@charset "UTF-8";
/**
 * A modern, elegant and minimal combination of Normalize.css and
 * CSS Reset. Compatible with IE10+.
 */
/**
 * 1. Sets box-sizing to border-box by default.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 * 3. Makes font thinkness to look the same in Firefox and Webkit.
 */
html {
  box-sizing: border-box; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -webkit-font-smoothing: antialiased; /* 3 */
  -moz-osx-font-smoothing: grayscale; /* 3 */
}

/**
 * Simple reset of element margin and padding
 */
body,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre, code,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
  margin: 0;
  padding: 0;
}

/**
 * Sets box-sizing to all elements and before / after
 */
*, *:before, *:after {
  box-sizing: inherit;
}

/**
 * Fixes the issues of main HTML5 tag with even earlier versions of IE.
 * For IE9-, please use HTML5Shiv https://github.com/aFarkas/html5shiv.
 */
main {
  display: block;
}

/**
 * Sets heading font-size to be equal to the content font-size. Encourages
 * the use of different heading elements to define the position of the heading
 * in the document and not the heading look.

 * Opinionated and disabled by default.
 */
/**
 * Applies a bold font weight to strong instead of the default bolder
 */
strong {
  font-weight: bold;
}

/**
 * Removes default border spacing and collapse
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Removes border from images inside links
 * 2. Helps images to properly behave in responsive layouts
 */
img {
  border-style: none; /* 1 */
  max-width: 100%; /* 2 */
  height: auto; /* 2 */
  vertical-align: middle; /* 2 */
}

/**
 * 1. Removes default grey background in IE10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent; /* 1 */
  -webkit-text-decoration-skip: objects; /* 2 */
}

/**
 * Remove margin from form elements
 */
input,
select,
textarea {
  margin: 0;
}

/**
 * Show overflow in Edge
 */
input {
  overflow: visible;
}

/**
 * Remove the default vertical scrollbar in IE.
 */
select {
  text-transform: none;
}

/**
 * 1. Remove the padding in IE 10-.
 * 2. Add the correct box sizing in IE 10-.
 */
[type=checkbox],
[type=radio] {
  padding: 0; /* 1 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */
[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

.o-grid {
  margin-left: -20px;
  list-style: none;
  box-sizing: border-box;
}
.o-grid > .o-grid__col {
  padding-left: 20px;
  width: 100%;
  box-sizing: inherit;
}

.o-grid {
  display: flex;
  flex-flow: row wrap;
}
.o-grid > .o-grid__col {
  box-sizing: inherit;
  flex-basis: auto;
  min-width: 0;
}

.o-grid--rev {
  flex-direction: row-reverse;
}

.u-1\/8 {
  width: 12.5% !important;
}

.u-2\/8 {
  width: 25% !important;
}

.u-3\/8 {
  width: 37.5% !important;
}

.u-4\/8 {
  width: 50% !important;
}

.u-5\/8 {
  width: 62.5% !important;
}

.u-6\/8 {
  width: 75% !important;
}

.u-7\/8 {
  width: 87.5% !important;
}

.u-8\/8 {
  width: 100% !important;
}

.u-1\/10 {
  width: 10% !important;
}

.u-2\/10 {
  width: 20% !important;
}

.u-3\/10 {
  width: 30% !important;
}

.u-4\/10 {
  width: 40% !important;
}

.u-5\/10 {
  width: 50% !important;
}

.u-6\/10 {
  width: 60% !important;
}

.u-7\/10 {
  width: 70% !important;
}

.u-8\/10 {
  width: 80% !important;
}

.u-9\/10 {
  width: 90% !important;
}

.u-10\/10 {
  width: 100% !important;
}

.u-1\/12 {
  width: 8.3333333333% !important;
}

.u-2\/12 {
  width: 16.6666666667% !important;
}

.u-3\/12 {
  width: 25% !important;
}

.u-4\/12 {
  width: 33.3333333333% !important;
}

.u-5\/12 {
  width: 41.6666666667% !important;
}

.u-6\/12 {
  width: 50% !important;
}

.u-7\/12 {
  width: 58.3333333333% !important;
}

.u-8\/12 {
  width: 66.6666666667% !important;
}

.u-9\/12 {
  width: 75% !important;
}

.u-10\/12 {
  width: 83.3333333333% !important;
}

.u-11\/12 {
  width: 91.6666666667% !important;
}

.u-12\/12 {
  width: 100% !important;
}

.u-1\/14 {
  width: 7.1428571429% !important;
}

.u-2\/14 {
  width: 14.2857142857% !important;
}

.u-3\/14 {
  width: 21.4285714286% !important;
}

.u-4\/14 {
  width: 28.5714285714% !important;
}

.u-5\/14 {
  width: 35.7142857143% !important;
}

.u-6\/14 {
  width: 42.8571428571% !important;
}

.u-7\/14 {
  width: 50% !important;
}

.u-8\/14 {
  width: 57.1428571429% !important;
}

.u-9\/14 {
  width: 64.2857142857% !important;
}

.u-10\/14 {
  width: 71.4285714286% !important;
}

.u-11\/14 {
  width: 78.5714285714% !important;
}

.u-12\/14 {
  width: 85.7142857143% !important;
}

.u-13\/14 {
  width: 92.8571428571% !important;
}

.u-14\/14 {
  width: 100% !important;
}

@media screen and (max-width: 767px) {
  .u-1\/8\@xs {
    width: 12.5% !important;
  }
  .u-2\/8\@xs {
    width: 25% !important;
  }
  .u-3\/8\@xs {
    width: 37.5% !important;
  }
  .u-4\/8\@xs {
    width: 50% !important;
  }
  .u-5\/8\@xs {
    width: 62.5% !important;
  }
  .u-6\/8\@xs {
    width: 75% !important;
  }
  .u-7\/8\@xs {
    width: 87.5% !important;
  }
  .u-8\/8\@xs {
    width: 100% !important;
  }
  .u-1\/10\@xs {
    width: 10% !important;
  }
  .u-2\/10\@xs {
    width: 20% !important;
  }
  .u-3\/10\@xs {
    width: 30% !important;
  }
  .u-4\/10\@xs {
    width: 40% !important;
  }
  .u-5\/10\@xs {
    width: 50% !important;
  }
  .u-6\/10\@xs {
    width: 60% !important;
  }
  .u-7\/10\@xs {
    width: 70% !important;
  }
  .u-8\/10\@xs {
    width: 80% !important;
  }
  .u-9\/10\@xs {
    width: 90% !important;
  }
  .u-10\/10\@xs {
    width: 100% !important;
  }
  .u-1\/12\@xs {
    width: 8.3333333333% !important;
  }
  .u-2\/12\@xs {
    width: 16.6666666667% !important;
  }
  .u-3\/12\@xs {
    width: 25% !important;
  }
  .u-4\/12\@xs {
    width: 33.3333333333% !important;
  }
  .u-5\/12\@xs {
    width: 41.6666666667% !important;
  }
  .u-6\/12\@xs {
    width: 50% !important;
  }
  .u-7\/12\@xs {
    width: 58.3333333333% !important;
  }
  .u-8\/12\@xs {
    width: 66.6666666667% !important;
  }
  .u-9\/12\@xs {
    width: 75% !important;
  }
  .u-10\/12\@xs {
    width: 83.3333333333% !important;
  }
  .u-11\/12\@xs {
    width: 91.6666666667% !important;
  }
  .u-12\/12\@xs {
    width: 100% !important;
  }
  .u-1\/14\@xs {
    width: 7.1428571429% !important;
  }
  .u-2\/14\@xs {
    width: 14.2857142857% !important;
  }
  .u-3\/14\@xs {
    width: 21.4285714286% !important;
  }
  .u-4\/14\@xs {
    width: 28.5714285714% !important;
  }
  .u-5\/14\@xs {
    width: 35.7142857143% !important;
  }
  .u-6\/14\@xs {
    width: 42.8571428571% !important;
  }
  .u-7\/14\@xs {
    width: 50% !important;
  }
  .u-8\/14\@xs {
    width: 57.1428571429% !important;
  }
  .u-9\/14\@xs {
    width: 64.2857142857% !important;
  }
  .u-10\/14\@xs {
    width: 71.4285714286% !important;
  }
  .u-11\/14\@xs {
    width: 78.5714285714% !important;
  }
  .u-12\/14\@xs {
    width: 85.7142857143% !important;
  }
  .u-13\/14\@xs {
    width: 92.8571428571% !important;
  }
  .u-14\/14\@xs {
    width: 100% !important;
  }
}
@media screen and (min-width: 568px) {
  .u-1\/8\@s {
    width: 12.5% !important;
  }
  .u-2\/8\@s {
    width: 25% !important;
  }
  .u-3\/8\@s {
    width: 37.5% !important;
  }
  .u-4\/8\@s {
    width: 50% !important;
  }
  .u-5\/8\@s {
    width: 62.5% !important;
  }
  .u-6\/8\@s {
    width: 75% !important;
  }
  .u-7\/8\@s {
    width: 87.5% !important;
  }
  .u-8\/8\@s {
    width: 100% !important;
  }
  .u-1\/10\@s {
    width: 10% !important;
  }
  .u-2\/10\@s {
    width: 20% !important;
  }
  .u-3\/10\@s {
    width: 30% !important;
  }
  .u-4\/10\@s {
    width: 40% !important;
  }
  .u-5\/10\@s {
    width: 50% !important;
  }
  .u-6\/10\@s {
    width: 60% !important;
  }
  .u-7\/10\@s {
    width: 70% !important;
  }
  .u-8\/10\@s {
    width: 80% !important;
  }
  .u-9\/10\@s {
    width: 90% !important;
  }
  .u-10\/10\@s {
    width: 100% !important;
  }
  .u-1\/12\@s {
    width: 8.3333333333% !important;
  }
  .u-2\/12\@s {
    width: 16.6666666667% !important;
  }
  .u-3\/12\@s {
    width: 25% !important;
  }
  .u-4\/12\@s {
    width: 33.3333333333% !important;
  }
  .u-5\/12\@s {
    width: 41.6666666667% !important;
  }
  .u-6\/12\@s {
    width: 50% !important;
  }
  .u-7\/12\@s {
    width: 58.3333333333% !important;
  }
  .u-8\/12\@s {
    width: 66.6666666667% !important;
  }
  .u-9\/12\@s {
    width: 75% !important;
  }
  .u-10\/12\@s {
    width: 83.3333333333% !important;
  }
  .u-11\/12\@s {
    width: 91.6666666667% !important;
  }
  .u-12\/12\@s {
    width: 100% !important;
  }
  .u-1\/14\@s {
    width: 7.1428571429% !important;
  }
  .u-2\/14\@s {
    width: 14.2857142857% !important;
  }
  .u-3\/14\@s {
    width: 21.4285714286% !important;
  }
  .u-4\/14\@s {
    width: 28.5714285714% !important;
  }
  .u-5\/14\@s {
    width: 35.7142857143% !important;
  }
  .u-6\/14\@s {
    width: 42.8571428571% !important;
  }
  .u-7\/14\@s {
    width: 50% !important;
  }
  .u-8\/14\@s {
    width: 57.1428571429% !important;
  }
  .u-9\/14\@s {
    width: 64.2857142857% !important;
  }
  .u-10\/14\@s {
    width: 71.4285714286% !important;
  }
  .u-11\/14\@s {
    width: 78.5714285714% !important;
  }
  .u-12\/14\@s {
    width: 85.7142857143% !important;
  }
  .u-13\/14\@s {
    width: 92.8571428571% !important;
  }
  .u-14\/14\@s {
    width: 100% !important;
  }
}
@media screen and (min-width: 769px) {
  .u-1\/8\@sm {
    width: 12.5% !important;
  }
  .u-2\/8\@sm {
    width: 25% !important;
  }
  .u-3\/8\@sm {
    width: 37.5% !important;
  }
  .u-4\/8\@sm {
    width: 50% !important;
  }
  .u-5\/8\@sm {
    width: 62.5% !important;
  }
  .u-6\/8\@sm {
    width: 75% !important;
  }
  .u-7\/8\@sm {
    width: 87.5% !important;
  }
  .u-8\/8\@sm {
    width: 100% !important;
  }
  .u-1\/10\@sm {
    width: 10% !important;
  }
  .u-2\/10\@sm {
    width: 20% !important;
  }
  .u-3\/10\@sm {
    width: 30% !important;
  }
  .u-4\/10\@sm {
    width: 40% !important;
  }
  .u-5\/10\@sm {
    width: 50% !important;
  }
  .u-6\/10\@sm {
    width: 60% !important;
  }
  .u-7\/10\@sm {
    width: 70% !important;
  }
  .u-8\/10\@sm {
    width: 80% !important;
  }
  .u-9\/10\@sm {
    width: 90% !important;
  }
  .u-10\/10\@sm {
    width: 100% !important;
  }
  .u-1\/12\@sm {
    width: 8.3333333333% !important;
  }
  .u-2\/12\@sm {
    width: 16.6666666667% !important;
  }
  .u-3\/12\@sm {
    width: 25% !important;
  }
  .u-4\/12\@sm {
    width: 33.3333333333% !important;
  }
  .u-5\/12\@sm {
    width: 41.6666666667% !important;
  }
  .u-6\/12\@sm {
    width: 50% !important;
  }
  .u-7\/12\@sm {
    width: 58.3333333333% !important;
  }
  .u-8\/12\@sm {
    width: 66.6666666667% !important;
  }
  .u-9\/12\@sm {
    width: 75% !important;
  }
  .u-10\/12\@sm {
    width: 83.3333333333% !important;
  }
  .u-11\/12\@sm {
    width: 91.6666666667% !important;
  }
  .u-12\/12\@sm {
    width: 100% !important;
  }
  .u-1\/14\@sm {
    width: 7.1428571429% !important;
  }
  .u-2\/14\@sm {
    width: 14.2857142857% !important;
  }
  .u-3\/14\@sm {
    width: 21.4285714286% !important;
  }
  .u-4\/14\@sm {
    width: 28.5714285714% !important;
  }
  .u-5\/14\@sm {
    width: 35.7142857143% !important;
  }
  .u-6\/14\@sm {
    width: 42.8571428571% !important;
  }
  .u-7\/14\@sm {
    width: 50% !important;
  }
  .u-8\/14\@sm {
    width: 57.1428571429% !important;
  }
  .u-9\/14\@sm {
    width: 64.2857142857% !important;
  }
  .u-10\/14\@sm {
    width: 71.4285714286% !important;
  }
  .u-11\/14\@sm {
    width: 78.5714285714% !important;
  }
  .u-12\/14\@sm {
    width: 85.7142857143% !important;
  }
  .u-13\/14\@sm {
    width: 92.8571428571% !important;
  }
  .u-14\/14\@sm {
    width: 100% !important;
  }
}
@media screen and (min-width: 992px) {
  .u-1\/8\@md {
    width: 12.5% !important;
  }
  .u-2\/8\@md {
    width: 25% !important;
  }
  .u-3\/8\@md {
    width: 37.5% !important;
  }
  .u-4\/8\@md {
    width: 50% !important;
  }
  .u-5\/8\@md {
    width: 62.5% !important;
  }
  .u-6\/8\@md {
    width: 75% !important;
  }
  .u-7\/8\@md {
    width: 87.5% !important;
  }
  .u-8\/8\@md {
    width: 100% !important;
  }
  .u-1\/10\@md {
    width: 10% !important;
  }
  .u-2\/10\@md {
    width: 20% !important;
  }
  .u-3\/10\@md {
    width: 30% !important;
  }
  .u-4\/10\@md {
    width: 40% !important;
  }
  .u-5\/10\@md {
    width: 50% !important;
  }
  .u-6\/10\@md {
    width: 60% !important;
  }
  .u-7\/10\@md {
    width: 70% !important;
  }
  .u-8\/10\@md {
    width: 80% !important;
  }
  .u-9\/10\@md {
    width: 90% !important;
  }
  .u-10\/10\@md {
    width: 100% !important;
  }
  .u-1\/12\@md {
    width: 8.3333333333% !important;
  }
  .u-2\/12\@md {
    width: 16.6666666667% !important;
  }
  .u-3\/12\@md {
    width: 25% !important;
  }
  .u-4\/12\@md {
    width: 33.3333333333% !important;
  }
  .u-5\/12\@md {
    width: 41.6666666667% !important;
  }
  .u-6\/12\@md {
    width: 50% !important;
  }
  .u-7\/12\@md {
    width: 58.3333333333% !important;
  }
  .u-8\/12\@md {
    width: 66.6666666667% !important;
  }
  .u-9\/12\@md {
    width: 75% !important;
  }
  .u-10\/12\@md {
    width: 83.3333333333% !important;
  }
  .u-11\/12\@md {
    width: 91.6666666667% !important;
  }
  .u-12\/12\@md {
    width: 100% !important;
  }
  .u-1\/14\@md {
    width: 7.1428571429% !important;
  }
  .u-2\/14\@md {
    width: 14.2857142857% !important;
  }
  .u-3\/14\@md {
    width: 21.4285714286% !important;
  }
  .u-4\/14\@md {
    width: 28.5714285714% !important;
  }
  .u-5\/14\@md {
    width: 35.7142857143% !important;
  }
  .u-6\/14\@md {
    width: 42.8571428571% !important;
  }
  .u-7\/14\@md {
    width: 50% !important;
  }
  .u-8\/14\@md {
    width: 57.1428571429% !important;
  }
  .u-9\/14\@md {
    width: 64.2857142857% !important;
  }
  .u-10\/14\@md {
    width: 71.4285714286% !important;
  }
  .u-11\/14\@md {
    width: 78.5714285714% !important;
  }
  .u-12\/14\@md {
    width: 85.7142857143% !important;
  }
  .u-13\/14\@md {
    width: 92.8571428571% !important;
  }
  .u-14\/14\@md {
    width: 100% !important;
  }
}
@media screen and (min-width: 1200px) {
  .u-1\/8\@lg {
    width: 12.5% !important;
  }
  .u-2\/8\@lg {
    width: 25% !important;
  }
  .u-3\/8\@lg {
    width: 37.5% !important;
  }
  .u-4\/8\@lg {
    width: 50% !important;
  }
  .u-5\/8\@lg {
    width: 62.5% !important;
  }
  .u-6\/8\@lg {
    width: 75% !important;
  }
  .u-7\/8\@lg {
    width: 87.5% !important;
  }
  .u-8\/8\@lg {
    width: 100% !important;
  }
  .u-1\/10\@lg {
    width: 10% !important;
  }
  .u-2\/10\@lg {
    width: 20% !important;
  }
  .u-3\/10\@lg {
    width: 30% !important;
  }
  .u-4\/10\@lg {
    width: 40% !important;
  }
  .u-5\/10\@lg {
    width: 50% !important;
  }
  .u-6\/10\@lg {
    width: 60% !important;
  }
  .u-7\/10\@lg {
    width: 70% !important;
  }
  .u-8\/10\@lg {
    width: 80% !important;
  }
  .u-9\/10\@lg {
    width: 90% !important;
  }
  .u-10\/10\@lg {
    width: 100% !important;
  }
  .u-1\/12\@lg {
    width: 8.3333333333% !important;
  }
  .u-2\/12\@lg {
    width: 16.6666666667% !important;
  }
  .u-3\/12\@lg {
    width: 25% !important;
  }
  .u-4\/12\@lg {
    width: 33.3333333333% !important;
  }
  .u-5\/12\@lg {
    width: 41.6666666667% !important;
  }
  .u-6\/12\@lg {
    width: 50% !important;
  }
  .u-7\/12\@lg {
    width: 58.3333333333% !important;
  }
  .u-8\/12\@lg {
    width: 66.6666666667% !important;
  }
  .u-9\/12\@lg {
    width: 75% !important;
  }
  .u-10\/12\@lg {
    width: 83.3333333333% !important;
  }
  .u-11\/12\@lg {
    width: 91.6666666667% !important;
  }
  .u-12\/12\@lg {
    width: 100% !important;
  }
  .u-1\/14\@lg {
    width: 7.1428571429% !important;
  }
  .u-2\/14\@lg {
    width: 14.2857142857% !important;
  }
  .u-3\/14\@lg {
    width: 21.4285714286% !important;
  }
  .u-4\/14\@lg {
    width: 28.5714285714% !important;
  }
  .u-5\/14\@lg {
    width: 35.7142857143% !important;
  }
  .u-6\/14\@lg {
    width: 42.8571428571% !important;
  }
  .u-7\/14\@lg {
    width: 50% !important;
  }
  .u-8\/14\@lg {
    width: 57.1428571429% !important;
  }
  .u-9\/14\@lg {
    width: 64.2857142857% !important;
  }
  .u-10\/14\@lg {
    width: 71.4285714286% !important;
  }
  .u-11\/14\@lg {
    width: 78.5714285714% !important;
  }
  .u-12\/14\@lg {
    width: 85.7142857143% !important;
  }
  .u-13\/14\@lg {
    width: 92.8571428571% !important;
  }
  .u-14\/14\@lg {
    width: 100% !important;
  }
}
@media screen and (min-width: 1681px) {
  .u-1\/8\@xl {
    width: 12.5% !important;
  }
  .u-2\/8\@xl {
    width: 25% !important;
  }
  .u-3\/8\@xl {
    width: 37.5% !important;
  }
  .u-4\/8\@xl {
    width: 50% !important;
  }
  .u-5\/8\@xl {
    width: 62.5% !important;
  }
  .u-6\/8\@xl {
    width: 75% !important;
  }
  .u-7\/8\@xl {
    width: 87.5% !important;
  }
  .u-8\/8\@xl {
    width: 100% !important;
  }
  .u-1\/10\@xl {
    width: 10% !important;
  }
  .u-2\/10\@xl {
    width: 20% !important;
  }
  .u-3\/10\@xl {
    width: 30% !important;
  }
  .u-4\/10\@xl {
    width: 40% !important;
  }
  .u-5\/10\@xl {
    width: 50% !important;
  }
  .u-6\/10\@xl {
    width: 60% !important;
  }
  .u-7\/10\@xl {
    width: 70% !important;
  }
  .u-8\/10\@xl {
    width: 80% !important;
  }
  .u-9\/10\@xl {
    width: 90% !important;
  }
  .u-10\/10\@xl {
    width: 100% !important;
  }
  .u-1\/12\@xl {
    width: 8.3333333333% !important;
  }
  .u-2\/12\@xl {
    width: 16.6666666667% !important;
  }
  .u-3\/12\@xl {
    width: 25% !important;
  }
  .u-4\/12\@xl {
    width: 33.3333333333% !important;
  }
  .u-5\/12\@xl {
    width: 41.6666666667% !important;
  }
  .u-6\/12\@xl {
    width: 50% !important;
  }
  .u-7\/12\@xl {
    width: 58.3333333333% !important;
  }
  .u-8\/12\@xl {
    width: 66.6666666667% !important;
  }
  .u-9\/12\@xl {
    width: 75% !important;
  }
  .u-10\/12\@xl {
    width: 83.3333333333% !important;
  }
  .u-11\/12\@xl {
    width: 91.6666666667% !important;
  }
  .u-12\/12\@xl {
    width: 100% !important;
  }
  .u-1\/14\@xl {
    width: 7.1428571429% !important;
  }
  .u-2\/14\@xl {
    width: 14.2857142857% !important;
  }
  .u-3\/14\@xl {
    width: 21.4285714286% !important;
  }
  .u-4\/14\@xl {
    width: 28.5714285714% !important;
  }
  .u-5\/14\@xl {
    width: 35.7142857143% !important;
  }
  .u-6\/14\@xl {
    width: 42.8571428571% !important;
  }
  .u-7\/14\@xl {
    width: 50% !important;
  }
  .u-8\/14\@xl {
    width: 57.1428571429% !important;
  }
  .u-9\/14\@xl {
    width: 64.2857142857% !important;
  }
  .u-10\/14\@xl {
    width: 71.4285714286% !important;
  }
  .u-11\/14\@xl {
    width: 78.5714285714% !important;
  }
  .u-12\/14\@xl {
    width: 85.7142857143% !important;
  }
  .u-13\/14\@xl {
    width: 92.8571428571% !important;
  }
  .u-14\/14\@xl {
    width: 100% !important;
  }
}
@media screen and (min-width: 2000px) {
  .u-1\/8\@xxl {
    width: 12.5% !important;
  }
  .u-2\/8\@xxl {
    width: 25% !important;
  }
  .u-3\/8\@xxl {
    width: 37.5% !important;
  }
  .u-4\/8\@xxl {
    width: 50% !important;
  }
  .u-5\/8\@xxl {
    width: 62.5% !important;
  }
  .u-6\/8\@xxl {
    width: 75% !important;
  }
  .u-7\/8\@xxl {
    width: 87.5% !important;
  }
  .u-8\/8\@xxl {
    width: 100% !important;
  }
  .u-1\/10\@xxl {
    width: 10% !important;
  }
  .u-2\/10\@xxl {
    width: 20% !important;
  }
  .u-3\/10\@xxl {
    width: 30% !important;
  }
  .u-4\/10\@xxl {
    width: 40% !important;
  }
  .u-5\/10\@xxl {
    width: 50% !important;
  }
  .u-6\/10\@xxl {
    width: 60% !important;
  }
  .u-7\/10\@xxl {
    width: 70% !important;
  }
  .u-8\/10\@xxl {
    width: 80% !important;
  }
  .u-9\/10\@xxl {
    width: 90% !important;
  }
  .u-10\/10\@xxl {
    width: 100% !important;
  }
  .u-1\/12\@xxl {
    width: 8.3333333333% !important;
  }
  .u-2\/12\@xxl {
    width: 16.6666666667% !important;
  }
  .u-3\/12\@xxl {
    width: 25% !important;
  }
  .u-4\/12\@xxl {
    width: 33.3333333333% !important;
  }
  .u-5\/12\@xxl {
    width: 41.6666666667% !important;
  }
  .u-6\/12\@xxl {
    width: 50% !important;
  }
  .u-7\/12\@xxl {
    width: 58.3333333333% !important;
  }
  .u-8\/12\@xxl {
    width: 66.6666666667% !important;
  }
  .u-9\/12\@xxl {
    width: 75% !important;
  }
  .u-10\/12\@xxl {
    width: 83.3333333333% !important;
  }
  .u-11\/12\@xxl {
    width: 91.6666666667% !important;
  }
  .u-12\/12\@xxl {
    width: 100% !important;
  }
  .u-1\/14\@xxl {
    width: 7.1428571429% !important;
  }
  .u-2\/14\@xxl {
    width: 14.2857142857% !important;
  }
  .u-3\/14\@xxl {
    width: 21.4285714286% !important;
  }
  .u-4\/14\@xxl {
    width: 28.5714285714% !important;
  }
  .u-5\/14\@xxl {
    width: 35.7142857143% !important;
  }
  .u-6\/14\@xxl {
    width: 42.8571428571% !important;
  }
  .u-7\/14\@xxl {
    width: 50% !important;
  }
  .u-8\/14\@xxl {
    width: 57.1428571429% !important;
  }
  .u-9\/14\@xxl {
    width: 64.2857142857% !important;
  }
  .u-10\/14\@xxl {
    width: 71.4285714286% !important;
  }
  .u-11\/14\@xxl {
    width: 78.5714285714% !important;
  }
  .u-12\/14\@xxl {
    width: 85.7142857143% !important;
  }
  .u-13\/14\@xxl {
    width: 92.8571428571% !important;
  }
  .u-14\/14\@xxl {
    width: 100% !important;
  }
}
.u-pull-1\/8 {
  margin-right: 12.5% !important;
}

.u-pull-2\/8 {
  margin-right: 25% !important;
}

.u-pull-3\/8 {
  margin-right: 37.5% !important;
}

.u-pull-4\/8 {
  margin-right: 50% !important;
}

.u-pull-5\/8 {
  margin-right: 62.5% !important;
}

.u-pull-6\/8 {
  margin-right: 75% !important;
}

.u-pull-7\/8 {
  margin-right: 87.5% !important;
}

.u-pull-1\/10 {
  margin-right: 10% !important;
}

.u-pull-2\/10 {
  margin-right: 20% !important;
}

.u-pull-3\/10 {
  margin-right: 30% !important;
}

.u-pull-4\/10 {
  margin-right: 40% !important;
}

.u-pull-5\/10 {
  margin-right: 50% !important;
}

.u-pull-6\/10 {
  margin-right: 60% !important;
}

.u-pull-7\/10 {
  margin-right: 70% !important;
}

.u-pull-8\/10 {
  margin-right: 80% !important;
}

.u-pull-9\/10 {
  margin-right: 90% !important;
}

.u-pull-1\/12 {
  margin-right: 8.3333333333% !important;
}

.u-pull-2\/12 {
  margin-right: 16.6666666667% !important;
}

.u-pull-3\/12 {
  margin-right: 25% !important;
}

.u-pull-4\/12 {
  margin-right: 33.3333333333% !important;
}

.u-pull-5\/12 {
  margin-right: 41.6666666667% !important;
}

.u-pull-6\/12 {
  margin-right: 50% !important;
}

.u-pull-7\/12 {
  margin-right: 58.3333333333% !important;
}

.u-pull-8\/12 {
  margin-right: 66.6666666667% !important;
}

.u-pull-9\/12 {
  margin-right: 75% !important;
}

.u-pull-10\/12 {
  margin-right: 83.3333333333% !important;
}

.u-pull-11\/12 {
  margin-right: 91.6666666667% !important;
}

.u-pull-1\/14 {
  margin-right: 7.1428571429% !important;
}

.u-pull-2\/14 {
  margin-right: 14.2857142857% !important;
}

.u-pull-3\/14 {
  margin-right: 21.4285714286% !important;
}

.u-pull-4\/14 {
  margin-right: 28.5714285714% !important;
}

.u-pull-5\/14 {
  margin-right: 35.7142857143% !important;
}

.u-pull-6\/14 {
  margin-right: 42.8571428571% !important;
}

.u-pull-7\/14 {
  margin-right: 50% !important;
}

.u-pull-8\/14 {
  margin-right: 57.1428571429% !important;
}

.u-pull-9\/14 {
  margin-right: 64.2857142857% !important;
}

.u-pull-10\/14 {
  margin-right: 71.4285714286% !important;
}

.u-pull-11\/14 {
  margin-right: 78.5714285714% !important;
}

.u-pull-12\/14 {
  margin-right: 85.7142857143% !important;
}

.u-pull-13\/14 {
  margin-right: 92.8571428571% !important;
}

.u-pull-0 {
  margin-right: 0 !important;
}

@media screen and (max-width: 767px) {
  .u-pull-1\/8\@xs {
    margin-right: 12.5% !important;
  }
  .u-pull-2\/8\@xs {
    margin-right: 25% !important;
  }
  .u-pull-3\/8\@xs {
    margin-right: 37.5% !important;
  }
  .u-pull-4\/8\@xs {
    margin-right: 50% !important;
  }
  .u-pull-5\/8\@xs {
    margin-right: 62.5% !important;
  }
  .u-pull-6\/8\@xs {
    margin-right: 75% !important;
  }
  .u-pull-7\/8\@xs {
    margin-right: 87.5% !important;
  }
  .u-pull-1\/10\@xs {
    margin-right: 10% !important;
  }
  .u-pull-2\/10\@xs {
    margin-right: 20% !important;
  }
  .u-pull-3\/10\@xs {
    margin-right: 30% !important;
  }
  .u-pull-4\/10\@xs {
    margin-right: 40% !important;
  }
  .u-pull-5\/10\@xs {
    margin-right: 50% !important;
  }
  .u-pull-6\/10\@xs {
    margin-right: 60% !important;
  }
  .u-pull-7\/10\@xs {
    margin-right: 70% !important;
  }
  .u-pull-8\/10\@xs {
    margin-right: 80% !important;
  }
  .u-pull-9\/10\@xs {
    margin-right: 90% !important;
  }
  .u-pull-1\/12\@xs {
    margin-right: 8.3333333333% !important;
  }
  .u-pull-2\/12\@xs {
    margin-right: 16.6666666667% !important;
  }
  .u-pull-3\/12\@xs {
    margin-right: 25% !important;
  }
  .u-pull-4\/12\@xs {
    margin-right: 33.3333333333% !important;
  }
  .u-pull-5\/12\@xs {
    margin-right: 41.6666666667% !important;
  }
  .u-pull-6\/12\@xs {
    margin-right: 50% !important;
  }
  .u-pull-7\/12\@xs {
    margin-right: 58.3333333333% !important;
  }
  .u-pull-8\/12\@xs {
    margin-right: 66.6666666667% !important;
  }
  .u-pull-9\/12\@xs {
    margin-right: 75% !important;
  }
  .u-pull-10\/12\@xs {
    margin-right: 83.3333333333% !important;
  }
  .u-pull-11\/12\@xs {
    margin-right: 91.6666666667% !important;
  }
  .u-pull-1\/14\@xs {
    margin-right: 7.1428571429% !important;
  }
  .u-pull-2\/14\@xs {
    margin-right: 14.2857142857% !important;
  }
  .u-pull-3\/14\@xs {
    margin-right: 21.4285714286% !important;
  }
  .u-pull-4\/14\@xs {
    margin-right: 28.5714285714% !important;
  }
  .u-pull-5\/14\@xs {
    margin-right: 35.7142857143% !important;
  }
  .u-pull-6\/14\@xs {
    margin-right: 42.8571428571% !important;
  }
  .u-pull-7\/14\@xs {
    margin-right: 50% !important;
  }
  .u-pull-8\/14\@xs {
    margin-right: 57.1428571429% !important;
  }
  .u-pull-9\/14\@xs {
    margin-right: 64.2857142857% !important;
  }
  .u-pull-10\/14\@xs {
    margin-right: 71.4285714286% !important;
  }
  .u-pull-11\/14\@xs {
    margin-right: 78.5714285714% !important;
  }
  .u-pull-12\/14\@xs {
    margin-right: 85.7142857143% !important;
  }
  .u-pull-13\/14\@xs {
    margin-right: 92.8571428571% !important;
  }
  .u-pull-0\@xs {
    margin-right: 0 !important;
  }
}
@media screen and (min-width: 568px) {
  .u-pull-1\/8\@s {
    margin-right: 12.5% !important;
  }
  .u-pull-2\/8\@s {
    margin-right: 25% !important;
  }
  .u-pull-3\/8\@s {
    margin-right: 37.5% !important;
  }
  .u-pull-4\/8\@s {
    margin-right: 50% !important;
  }
  .u-pull-5\/8\@s {
    margin-right: 62.5% !important;
  }
  .u-pull-6\/8\@s {
    margin-right: 75% !important;
  }
  .u-pull-7\/8\@s {
    margin-right: 87.5% !important;
  }
  .u-pull-1\/10\@s {
    margin-right: 10% !important;
  }
  .u-pull-2\/10\@s {
    margin-right: 20% !important;
  }
  .u-pull-3\/10\@s {
    margin-right: 30% !important;
  }
  .u-pull-4\/10\@s {
    margin-right: 40% !important;
  }
  .u-pull-5\/10\@s {
    margin-right: 50% !important;
  }
  .u-pull-6\/10\@s {
    margin-right: 60% !important;
  }
  .u-pull-7\/10\@s {
    margin-right: 70% !important;
  }
  .u-pull-8\/10\@s {
    margin-right: 80% !important;
  }
  .u-pull-9\/10\@s {
    margin-right: 90% !important;
  }
  .u-pull-1\/12\@s {
    margin-right: 8.3333333333% !important;
  }
  .u-pull-2\/12\@s {
    margin-right: 16.6666666667% !important;
  }
  .u-pull-3\/12\@s {
    margin-right: 25% !important;
  }
  .u-pull-4\/12\@s {
    margin-right: 33.3333333333% !important;
  }
  .u-pull-5\/12\@s {
    margin-right: 41.6666666667% !important;
  }
  .u-pull-6\/12\@s {
    margin-right: 50% !important;
  }
  .u-pull-7\/12\@s {
    margin-right: 58.3333333333% !important;
  }
  .u-pull-8\/12\@s {
    margin-right: 66.6666666667% !important;
  }
  .u-pull-9\/12\@s {
    margin-right: 75% !important;
  }
  .u-pull-10\/12\@s {
    margin-right: 83.3333333333% !important;
  }
  .u-pull-11\/12\@s {
    margin-right: 91.6666666667% !important;
  }
  .u-pull-1\/14\@s {
    margin-right: 7.1428571429% !important;
  }
  .u-pull-2\/14\@s {
    margin-right: 14.2857142857% !important;
  }
  .u-pull-3\/14\@s {
    margin-right: 21.4285714286% !important;
  }
  .u-pull-4\/14\@s {
    margin-right: 28.5714285714% !important;
  }
  .u-pull-5\/14\@s {
    margin-right: 35.7142857143% !important;
  }
  .u-pull-6\/14\@s {
    margin-right: 42.8571428571% !important;
  }
  .u-pull-7\/14\@s {
    margin-right: 50% !important;
  }
  .u-pull-8\/14\@s {
    margin-right: 57.1428571429% !important;
  }
  .u-pull-9\/14\@s {
    margin-right: 64.2857142857% !important;
  }
  .u-pull-10\/14\@s {
    margin-right: 71.4285714286% !important;
  }
  .u-pull-11\/14\@s {
    margin-right: 78.5714285714% !important;
  }
  .u-pull-12\/14\@s {
    margin-right: 85.7142857143% !important;
  }
  .u-pull-13\/14\@s {
    margin-right: 92.8571428571% !important;
  }
  .u-pull-0\@s {
    margin-right: 0 !important;
  }
}
@media screen and (min-width: 769px) {
  .u-pull-1\/8\@sm {
    margin-right: 12.5% !important;
  }
  .u-pull-2\/8\@sm {
    margin-right: 25% !important;
  }
  .u-pull-3\/8\@sm {
    margin-right: 37.5% !important;
  }
  .u-pull-4\/8\@sm {
    margin-right: 50% !important;
  }
  .u-pull-5\/8\@sm {
    margin-right: 62.5% !important;
  }
  .u-pull-6\/8\@sm {
    margin-right: 75% !important;
  }
  .u-pull-7\/8\@sm {
    margin-right: 87.5% !important;
  }
  .u-pull-1\/10\@sm {
    margin-right: 10% !important;
  }
  .u-pull-2\/10\@sm {
    margin-right: 20% !important;
  }
  .u-pull-3\/10\@sm {
    margin-right: 30% !important;
  }
  .u-pull-4\/10\@sm {
    margin-right: 40% !important;
  }
  .u-pull-5\/10\@sm {
    margin-right: 50% !important;
  }
  .u-pull-6\/10\@sm {
    margin-right: 60% !important;
  }
  .u-pull-7\/10\@sm {
    margin-right: 70% !important;
  }
  .u-pull-8\/10\@sm {
    margin-right: 80% !important;
  }
  .u-pull-9\/10\@sm {
    margin-right: 90% !important;
  }
  .u-pull-1\/12\@sm {
    margin-right: 8.3333333333% !important;
  }
  .u-pull-2\/12\@sm {
    margin-right: 16.6666666667% !important;
  }
  .u-pull-3\/12\@sm {
    margin-right: 25% !important;
  }
  .u-pull-4\/12\@sm {
    margin-right: 33.3333333333% !important;
  }
  .u-pull-5\/12\@sm {
    margin-right: 41.6666666667% !important;
  }
  .u-pull-6\/12\@sm {
    margin-right: 50% !important;
  }
  .u-pull-7\/12\@sm {
    margin-right: 58.3333333333% !important;
  }
  .u-pull-8\/12\@sm {
    margin-right: 66.6666666667% !important;
  }
  .u-pull-9\/12\@sm {
    margin-right: 75% !important;
  }
  .u-pull-10\/12\@sm {
    margin-right: 83.3333333333% !important;
  }
  .u-pull-11\/12\@sm {
    margin-right: 91.6666666667% !important;
  }
  .u-pull-1\/14\@sm {
    margin-right: 7.1428571429% !important;
  }
  .u-pull-2\/14\@sm {
    margin-right: 14.2857142857% !important;
  }
  .u-pull-3\/14\@sm {
    margin-right: 21.4285714286% !important;
  }
  .u-pull-4\/14\@sm {
    margin-right: 28.5714285714% !important;
  }
  .u-pull-5\/14\@sm {
    margin-right: 35.7142857143% !important;
  }
  .u-pull-6\/14\@sm {
    margin-right: 42.8571428571% !important;
  }
  .u-pull-7\/14\@sm {
    margin-right: 50% !important;
  }
  .u-pull-8\/14\@sm {
    margin-right: 57.1428571429% !important;
  }
  .u-pull-9\/14\@sm {
    margin-right: 64.2857142857% !important;
  }
  .u-pull-10\/14\@sm {
    margin-right: 71.4285714286% !important;
  }
  .u-pull-11\/14\@sm {
    margin-right: 78.5714285714% !important;
  }
  .u-pull-12\/14\@sm {
    margin-right: 85.7142857143% !important;
  }
  .u-pull-13\/14\@sm {
    margin-right: 92.8571428571% !important;
  }
  .u-pull-0\@sm {
    margin-right: 0 !important;
  }
}
@media screen and (min-width: 992px) {
  .u-pull-1\/8\@md {
    margin-right: 12.5% !important;
  }
  .u-pull-2\/8\@md {
    margin-right: 25% !important;
  }
  .u-pull-3\/8\@md {
    margin-right: 37.5% !important;
  }
  .u-pull-4\/8\@md {
    margin-right: 50% !important;
  }
  .u-pull-5\/8\@md {
    margin-right: 62.5% !important;
  }
  .u-pull-6\/8\@md {
    margin-right: 75% !important;
  }
  .u-pull-7\/8\@md {
    margin-right: 87.5% !important;
  }
  .u-pull-1\/10\@md {
    margin-right: 10% !important;
  }
  .u-pull-2\/10\@md {
    margin-right: 20% !important;
  }
  .u-pull-3\/10\@md {
    margin-right: 30% !important;
  }
  .u-pull-4\/10\@md {
    margin-right: 40% !important;
  }
  .u-pull-5\/10\@md {
    margin-right: 50% !important;
  }
  .u-pull-6\/10\@md {
    margin-right: 60% !important;
  }
  .u-pull-7\/10\@md {
    margin-right: 70% !important;
  }
  .u-pull-8\/10\@md {
    margin-right: 80% !important;
  }
  .u-pull-9\/10\@md {
    margin-right: 90% !important;
  }
  .u-pull-1\/12\@md {
    margin-right: 8.3333333333% !important;
  }
  .u-pull-2\/12\@md {
    margin-right: 16.6666666667% !important;
  }
  .u-pull-3\/12\@md {
    margin-right: 25% !important;
  }
  .u-pull-4\/12\@md {
    margin-right: 33.3333333333% !important;
  }
  .u-pull-5\/12\@md {
    margin-right: 41.6666666667% !important;
  }
  .u-pull-6\/12\@md {
    margin-right: 50% !important;
  }
  .u-pull-7\/12\@md {
    margin-right: 58.3333333333% !important;
  }
  .u-pull-8\/12\@md {
    margin-right: 66.6666666667% !important;
  }
  .u-pull-9\/12\@md {
    margin-right: 75% !important;
  }
  .u-pull-10\/12\@md {
    margin-right: 83.3333333333% !important;
  }
  .u-pull-11\/12\@md {
    margin-right: 91.6666666667% !important;
  }
  .u-pull-1\/14\@md {
    margin-right: 7.1428571429% !important;
  }
  .u-pull-2\/14\@md {
    margin-right: 14.2857142857% !important;
  }
  .u-pull-3\/14\@md {
    margin-right: 21.4285714286% !important;
  }
  .u-pull-4\/14\@md {
    margin-right: 28.5714285714% !important;
  }
  .u-pull-5\/14\@md {
    margin-right: 35.7142857143% !important;
  }
  .u-pull-6\/14\@md {
    margin-right: 42.8571428571% !important;
  }
  .u-pull-7\/14\@md {
    margin-right: 50% !important;
  }
  .u-pull-8\/14\@md {
    margin-right: 57.1428571429% !important;
  }
  .u-pull-9\/14\@md {
    margin-right: 64.2857142857% !important;
  }
  .u-pull-10\/14\@md {
    margin-right: 71.4285714286% !important;
  }
  .u-pull-11\/14\@md {
    margin-right: 78.5714285714% !important;
  }
  .u-pull-12\/14\@md {
    margin-right: 85.7142857143% !important;
  }
  .u-pull-13\/14\@md {
    margin-right: 92.8571428571% !important;
  }
  .u-pull-0\@md {
    margin-right: 0 !important;
  }
}
@media screen and (min-width: 1200px) {
  .u-pull-1\/8\@lg {
    margin-right: 12.5% !important;
  }
  .u-pull-2\/8\@lg {
    margin-right: 25% !important;
  }
  .u-pull-3\/8\@lg {
    margin-right: 37.5% !important;
  }
  .u-pull-4\/8\@lg {
    margin-right: 50% !important;
  }
  .u-pull-5\/8\@lg {
    margin-right: 62.5% !important;
  }
  .u-pull-6\/8\@lg {
    margin-right: 75% !important;
  }
  .u-pull-7\/8\@lg {
    margin-right: 87.5% !important;
  }
  .u-pull-1\/10\@lg {
    margin-right: 10% !important;
  }
  .u-pull-2\/10\@lg {
    margin-right: 20% !important;
  }
  .u-pull-3\/10\@lg {
    margin-right: 30% !important;
  }
  .u-pull-4\/10\@lg {
    margin-right: 40% !important;
  }
  .u-pull-5\/10\@lg {
    margin-right: 50% !important;
  }
  .u-pull-6\/10\@lg {
    margin-right: 60% !important;
  }
  .u-pull-7\/10\@lg {
    margin-right: 70% !important;
  }
  .u-pull-8\/10\@lg {
    margin-right: 80% !important;
  }
  .u-pull-9\/10\@lg {
    margin-right: 90% !important;
  }
  .u-pull-1\/12\@lg {
    margin-right: 8.3333333333% !important;
  }
  .u-pull-2\/12\@lg {
    margin-right: 16.6666666667% !important;
  }
  .u-pull-3\/12\@lg {
    margin-right: 25% !important;
  }
  .u-pull-4\/12\@lg {
    margin-right: 33.3333333333% !important;
  }
  .u-pull-5\/12\@lg {
    margin-right: 41.6666666667% !important;
  }
  .u-pull-6\/12\@lg {
    margin-right: 50% !important;
  }
  .u-pull-7\/12\@lg {
    margin-right: 58.3333333333% !important;
  }
  .u-pull-8\/12\@lg {
    margin-right: 66.6666666667% !important;
  }
  .u-pull-9\/12\@lg {
    margin-right: 75% !important;
  }
  .u-pull-10\/12\@lg {
    margin-right: 83.3333333333% !important;
  }
  .u-pull-11\/12\@lg {
    margin-right: 91.6666666667% !important;
  }
  .u-pull-1\/14\@lg {
    margin-right: 7.1428571429% !important;
  }
  .u-pull-2\/14\@lg {
    margin-right: 14.2857142857% !important;
  }
  .u-pull-3\/14\@lg {
    margin-right: 21.4285714286% !important;
  }
  .u-pull-4\/14\@lg {
    margin-right: 28.5714285714% !important;
  }
  .u-pull-5\/14\@lg {
    margin-right: 35.7142857143% !important;
  }
  .u-pull-6\/14\@lg {
    margin-right: 42.8571428571% !important;
  }
  .u-pull-7\/14\@lg {
    margin-right: 50% !important;
  }
  .u-pull-8\/14\@lg {
    margin-right: 57.1428571429% !important;
  }
  .u-pull-9\/14\@lg {
    margin-right: 64.2857142857% !important;
  }
  .u-pull-10\/14\@lg {
    margin-right: 71.4285714286% !important;
  }
  .u-pull-11\/14\@lg {
    margin-right: 78.5714285714% !important;
  }
  .u-pull-12\/14\@lg {
    margin-right: 85.7142857143% !important;
  }
  .u-pull-13\/14\@lg {
    margin-right: 92.8571428571% !important;
  }
  .u-pull-0\@lg {
    margin-right: 0 !important;
  }
}
@media screen and (min-width: 1681px) {
  .u-pull-1\/8\@xl {
    margin-right: 12.5% !important;
  }
  .u-pull-2\/8\@xl {
    margin-right: 25% !important;
  }
  .u-pull-3\/8\@xl {
    margin-right: 37.5% !important;
  }
  .u-pull-4\/8\@xl {
    margin-right: 50% !important;
  }
  .u-pull-5\/8\@xl {
    margin-right: 62.5% !important;
  }
  .u-pull-6\/8\@xl {
    margin-right: 75% !important;
  }
  .u-pull-7\/8\@xl {
    margin-right: 87.5% !important;
  }
  .u-pull-1\/10\@xl {
    margin-right: 10% !important;
  }
  .u-pull-2\/10\@xl {
    margin-right: 20% !important;
  }
  .u-pull-3\/10\@xl {
    margin-right: 30% !important;
  }
  .u-pull-4\/10\@xl {
    margin-right: 40% !important;
  }
  .u-pull-5\/10\@xl {
    margin-right: 50% !important;
  }
  .u-pull-6\/10\@xl {
    margin-right: 60% !important;
  }
  .u-pull-7\/10\@xl {
    margin-right: 70% !important;
  }
  .u-pull-8\/10\@xl {
    margin-right: 80% !important;
  }
  .u-pull-9\/10\@xl {
    margin-right: 90% !important;
  }
  .u-pull-1\/12\@xl {
    margin-right: 8.3333333333% !important;
  }
  .u-pull-2\/12\@xl {
    margin-right: 16.6666666667% !important;
  }
  .u-pull-3\/12\@xl {
    margin-right: 25% !important;
  }
  .u-pull-4\/12\@xl {
    margin-right: 33.3333333333% !important;
  }
  .u-pull-5\/12\@xl {
    margin-right: 41.6666666667% !important;
  }
  .u-pull-6\/12\@xl {
    margin-right: 50% !important;
  }
  .u-pull-7\/12\@xl {
    margin-right: 58.3333333333% !important;
  }
  .u-pull-8\/12\@xl {
    margin-right: 66.6666666667% !important;
  }
  .u-pull-9\/12\@xl {
    margin-right: 75% !important;
  }
  .u-pull-10\/12\@xl {
    margin-right: 83.3333333333% !important;
  }
  .u-pull-11\/12\@xl {
    margin-right: 91.6666666667% !important;
  }
  .u-pull-1\/14\@xl {
    margin-right: 7.1428571429% !important;
  }
  .u-pull-2\/14\@xl {
    margin-right: 14.2857142857% !important;
  }
  .u-pull-3\/14\@xl {
    margin-right: 21.4285714286% !important;
  }
  .u-pull-4\/14\@xl {
    margin-right: 28.5714285714% !important;
  }
  .u-pull-5\/14\@xl {
    margin-right: 35.7142857143% !important;
  }
  .u-pull-6\/14\@xl {
    margin-right: 42.8571428571% !important;
  }
  .u-pull-7\/14\@xl {
    margin-right: 50% !important;
  }
  .u-pull-8\/14\@xl {
    margin-right: 57.1428571429% !important;
  }
  .u-pull-9\/14\@xl {
    margin-right: 64.2857142857% !important;
  }
  .u-pull-10\/14\@xl {
    margin-right: 71.4285714286% !important;
  }
  .u-pull-11\/14\@xl {
    margin-right: 78.5714285714% !important;
  }
  .u-pull-12\/14\@xl {
    margin-right: 85.7142857143% !important;
  }
  .u-pull-13\/14\@xl {
    margin-right: 92.8571428571% !important;
  }
  .u-pull-0\@xl {
    margin-right: 0 !important;
  }
}
@media screen and (min-width: 2000px) {
  .u-pull-1\/8\@xxl {
    margin-right: 12.5% !important;
  }
  .u-pull-2\/8\@xxl {
    margin-right: 25% !important;
  }
  .u-pull-3\/8\@xxl {
    margin-right: 37.5% !important;
  }
  .u-pull-4\/8\@xxl {
    margin-right: 50% !important;
  }
  .u-pull-5\/8\@xxl {
    margin-right: 62.5% !important;
  }
  .u-pull-6\/8\@xxl {
    margin-right: 75% !important;
  }
  .u-pull-7\/8\@xxl {
    margin-right: 87.5% !important;
  }
  .u-pull-1\/10\@xxl {
    margin-right: 10% !important;
  }
  .u-pull-2\/10\@xxl {
    margin-right: 20% !important;
  }
  .u-pull-3\/10\@xxl {
    margin-right: 30% !important;
  }
  .u-pull-4\/10\@xxl {
    margin-right: 40% !important;
  }
  .u-pull-5\/10\@xxl {
    margin-right: 50% !important;
  }
  .u-pull-6\/10\@xxl {
    margin-right: 60% !important;
  }
  .u-pull-7\/10\@xxl {
    margin-right: 70% !important;
  }
  .u-pull-8\/10\@xxl {
    margin-right: 80% !important;
  }
  .u-pull-9\/10\@xxl {
    margin-right: 90% !important;
  }
  .u-pull-1\/12\@xxl {
    margin-right: 8.3333333333% !important;
  }
  .u-pull-2\/12\@xxl {
    margin-right: 16.6666666667% !important;
  }
  .u-pull-3\/12\@xxl {
    margin-right: 25% !important;
  }
  .u-pull-4\/12\@xxl {
    margin-right: 33.3333333333% !important;
  }
  .u-pull-5\/12\@xxl {
    margin-right: 41.6666666667% !important;
  }
  .u-pull-6\/12\@xxl {
    margin-right: 50% !important;
  }
  .u-pull-7\/12\@xxl {
    margin-right: 58.3333333333% !important;
  }
  .u-pull-8\/12\@xxl {
    margin-right: 66.6666666667% !important;
  }
  .u-pull-9\/12\@xxl {
    margin-right: 75% !important;
  }
  .u-pull-10\/12\@xxl {
    margin-right: 83.3333333333% !important;
  }
  .u-pull-11\/12\@xxl {
    margin-right: 91.6666666667% !important;
  }
  .u-pull-1\/14\@xxl {
    margin-right: 7.1428571429% !important;
  }
  .u-pull-2\/14\@xxl {
    margin-right: 14.2857142857% !important;
  }
  .u-pull-3\/14\@xxl {
    margin-right: 21.4285714286% !important;
  }
  .u-pull-4\/14\@xxl {
    margin-right: 28.5714285714% !important;
  }
  .u-pull-5\/14\@xxl {
    margin-right: 35.7142857143% !important;
  }
  .u-pull-6\/14\@xxl {
    margin-right: 42.8571428571% !important;
  }
  .u-pull-7\/14\@xxl {
    margin-right: 50% !important;
  }
  .u-pull-8\/14\@xxl {
    margin-right: 57.1428571429% !important;
  }
  .u-pull-9\/14\@xxl {
    margin-right: 64.2857142857% !important;
  }
  .u-pull-10\/14\@xxl {
    margin-right: 71.4285714286% !important;
  }
  .u-pull-11\/14\@xxl {
    margin-right: 78.5714285714% !important;
  }
  .u-pull-12\/14\@xxl {
    margin-right: 85.7142857143% !important;
  }
  .u-pull-13\/14\@xxl {
    margin-right: 92.8571428571% !important;
  }
  .u-pull-0\@xxl {
    margin-right: 0 !important;
  }
}
.u-push-1\/8 {
  margin-left: 12.5% !important;
}

.u-push-2\/8 {
  margin-left: 25% !important;
}

.u-push-3\/8 {
  margin-left: 37.5% !important;
}

.u-push-4\/8 {
  margin-left: 50% !important;
}

.u-push-5\/8 {
  margin-left: 62.5% !important;
}

.u-push-6\/8 {
  margin-left: 75% !important;
}

.u-push-7\/8 {
  margin-left: 87.5% !important;
}

.u-push-1\/10 {
  margin-left: 10% !important;
}

.u-push-2\/10 {
  margin-left: 20% !important;
}

.u-push-3\/10 {
  margin-left: 30% !important;
}

.u-push-4\/10 {
  margin-left: 40% !important;
}

.u-push-5\/10 {
  margin-left: 50% !important;
}

.u-push-6\/10 {
  margin-left: 60% !important;
}

.u-push-7\/10 {
  margin-left: 70% !important;
}

.u-push-8\/10 {
  margin-left: 80% !important;
}

.u-push-9\/10 {
  margin-left: 90% !important;
}

.u-push-1\/12 {
  margin-left: 8.3333333333% !important;
}

.u-push-2\/12 {
  margin-left: 16.6666666667% !important;
}

.u-push-3\/12 {
  margin-left: 25% !important;
}

.u-push-4\/12 {
  margin-left: 33.3333333333% !important;
}

.u-push-5\/12 {
  margin-left: 41.6666666667% !important;
}

.u-push-6\/12 {
  margin-left: 50% !important;
}

.u-push-7\/12 {
  margin-left: 58.3333333333% !important;
}

.u-push-8\/12 {
  margin-left: 66.6666666667% !important;
}

.u-push-9\/12 {
  margin-left: 75% !important;
}

.u-push-10\/12 {
  margin-left: 83.3333333333% !important;
}

.u-push-11\/12 {
  margin-left: 91.6666666667% !important;
}

.u-push-1\/14 {
  margin-left: 7.1428571429% !important;
}

.u-push-2\/14 {
  margin-left: 14.2857142857% !important;
}

.u-push-3\/14 {
  margin-left: 21.4285714286% !important;
}

.u-push-4\/14 {
  margin-left: 28.5714285714% !important;
}

.u-push-5\/14 {
  margin-left: 35.7142857143% !important;
}

.u-push-6\/14 {
  margin-left: 42.8571428571% !important;
}

.u-push-7\/14 {
  margin-left: 50% !important;
}

.u-push-8\/14 {
  margin-left: 57.1428571429% !important;
}

.u-push-9\/14 {
  margin-left: 64.2857142857% !important;
}

.u-push-10\/14 {
  margin-left: 71.4285714286% !important;
}

.u-push-11\/14 {
  margin-left: 78.5714285714% !important;
}

.u-push-12\/14 {
  margin-left: 85.7142857143% !important;
}

.u-push-13\/14 {
  margin-left: 92.8571428571% !important;
}

.u-push-0 {
  margin-left: 0 !important;
}

@media screen and (max-width: 767px) {
  .u-push-1\/8\@xs {
    margin-left: 12.5% !important;
  }
  .u-push-2\/8\@xs {
    margin-left: 25% !important;
  }
  .u-push-3\/8\@xs {
    margin-left: 37.5% !important;
  }
  .u-push-4\/8\@xs {
    margin-left: 50% !important;
  }
  .u-push-5\/8\@xs {
    margin-left: 62.5% !important;
  }
  .u-push-6\/8\@xs {
    margin-left: 75% !important;
  }
  .u-push-7\/8\@xs {
    margin-left: 87.5% !important;
  }
  .u-push-1\/10\@xs {
    margin-left: 10% !important;
  }
  .u-push-2\/10\@xs {
    margin-left: 20% !important;
  }
  .u-push-3\/10\@xs {
    margin-left: 30% !important;
  }
  .u-push-4\/10\@xs {
    margin-left: 40% !important;
  }
  .u-push-5\/10\@xs {
    margin-left: 50% !important;
  }
  .u-push-6\/10\@xs {
    margin-left: 60% !important;
  }
  .u-push-7\/10\@xs {
    margin-left: 70% !important;
  }
  .u-push-8\/10\@xs {
    margin-left: 80% !important;
  }
  .u-push-9\/10\@xs {
    margin-left: 90% !important;
  }
  .u-push-1\/12\@xs {
    margin-left: 8.3333333333% !important;
  }
  .u-push-2\/12\@xs {
    margin-left: 16.6666666667% !important;
  }
  .u-push-3\/12\@xs {
    margin-left: 25% !important;
  }
  .u-push-4\/12\@xs {
    margin-left: 33.3333333333% !important;
  }
  .u-push-5\/12\@xs {
    margin-left: 41.6666666667% !important;
  }
  .u-push-6\/12\@xs {
    margin-left: 50% !important;
  }
  .u-push-7\/12\@xs {
    margin-left: 58.3333333333% !important;
  }
  .u-push-8\/12\@xs {
    margin-left: 66.6666666667% !important;
  }
  .u-push-9\/12\@xs {
    margin-left: 75% !important;
  }
  .u-push-10\/12\@xs {
    margin-left: 83.3333333333% !important;
  }
  .u-push-11\/12\@xs {
    margin-left: 91.6666666667% !important;
  }
  .u-push-1\/14\@xs {
    margin-left: 7.1428571429% !important;
  }
  .u-push-2\/14\@xs {
    margin-left: 14.2857142857% !important;
  }
  .u-push-3\/14\@xs {
    margin-left: 21.4285714286% !important;
  }
  .u-push-4\/14\@xs {
    margin-left: 28.5714285714% !important;
  }
  .u-push-5\/14\@xs {
    margin-left: 35.7142857143% !important;
  }
  .u-push-6\/14\@xs {
    margin-left: 42.8571428571% !important;
  }
  .u-push-7\/14\@xs {
    margin-left: 50% !important;
  }
  .u-push-8\/14\@xs {
    margin-left: 57.1428571429% !important;
  }
  .u-push-9\/14\@xs {
    margin-left: 64.2857142857% !important;
  }
  .u-push-10\/14\@xs {
    margin-left: 71.4285714286% !important;
  }
  .u-push-11\/14\@xs {
    margin-left: 78.5714285714% !important;
  }
  .u-push-12\/14\@xs {
    margin-left: 85.7142857143% !important;
  }
  .u-push-13\/14\@xs {
    margin-left: 92.8571428571% !important;
  }
  .u-push-0\@xs {
    margin-left: 0 !important;
  }
}
@media screen and (min-width: 568px) {
  .u-push-1\/8\@s {
    margin-left: 12.5% !important;
  }
  .u-push-2\/8\@s {
    margin-left: 25% !important;
  }
  .u-push-3\/8\@s {
    margin-left: 37.5% !important;
  }
  .u-push-4\/8\@s {
    margin-left: 50% !important;
  }
  .u-push-5\/8\@s {
    margin-left: 62.5% !important;
  }
  .u-push-6\/8\@s {
    margin-left: 75% !important;
  }
  .u-push-7\/8\@s {
    margin-left: 87.5% !important;
  }
  .u-push-1\/10\@s {
    margin-left: 10% !important;
  }
  .u-push-2\/10\@s {
    margin-left: 20% !important;
  }
  .u-push-3\/10\@s {
    margin-left: 30% !important;
  }
  .u-push-4\/10\@s {
    margin-left: 40% !important;
  }
  .u-push-5\/10\@s {
    margin-left: 50% !important;
  }
  .u-push-6\/10\@s {
    margin-left: 60% !important;
  }
  .u-push-7\/10\@s {
    margin-left: 70% !important;
  }
  .u-push-8\/10\@s {
    margin-left: 80% !important;
  }
  .u-push-9\/10\@s {
    margin-left: 90% !important;
  }
  .u-push-1\/12\@s {
    margin-left: 8.3333333333% !important;
  }
  .u-push-2\/12\@s {
    margin-left: 16.6666666667% !important;
  }
  .u-push-3\/12\@s {
    margin-left: 25% !important;
  }
  .u-push-4\/12\@s {
    margin-left: 33.3333333333% !important;
  }
  .u-push-5\/12\@s {
    margin-left: 41.6666666667% !important;
  }
  .u-push-6\/12\@s {
    margin-left: 50% !important;
  }
  .u-push-7\/12\@s {
    margin-left: 58.3333333333% !important;
  }
  .u-push-8\/12\@s {
    margin-left: 66.6666666667% !important;
  }
  .u-push-9\/12\@s {
    margin-left: 75% !important;
  }
  .u-push-10\/12\@s {
    margin-left: 83.3333333333% !important;
  }
  .u-push-11\/12\@s {
    margin-left: 91.6666666667% !important;
  }
  .u-push-1\/14\@s {
    margin-left: 7.1428571429% !important;
  }
  .u-push-2\/14\@s {
    margin-left: 14.2857142857% !important;
  }
  .u-push-3\/14\@s {
    margin-left: 21.4285714286% !important;
  }
  .u-push-4\/14\@s {
    margin-left: 28.5714285714% !important;
  }
  .u-push-5\/14\@s {
    margin-left: 35.7142857143% !important;
  }
  .u-push-6\/14\@s {
    margin-left: 42.8571428571% !important;
  }
  .u-push-7\/14\@s {
    margin-left: 50% !important;
  }
  .u-push-8\/14\@s {
    margin-left: 57.1428571429% !important;
  }
  .u-push-9\/14\@s {
    margin-left: 64.2857142857% !important;
  }
  .u-push-10\/14\@s {
    margin-left: 71.4285714286% !important;
  }
  .u-push-11\/14\@s {
    margin-left: 78.5714285714% !important;
  }
  .u-push-12\/14\@s {
    margin-left: 85.7142857143% !important;
  }
  .u-push-13\/14\@s {
    margin-left: 92.8571428571% !important;
  }
  .u-push-0\@s {
    margin-left: 0 !important;
  }
}
@media screen and (min-width: 769px) {
  .u-push-1\/8\@sm {
    margin-left: 12.5% !important;
  }
  .u-push-2\/8\@sm {
    margin-left: 25% !important;
  }
  .u-push-3\/8\@sm {
    margin-left: 37.5% !important;
  }
  .u-push-4\/8\@sm {
    margin-left: 50% !important;
  }
  .u-push-5\/8\@sm {
    margin-left: 62.5% !important;
  }
  .u-push-6\/8\@sm {
    margin-left: 75% !important;
  }
  .u-push-7\/8\@sm {
    margin-left: 87.5% !important;
  }
  .u-push-1\/10\@sm {
    margin-left: 10% !important;
  }
  .u-push-2\/10\@sm {
    margin-left: 20% !important;
  }
  .u-push-3\/10\@sm {
    margin-left: 30% !important;
  }
  .u-push-4\/10\@sm {
    margin-left: 40% !important;
  }
  .u-push-5\/10\@sm {
    margin-left: 50% !important;
  }
  .u-push-6\/10\@sm {
    margin-left: 60% !important;
  }
  .u-push-7\/10\@sm {
    margin-left: 70% !important;
  }
  .u-push-8\/10\@sm {
    margin-left: 80% !important;
  }
  .u-push-9\/10\@sm {
    margin-left: 90% !important;
  }
  .u-push-1\/12\@sm {
    margin-left: 8.3333333333% !important;
  }
  .u-push-2\/12\@sm {
    margin-left: 16.6666666667% !important;
  }
  .u-push-3\/12\@sm {
    margin-left: 25% !important;
  }
  .u-push-4\/12\@sm {
    margin-left: 33.3333333333% !important;
  }
  .u-push-5\/12\@sm {
    margin-left: 41.6666666667% !important;
  }
  .u-push-6\/12\@sm {
    margin-left: 50% !important;
  }
  .u-push-7\/12\@sm {
    margin-left: 58.3333333333% !important;
  }
  .u-push-8\/12\@sm {
    margin-left: 66.6666666667% !important;
  }
  .u-push-9\/12\@sm {
    margin-left: 75% !important;
  }
  .u-push-10\/12\@sm {
    margin-left: 83.3333333333% !important;
  }
  .u-push-11\/12\@sm {
    margin-left: 91.6666666667% !important;
  }
  .u-push-1\/14\@sm {
    margin-left: 7.1428571429% !important;
  }
  .u-push-2\/14\@sm {
    margin-left: 14.2857142857% !important;
  }
  .u-push-3\/14\@sm {
    margin-left: 21.4285714286% !important;
  }
  .u-push-4\/14\@sm {
    margin-left: 28.5714285714% !important;
  }
  .u-push-5\/14\@sm {
    margin-left: 35.7142857143% !important;
  }
  .u-push-6\/14\@sm {
    margin-left: 42.8571428571% !important;
  }
  .u-push-7\/14\@sm {
    margin-left: 50% !important;
  }
  .u-push-8\/14\@sm {
    margin-left: 57.1428571429% !important;
  }
  .u-push-9\/14\@sm {
    margin-left: 64.2857142857% !important;
  }
  .u-push-10\/14\@sm {
    margin-left: 71.4285714286% !important;
  }
  .u-push-11\/14\@sm {
    margin-left: 78.5714285714% !important;
  }
  .u-push-12\/14\@sm {
    margin-left: 85.7142857143% !important;
  }
  .u-push-13\/14\@sm {
    margin-left: 92.8571428571% !important;
  }
  .u-push-0\@sm {
    margin-left: 0 !important;
  }
}
@media screen and (min-width: 992px) {
  .u-push-1\/8\@md {
    margin-left: 12.5% !important;
  }
  .u-push-2\/8\@md {
    margin-left: 25% !important;
  }
  .u-push-3\/8\@md {
    margin-left: 37.5% !important;
  }
  .u-push-4\/8\@md {
    margin-left: 50% !important;
  }
  .u-push-5\/8\@md {
    margin-left: 62.5% !important;
  }
  .u-push-6\/8\@md {
    margin-left: 75% !important;
  }
  .u-push-7\/8\@md {
    margin-left: 87.5% !important;
  }
  .u-push-1\/10\@md {
    margin-left: 10% !important;
  }
  .u-push-2\/10\@md {
    margin-left: 20% !important;
  }
  .u-push-3\/10\@md {
    margin-left: 30% !important;
  }
  .u-push-4\/10\@md {
    margin-left: 40% !important;
  }
  .u-push-5\/10\@md {
    margin-left: 50% !important;
  }
  .u-push-6\/10\@md {
    margin-left: 60% !important;
  }
  .u-push-7\/10\@md {
    margin-left: 70% !important;
  }
  .u-push-8\/10\@md {
    margin-left: 80% !important;
  }
  .u-push-9\/10\@md {
    margin-left: 90% !important;
  }
  .u-push-1\/12\@md {
    margin-left: 8.3333333333% !important;
  }
  .u-push-2\/12\@md {
    margin-left: 16.6666666667% !important;
  }
  .u-push-3\/12\@md {
    margin-left: 25% !important;
  }
  .u-push-4\/12\@md {
    margin-left: 33.3333333333% !important;
  }
  .u-push-5\/12\@md {
    margin-left: 41.6666666667% !important;
  }
  .u-push-6\/12\@md {
    margin-left: 50% !important;
  }
  .u-push-7\/12\@md {
    margin-left: 58.3333333333% !important;
  }
  .u-push-8\/12\@md {
    margin-left: 66.6666666667% !important;
  }
  .u-push-9\/12\@md {
    margin-left: 75% !important;
  }
  .u-push-10\/12\@md {
    margin-left: 83.3333333333% !important;
  }
  .u-push-11\/12\@md {
    margin-left: 91.6666666667% !important;
  }
  .u-push-1\/14\@md {
    margin-left: 7.1428571429% !important;
  }
  .u-push-2\/14\@md {
    margin-left: 14.2857142857% !important;
  }
  .u-push-3\/14\@md {
    margin-left: 21.4285714286% !important;
  }
  .u-push-4\/14\@md {
    margin-left: 28.5714285714% !important;
  }
  .u-push-5\/14\@md {
    margin-left: 35.7142857143% !important;
  }
  .u-push-6\/14\@md {
    margin-left: 42.8571428571% !important;
  }
  .u-push-7\/14\@md {
    margin-left: 50% !important;
  }
  .u-push-8\/14\@md {
    margin-left: 57.1428571429% !important;
  }
  .u-push-9\/14\@md {
    margin-left: 64.2857142857% !important;
  }
  .u-push-10\/14\@md {
    margin-left: 71.4285714286% !important;
  }
  .u-push-11\/14\@md {
    margin-left: 78.5714285714% !important;
  }
  .u-push-12\/14\@md {
    margin-left: 85.7142857143% !important;
  }
  .u-push-13\/14\@md {
    margin-left: 92.8571428571% !important;
  }
  .u-push-0\@md {
    margin-left: 0 !important;
  }
}
@media screen and (min-width: 1200px) {
  .u-push-1\/8\@lg {
    margin-left: 12.5% !important;
  }
  .u-push-2\/8\@lg {
    margin-left: 25% !important;
  }
  .u-push-3\/8\@lg {
    margin-left: 37.5% !important;
  }
  .u-push-4\/8\@lg {
    margin-left: 50% !important;
  }
  .u-push-5\/8\@lg {
    margin-left: 62.5% !important;
  }
  .u-push-6\/8\@lg {
    margin-left: 75% !important;
  }
  .u-push-7\/8\@lg {
    margin-left: 87.5% !important;
  }
  .u-push-1\/10\@lg {
    margin-left: 10% !important;
  }
  .u-push-2\/10\@lg {
    margin-left: 20% !important;
  }
  .u-push-3\/10\@lg {
    margin-left: 30% !important;
  }
  .u-push-4\/10\@lg {
    margin-left: 40% !important;
  }
  .u-push-5\/10\@lg {
    margin-left: 50% !important;
  }
  .u-push-6\/10\@lg {
    margin-left: 60% !important;
  }
  .u-push-7\/10\@lg {
    margin-left: 70% !important;
  }
  .u-push-8\/10\@lg {
    margin-left: 80% !important;
  }
  .u-push-9\/10\@lg {
    margin-left: 90% !important;
  }
  .u-push-1\/12\@lg {
    margin-left: 8.3333333333% !important;
  }
  .u-push-2\/12\@lg {
    margin-left: 16.6666666667% !important;
  }
  .u-push-3\/12\@lg {
    margin-left: 25% !important;
  }
  .u-push-4\/12\@lg {
    margin-left: 33.3333333333% !important;
  }
  .u-push-5\/12\@lg {
    margin-left: 41.6666666667% !important;
  }
  .u-push-6\/12\@lg {
    margin-left: 50% !important;
  }
  .u-push-7\/12\@lg {
    margin-left: 58.3333333333% !important;
  }
  .u-push-8\/12\@lg {
    margin-left: 66.6666666667% !important;
  }
  .u-push-9\/12\@lg {
    margin-left: 75% !important;
  }
  .u-push-10\/12\@lg {
    margin-left: 83.3333333333% !important;
  }
  .u-push-11\/12\@lg {
    margin-left: 91.6666666667% !important;
  }
  .u-push-1\/14\@lg {
    margin-left: 7.1428571429% !important;
  }
  .u-push-2\/14\@lg {
    margin-left: 14.2857142857% !important;
  }
  .u-push-3\/14\@lg {
    margin-left: 21.4285714286% !important;
  }
  .u-push-4\/14\@lg {
    margin-left: 28.5714285714% !important;
  }
  .u-push-5\/14\@lg {
    margin-left: 35.7142857143% !important;
  }
  .u-push-6\/14\@lg {
    margin-left: 42.8571428571% !important;
  }
  .u-push-7\/14\@lg {
    margin-left: 50% !important;
  }
  .u-push-8\/14\@lg {
    margin-left: 57.1428571429% !important;
  }
  .u-push-9\/14\@lg {
    margin-left: 64.2857142857% !important;
  }
  .u-push-10\/14\@lg {
    margin-left: 71.4285714286% !important;
  }
  .u-push-11\/14\@lg {
    margin-left: 78.5714285714% !important;
  }
  .u-push-12\/14\@lg {
    margin-left: 85.7142857143% !important;
  }
  .u-push-13\/14\@lg {
    margin-left: 92.8571428571% !important;
  }
  .u-push-0\@lg {
    margin-left: 0 !important;
  }
}
@media screen and (min-width: 1681px) {
  .u-push-1\/8\@xl {
    margin-left: 12.5% !important;
  }
  .u-push-2\/8\@xl {
    margin-left: 25% !important;
  }
  .u-push-3\/8\@xl {
    margin-left: 37.5% !important;
  }
  .u-push-4\/8\@xl {
    margin-left: 50% !important;
  }
  .u-push-5\/8\@xl {
    margin-left: 62.5% !important;
  }
  .u-push-6\/8\@xl {
    margin-left: 75% !important;
  }
  .u-push-7\/8\@xl {
    margin-left: 87.5% !important;
  }
  .u-push-1\/10\@xl {
    margin-left: 10% !important;
  }
  .u-push-2\/10\@xl {
    margin-left: 20% !important;
  }
  .u-push-3\/10\@xl {
    margin-left: 30% !important;
  }
  .u-push-4\/10\@xl {
    margin-left: 40% !important;
  }
  .u-push-5\/10\@xl {
    margin-left: 50% !important;
  }
  .u-push-6\/10\@xl {
    margin-left: 60% !important;
  }
  .u-push-7\/10\@xl {
    margin-left: 70% !important;
  }
  .u-push-8\/10\@xl {
    margin-left: 80% !important;
  }
  .u-push-9\/10\@xl {
    margin-left: 90% !important;
  }
  .u-push-1\/12\@xl {
    margin-left: 8.3333333333% !important;
  }
  .u-push-2\/12\@xl {
    margin-left: 16.6666666667% !important;
  }
  .u-push-3\/12\@xl {
    margin-left: 25% !important;
  }
  .u-push-4\/12\@xl {
    margin-left: 33.3333333333% !important;
  }
  .u-push-5\/12\@xl {
    margin-left: 41.6666666667% !important;
  }
  .u-push-6\/12\@xl {
    margin-left: 50% !important;
  }
  .u-push-7\/12\@xl {
    margin-left: 58.3333333333% !important;
  }
  .u-push-8\/12\@xl {
    margin-left: 66.6666666667% !important;
  }
  .u-push-9\/12\@xl {
    margin-left: 75% !important;
  }
  .u-push-10\/12\@xl {
    margin-left: 83.3333333333% !important;
  }
  .u-push-11\/12\@xl {
    margin-left: 91.6666666667% !important;
  }
  .u-push-1\/14\@xl {
    margin-left: 7.1428571429% !important;
  }
  .u-push-2\/14\@xl {
    margin-left: 14.2857142857% !important;
  }
  .u-push-3\/14\@xl {
    margin-left: 21.4285714286% !important;
  }
  .u-push-4\/14\@xl {
    margin-left: 28.5714285714% !important;
  }
  .u-push-5\/14\@xl {
    margin-left: 35.7142857143% !important;
  }
  .u-push-6\/14\@xl {
    margin-left: 42.8571428571% !important;
  }
  .u-push-7\/14\@xl {
    margin-left: 50% !important;
  }
  .u-push-8\/14\@xl {
    margin-left: 57.1428571429% !important;
  }
  .u-push-9\/14\@xl {
    margin-left: 64.2857142857% !important;
  }
  .u-push-10\/14\@xl {
    margin-left: 71.4285714286% !important;
  }
  .u-push-11\/14\@xl {
    margin-left: 78.5714285714% !important;
  }
  .u-push-12\/14\@xl {
    margin-left: 85.7142857143% !important;
  }
  .u-push-13\/14\@xl {
    margin-left: 92.8571428571% !important;
  }
  .u-push-0\@xl {
    margin-left: 0 !important;
  }
}
@media screen and (min-width: 2000px) {
  .u-push-1\/8\@xxl {
    margin-left: 12.5% !important;
  }
  .u-push-2\/8\@xxl {
    margin-left: 25% !important;
  }
  .u-push-3\/8\@xxl {
    margin-left: 37.5% !important;
  }
  .u-push-4\/8\@xxl {
    margin-left: 50% !important;
  }
  .u-push-5\/8\@xxl {
    margin-left: 62.5% !important;
  }
  .u-push-6\/8\@xxl {
    margin-left: 75% !important;
  }
  .u-push-7\/8\@xxl {
    margin-left: 87.5% !important;
  }
  .u-push-1\/10\@xxl {
    margin-left: 10% !important;
  }
  .u-push-2\/10\@xxl {
    margin-left: 20% !important;
  }
  .u-push-3\/10\@xxl {
    margin-left: 30% !important;
  }
  .u-push-4\/10\@xxl {
    margin-left: 40% !important;
  }
  .u-push-5\/10\@xxl {
    margin-left: 50% !important;
  }
  .u-push-6\/10\@xxl {
    margin-left: 60% !important;
  }
  .u-push-7\/10\@xxl {
    margin-left: 70% !important;
  }
  .u-push-8\/10\@xxl {
    margin-left: 80% !important;
  }
  .u-push-9\/10\@xxl {
    margin-left: 90% !important;
  }
  .u-push-1\/12\@xxl {
    margin-left: 8.3333333333% !important;
  }
  .u-push-2\/12\@xxl {
    margin-left: 16.6666666667% !important;
  }
  .u-push-3\/12\@xxl {
    margin-left: 25% !important;
  }
  .u-push-4\/12\@xxl {
    margin-left: 33.3333333333% !important;
  }
  .u-push-5\/12\@xxl {
    margin-left: 41.6666666667% !important;
  }
  .u-push-6\/12\@xxl {
    margin-left: 50% !important;
  }
  .u-push-7\/12\@xxl {
    margin-left: 58.3333333333% !important;
  }
  .u-push-8\/12\@xxl {
    margin-left: 66.6666666667% !important;
  }
  .u-push-9\/12\@xxl {
    margin-left: 75% !important;
  }
  .u-push-10\/12\@xxl {
    margin-left: 83.3333333333% !important;
  }
  .u-push-11\/12\@xxl {
    margin-left: 91.6666666667% !important;
  }
  .u-push-1\/14\@xxl {
    margin-left: 7.1428571429% !important;
  }
  .u-push-2\/14\@xxl {
    margin-left: 14.2857142857% !important;
  }
  .u-push-3\/14\@xxl {
    margin-left: 21.4285714286% !important;
  }
  .u-push-4\/14\@xxl {
    margin-left: 28.5714285714% !important;
  }
  .u-push-5\/14\@xxl {
    margin-left: 35.7142857143% !important;
  }
  .u-push-6\/14\@xxl {
    margin-left: 42.8571428571% !important;
  }
  .u-push-7\/14\@xxl {
    margin-left: 50% !important;
  }
  .u-push-8\/14\@xxl {
    margin-left: 57.1428571429% !important;
  }
  .u-push-9\/14\@xxl {
    margin-left: 64.2857142857% !important;
  }
  .u-push-10\/14\@xxl {
    margin-left: 71.4285714286% !important;
  }
  .u-push-11\/14\@xxl {
    margin-left: 78.5714285714% !important;
  }
  .u-push-12\/14\@xxl {
    margin-left: 85.7142857143% !important;
  }
  .u-push-13\/14\@xxl {
    margin-left: 92.8571428571% !important;
  }
  .u-push-0\@xxl {
    margin-left: 0 !important;
  }
}
@font-face {
  font-family: "RelativePro-Book";
  src: url("/fonts/RelativePro-Book.woff") format("woff"), url("/fonts/RelativePro-Book.woff2") format("woff2");
}
@font-face {
  font-family: "RelativePro-Medium";
  src: url("/fonts/RelativePro-Medium.woff") format("woff"), url("/fonts/RelativePro-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "RelativePro-Bold";
  src: url("/fonts/relative-bold-pro.woff") format("woff"), url("/fonts/relative-bold-pro.woff2") format("woff2");
}
@font-face {
  font-family: "Handwriting";
  src: url("/fonts/ffmarket.woff") format("woff"), url("/fonts/ffmarket.woff2") format("woff2");
}
@font-face {
  font-family: "coda-icons";
  src: url("/fonts/icomoon.woff") format("woff"), url("/fonts/icomoon.woff2") format("woff2");
}
/**
 * Functions
 *
 */
/**
 * Mixins
 *
 */
/**
 * Usage:
 * $name: required, Font Name from base map
 * $size: optional, Font Size in px, set $base-font-in-rem to true to enable rem calculation
 * $lineHeight: optional, multiplier of font-size
 *
 * @include font($name);
 * @include font($name, $size);
 * @include font($name, $size, $lineHeight);
 *
 * Setup:
 * $base-fonts: Sass Map
 *
 * $base-fonts: (
 *  regular: 'proxima-nova', // single value
 *  bold: ('proxima-nova', 700), // with font-weight for Typekit Fonts or similar
 * );
 */
/**
 * Iota breakpoint shortcut
 *
 */
*,
*::before,
*::after {
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

li {
  list-style-type: none;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;
  /* Corrects font smoothing for webkit */
  font-smoothing: inherit;
  /* Corrects inability to style clickable `input` types in iOS */
  -appearance: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
/* Icons */
@font-face {
  font-family: "slick";
  src: url("/fonts/slick.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/**
* DO NOT EDIT THIS FILE, IT WILL BE OVERRIDDEN ON SASS COMPILATION
*/
/* 0: /app/components/Atoms/Checkbox/Checkbox.scss */
.Checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.Checkbox .box {
  width: 40px;
  height: 40px;
  border: 1px solid #ffd2c8;
  background: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #ff4b23;
  margin-right: 6px;
}
@media screen and (min-width: 992px) {
  .Checkbox .box {
    width: 50px;
    height: 50px;
  }
}
.Checkbox .box:hover {
  background: #fff5f3;
}
.Checkbox .box > * {
  display: none;
}
.Checkbox.checked .box {
  background: #ffd2c8;
}
.Checkbox.checked .box > * {
  display: block;
}
.Checkbox.narrow .box {
  width: 24px;
  height: 24px;
  border-radius: 3px;
  font-size: 16px;
}

/* 1: /app/components/Atoms/ContactIcon/ContactIcon.scss */
.ContactIcon {
  display: block;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
} /* 2: /app/components/Atoms/Hamburger/Hamburger.scss */
/* 3: /app/components/Atoms/Highlight/Highlight.scss */
.Highlight {
  position: absolute;
  bottom: 20px;
  right: 50px;
  z-index: 10;
  background: white;
  max-width: 300px;
  padding: 10px;
  padding-top: 6px;
  border-radius: 16px;
}

/* 4: /app/components/Atoms/LanguagePicker/LanguagePicker.scss */
/* 5: /app/components/Atoms/Link/Link.scss */
/* 6: /app/components/Atoms/LinkButton/LinkButton.scss */
/* 7: /app/components/Atoms/LoginButton/LoginButton.scss */
/* 8: /app/components/Atoms/Logo/Logo.scss */
.Logo {
  width: 100%;
  height: 100%;
}
.Logo svg {
  height: 100%;
  width: auto;
}
.Logo .logo-color {
  transition: all 300ms ease-in-out;
}

/* 9: /app/components/Atoms/ScrollIndicator/ScrollIndicator.scss */
.ScrollIndicator {
  position: absolute;
  bottom: 16px;
  right: 20px;
  z-index: 10;
  display: flex;
  align-items: center;
  gap: 4px;
  transform-origin: right top;
  transform: rotate(90deg);
  animation: move 3s infinite ease-out;
}

@keyframes move {
  0% {
    transform: rotate(90deg) translateX(0);
  }
  50% {
    transform: rotate(90deg) translateX(15px);
  }
  100% {
    transform: rotate(90deg) translateX(0);
  }
} /* 10: /app/components/Atoms/Select/Select.scss */
.Select .Dropdown-root {
  position: relative;
  color: #ff4b23;
}
.Select .Dropdown-control {
  position: relative;
  overflow: hidden;
  background-color: white;
  border: 1px solid #ffd2c8;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: default;
  outline: none;
  padding: 0 12px 0 12px;
  height: 50px;
  transition: all 200ms ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Select .Dropdown-control:hover {
  background: #fff5f3;
}
.Select .Dropdown-arrow {
  border-color: #ff4b23 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  width: 0;
}
.Select .is-open .Dropdown-arrow {
  border-color: transparent transparent #ff4b23;
  border-width: 0 5px 5px;
}
.Select .Dropdown-menu {
  background-color: white;
  border: 1px solid #ffd2c8;
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}
.Select .Dropdown-menu .Dropdown-group > .Dropdown-title {
  padding: 8px 10px;
  font-weight: bold;
  text-transform: capitalize;
}
.Select .Dropdown-option {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}
.Select .Dropdown-option:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}
.Select .Dropdown-option:hover {
  background: #fff5f3;
}
.Select .Dropdown-option.is-selected {
  background: #ffd2c8;
}
.Select .Dropdown-noresults {
  box-sizing: border-box;
  cursor: default;
  display: block;
  padding: 8px 10px;
}

/* 11: /app/components/Atoms/Todo/Todo.scss */
.Todo {
  background: #ffe686;
  padding: 10px;
}

/* 12: /app/components/Blocks/Filters/Filters.scss */
.Filters {
  background: white;
  border-radius: 16px 0 0 0;
  margin-right: -20px;
}
@media screen and (min-width: 992px) {
  .Filters {
    border-radius: 16px 16px 0 0;
    margin-right: 0;
  }
}
.Filters.border {
  border-bottom: 1px solid #e4e4e4;
}
.Filters .label {
  padding: 10px 10px 0 10px;
}
.Filters .tabs {
  min-height: 100px;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: scroll;
}
@media screen and (min-width: 992px) {
  .Filters .tabs {
    overflow: hidden;
  }
}
.Filters .tabs > li {
  position: relative;
  height: 100%;
  padding: 0 20px;
  white-space: nowrap;
}
.Filters .tabs button {
  cursor: pointer;
  height: 100%;
  border-bottom: 4px solid transparent;
}
.Filters .tabs button.selected {
  border-bottom: 4px solid #ff4b23;
}
.Filters .chips {
  min-height: 100px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
}
.Filters .chips button {
  padding: 4px 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  border: 1px solid #ff4b23;
  cursor: pointer;
  white-space: nowrap;
}
.Filters .chips button .ic {
  margin-left: 8px;
  display: none;
  transform: translateY(3px);
}
.Filters .chips button:hover {
  background: #fff5f3 !important;
}
.Filters .chips button.selected {
  color: #ff4b23;
  background: #ffd2c8;
}
.Filters .chips button.selected .ic-check {
  display: block !important;
}
.Filters .chips button.selected:hover .ic-check {
  display: none !important;
}
.Filters .chips button.selected:hover .ic-close {
  display: block !important;
}

/* 13: /app/components/Blocks/Navigation/Element/Element.scss */
/* 14: /app/components/Blocks/Navigation/Level/Level.scss */
.Level {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 3;
}
@media screen and (min-width: 992px) {
  .Level {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25%;
  }
}
.Level li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}
@media screen and (min-width: 992px) {
  .Level li {
    padding: 0;
  }
}
.Level ul.sub.prio > li {
  margin-bottom: 16px;
}
.Level ul.sub.noprio > li {
  margin-bottom: 16px;
}
@media screen and (min-width: 992px) {
  .Level ul.sub.noprio > li {
    margin-bottom: 8px;
  }
}
.Level .title {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  background: #ff4b23;
  padding: 10px;
}
@media screen and (min-width: 992px) {
  .Level .title {
    background: none;
    padding: 0;
  }
}
.Level .title .Icon, .Level .title .SliderVideos .slick-prev, .SliderVideos .Level .title .slick-prev,
.Level .title .SliderVideos .slick-next,
.SliderVideos .Level .title .slick-next, .Level .title .SliderQuotes .slick-prev, .SliderQuotes .Level .title .slick-prev,
.Level .title .SliderQuotes .slick-next,
.SliderQuotes .Level .title .slick-next, .Level .title .SliderNumbers .slick-prev, .SliderNumbers .Level .title .slick-prev,
.Level .title .SliderNumbers .slick-next,
.SliderNumbers .Level .title .slick-next, .Level .title .SliderImages .slick-prev, .SliderImages .Level .title .slick-prev,
.Level .title .SliderImages .slick-next,
.SliderImages .Level .title .slick-next, .Level .title .SliderGuests .slick-prev, .SliderGuests .Level .title .slick-prev,
.Level .title .SliderGuests .slick-next,
.SliderGuests .Level .title .slick-next, .Level .title .Gallery .slick-prev, .Gallery .Level .title .slick-prev,
.Level .title .Gallery .slick-next,
.Gallery .Level .title .slick-next {
  margin-right: 6px;
}
.Level.l1 {
  width: 100%;
  background: #ff7356;
  padding: 0;
  padding-top: 0;
  overflow: scroll;
}
@media screen and (min-width: 992px) {
  .Level.l1 {
    background: #ff4b23;
    padding: 40px 60px;
    width: 50%;
  }
}
.Level.l2 {
  background: #ffd2c8;
  padding: 0;
  left: 0%;
  overflow: scroll;
}
@media screen and (min-width: 992px) {
  .Level.l2 {
    padding: 40px 20px;
    left: 50%;
  }
}
.Level.l2 ul.sub.prio > li {
  margin-bottom: 8px;
}
.Level.l3 {
  padding: 0;
  left: 75%;
  background: #fff5f3;
  color: #000000;
}
@media screen and (min-width: 992px) {
  .Level.l3 {
    padding: 40px 20px;
  }
}
.Level.l1, .Level.l2 {
  overflow: scroll;
}
.Level .image {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.Level .image img {
  height: 100%;
  object-fit: cover;
  width: auto;
}

/* 15: /app/components/Blocks/Navigation/Navigation.scss */
.Navigation {
  height: 100%;
}
.Navigation ul.main {
  position: fixed;
  top: 72px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ff4b23;
  padding: 10px;
}
@media screen and (min-width: 992px) {
  .Navigation ul.main {
    position: relative;
    top: unset;
    left: unset;
    right: unset;
    bottom: unset;
    background: none;
    display: flex;
    align-items: center;
    gap: 30px;
    height: 100%;
    padding: 0;
  }
}
.Navigation li.main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
@media screen and (min-width: 992px) {
  .Navigation li.main {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 992px) {
  .Navigation li.main .arrow {
    display: none;
  }
}

.Navigation-Dropdown {
  position: fixed;
  top: 72px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  opacity: 0;
  visibility: hidden;
}
.Navigation-Dropdown .leave-trigger {
  bottom: 0;
  left: 75%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.Navigation-Dropdown.visible {
  opacity: 1;
  visibility: visible;
}

/* 16: /app/components/Blocks/NavigationFooter/NavigationFooter.scss */
.NavigationFooter {
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding: 20px 0;
  text-align: center;
}
@media screen and (min-width: 992px) {
  .NavigationFooter {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.NavigationFooter li {
  display: inline-block;
  margin-right: 10px;
}
@media screen and (min-width: 992px) {
  .NavigationFooter li {
    margin-right: 0;
    flex: 1;
  }
}

/* 17: /app/components/Blocks/PaymentProviders/PaymentProviders.scss */
.PaymentProviders {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
}
.PaymentProviders img {
  width: 50px;
  opacity: 0.7;
}

/* 18: /app/components/Blocks/Search/Hit/Hit.scss */
.Hit a {
  text-decoration: underline;
}
.Hit .title {
  display: flex;
}

/* 19: /app/components/Blocks/Search/Insights/Insights.scss */
/* 20: /app/components/Blocks/Search/Search.scss */
.Search {
  position: fixed;
  top: 72px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000000;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 56px 1fr;
}
.Search .box {
  position: relative;
  background: #000000;
}
.Search .box input {
  display: block;
  width: 100%;
  height: 56px;
  appearance: none;
  border: 0;
  background: #000000;
  color: #ffffff;
  outline: none;
}
.Search .box .submit {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
.Search .box .reset {
  display: none;
}
.Search .box .Icon, .Search .box .SliderVideos .slick-prev, .SliderVideos .Search .box .slick-prev,
.Search .box .SliderVideos .slick-next,
.SliderVideos .Search .box .slick-next, .Search .box .SliderQuotes .slick-prev, .SliderQuotes .Search .box .slick-prev,
.Search .box .SliderQuotes .slick-next,
.SliderQuotes .Search .box .slick-next, .Search .box .SliderNumbers .slick-prev, .SliderNumbers .Search .box .slick-prev,
.Search .box .SliderNumbers .slick-next,
.SliderNumbers .Search .box .slick-next, .Search .box .SliderImages .slick-prev, .SliderImages .Search .box .slick-prev,
.Search .box .SliderImages .slick-next,
.SliderImages .Search .box .slick-next, .Search .box .SliderGuests .slick-prev, .SliderGuests .Search .box .slick-prev,
.Search .box .SliderGuests .slick-next,
.SliderGuests .Search .box .slick-next, .Search .box .Gallery .slick-prev, .Gallery .Search .box .slick-prev,
.Search .box .Gallery .slick-next,
.Gallery .Search .box .slick-next {
  background: #ff4b23;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
.Search .results {
  background: rgba(255, 255, 255, 0.9);
  overflow: scroll;
  backdrop-filter: blur(5px);
}

/* 21: /app/components/Blocks/Search/Stats/Stats.scss */
/* 22: /app/components/Blocks/Search/Trigger/Trigger.scss */
.Trigger {
  cursor: pointer;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 23: /app/components/Consent/Box/Box.scss */
.Cookie-Box {
  position: fixed;
  bottom: 10px;
  left: 10px;
  right: 10px;
  background: white;
  border-radius: 20px;
  box-shadow: 0 4px 14px 8px rgba(8, 0, 30, 0.1);
  z-index: 1900;
  padding: 6px;
}
@media screen and (min-width: 992px) {
  .Cookie-Box {
    bottom: 20px;
    left: 20px;
    right: unset;
    width: 100%;
    max-width: 640px;
    padding: 12px;
  }
}
.Cookie-Box .form {
  overflow: scroll;
  max-height: 50vh;
}
@media screen and (min-width: 992px) {
  .Cookie-Box .form {
    max-height: 60vh;
  }
}
.Cookie-Box .buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}
@media screen and (min-width: 992px) {
  .Cookie-Box .buttons {
    flex-direction: row;
  }
}
.Cookie-Box .buttons > button {
  width: 100%;
}
@media screen and (min-width: 992px) {
  .Cookie-Box .buttons > button {
    width: auto;
    flex: 1;
  }
}

/* 24: /app/components/Consent/Consent.scss */
/* 25: /app/components/Consent/Form/Category/Category.scss */
.Category {
  padding: 12px 0;
}
.Category .name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Category .info {
  position: relative;
  padding-left: 40px;
}
.Category .info .checkbox {
  position: absolute;
  left: 0;
  top: 3px;
}
.Category .details {
  margin-left: 20px;
  overflow: hidden;
}

/* 26: /app/components/Consent/Form/Form.scss */
/* 27: /app/components/Consent/Form/Provider/Provider.scss */
.Provider {
  position: relative;
  padding-left: 40px;
  margin-top: 12px;
}
.Provider .checkbox {
  position: absolute;
  top: 0px;
  left: 0;
}
.Provider .cookies {
  margin-top: 6px;
  height: 0;
  overflow: hidden;
}
.Provider .cookie {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 0 6px;
  padding: 6px;
}
.Provider .cookie:last-child {
  border-bottom: 0;
}
.Provider .cookie > div {
  display: flex;
  align-items: center;
}

/* 28: /app/components/Effects/InView/InView.scss */
.InView.fillHeight {
  height: 100%;
}

/* 29: /app/components/Effects/LetterReveal/LetterReveal.scss */
.LetterReveal .line {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.LetterReveal .letter {
  position: relative;
  padding-bottom: 10px;
}

/* 30: /app/components/Effects/Parallax/Parallax.scss */
/* 31: /app/components/Effects/Skew/Skew.scss */
/* 32: /app/components/Effects/TextHover/TextHover.scss */
/* 33: /app/components/Helpers/Anchor/Anchor.scss */
/* 34: /app/components/Helpers/BrowserSupport/BrowserSupport.scss */
body .buorg {
  position: absolute;
  position: fixed;
  z-index: 111111;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: none;
  text-align: center;
  box-shadow: 0 4px 14px 8px rgba(8, 0, 30, 0.1);
  color: white;
  animation: 0.5s ease-out 0s buorgfly;
}
body .buorg-icon {
  display: none !important;
}
body .buorg-mainmsg {
  display: block;
  font-weight: 500;
}
body .buorg-pad {
  padding: 10px;
}
body .buorg-buttons {
  display: block;
  text-align: center;
  margin-top: 6px;
}
body #buorgig,
body #buorgul,
body #buorgpermanent {
  color: white;
  text-decoration: none;
  cursor: pointer;
  box-shadow: none;
  padding: 4px 8px 2px;
  border-radius: 60px;
  white-space: nowrap;
  margin: 0 4px;
  display: inline-block;
  transition: all 250ms ease-out;
}
body #buorgig:hover,
body #buorgul:hover,
body #buorgpermanent:hover {
  background: white;
  border: 1px solid white;
}
body #buorgig {
  background-color: transparent;
}
body .buorg-fadeout {
  transition: visibility 0s 8.5s, opacity 8s ease-out 0.5s;
}
body .buorg-icon {
  width: 22px;
  height: 16px;
  vertical-align: middle;
  position: relative;
  top: -0.05em;
  display: inline-block;
  background: no-repeat 0px center;
}

/* 35: /app/components/Helpers/ClientOnly/ClientOnly.scss */
/* 36: /app/components/Helpers/DebugBar/DebugBar.scss */
.DebugBar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 4px 20px;
  z-index: 1001;
  border-top: 1px solid #efefef;
  box-shadow: 0px -5px 20px -11px rgba(0, 0, 0, 0.2);
}
.DebugBar ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

/* 37: /app/components/Helpers/Error/Error.scss */
.Error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  text-align: center;
}

/* 38: /app/components/Helpers/Json/Json.scss */
.json {
  background: #fff;
  padding: 15px;
}

/* 39: /app/components/Helpers/Providers/Providers.scss */
/* 40: /app/components/Helpers/QuizScript/QuizScript.scss */
.sa--chat-button .sa-button_text {
  font-family: "RelativePro-Medium";
}

/* 41: /app/components/Helpers/TagManager/TagManager.scss */
/* 42: /app/components/Modals/Booking/Booking.scss */
.Booking {
  padding: 0 10px;
}
@media screen and (min-width: 992px) {
  .Booking {
    padding: 0;
  }
}
.Booking iframe {
  width: 100%;
  max-height: 400px;
}

/* 43: /app/components/Modals/Coupons/Coupons.scss */
.Coupons iframe {
  width: 100%;
  height: 40vh;
}

/* 44: /app/components/Modals/Courses/Courses.scss */
.Courses {
  padding: 0;
}
@media screen and (min-width: 992px) {
  .Courses {
    padding-right: 10px;
  }
}
.Courses .left {
  background: #fff5f3;
}
.Courses .left > .image {
  display: none;
}
@media screen and (min-width: 992px) {
  .Courses .left > .image {
    display: block;
  }
}
.Courses .badges > * {
  margin-right: 6px;
}
.Courses .meta {
  padding: 0 20px;
}
.Courses .course {
  border-bottom: 1px solid #e4e4e4;
  padding: 0 10px;
}
.Courses .course:last-child {
  border: 0;
}
@media screen and (min-width: 992px) {
  .Courses .course {
    padding: 0;
  }
}
.Courses .scroller {
  max-height: 60vh;
  overflow: scroll;
  padding-right: 10px;
}

/* 45: /app/components/Modals/Guestinfo/Guestinfo.scss */
.Guestinfo {
  padding: 10px;
}
@media screen and (min-width: 992px) {
  .Guestinfo {
    padding: 0;
  }
}
.Guestinfo iframe {
  width: 100%;
}
.Guestinfo .image {
  border-radius: 8px;
  overflow: hidden;
}
.Guestinfo .left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  align-items: flex-start;
}
.Guestinfo .scrollableContent {
  max-height: 400px;
  overflow: scroll;
}

/* 46: /app/components/Modals/Newsletter/Newsletter.scss */
.Newsletter iframe {
  width: 100%;
}

/* 47: /app/components/Modals/Product/Product.scss */
/* 48: /app/components/Modals/Welcome/Welcome.scss */
.Welcome .logo {
  display: flex;
  justify-content: flex-start;
  height: 30px;
  padding: 0 10px;
}
@media screen and (min-width: 992px) {
  .Welcome .logo {
    justify-content: center;
    height: 50px;
  }
}
.Welcome .logo .Logo {
  width: auto;
}
.Welcome .cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 60vh;
}
@media screen and (min-width: 992px) {
  .Welcome .cards {
    height: 50vh;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
.Welcome .cards .image {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  z-index: 1000;
}
.Welcome .cards .image:after {
  transition: 200ms ease-out;
  content: " ";
  z-index: 1001;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.2) 100%);
}
.Welcome .cards .image svg {
  height: 100%;
  width: 100%;
}
.Welcome .cards .image svg .hover-image {
  position: absolute;
  opacity: 1;
  transition: 200ms ease-out;
}
.Welcome .cards .image:hover .hover-image {
  opacity: 0;
}
.Welcome .cards .image:hover:after {
  opacity: 0;
}
.Welcome .cards .card {
  position: relative;
}
.Welcome .cards .title {
  position: absolute;
  bottom: 8px;
  left: 8px;
  z-index: 1002;
  width: 70%;
}
@media screen and (min-width: 992px) {
  .Welcome .cards .title {
    bottom: 16px;
    left: 16px;
    width: 50%;
  }
}
.Welcome .cards .title img {
  width: 100%;
}

/* 49: /app/components/Modules/Accordeon/Accordeon.scss */
.Accordeon .trigger {
  position: relative;
  cursor: pointer;
}
.Accordeon .arrow {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
.Accordeon .boxes {
  border-top: 1px solid #e4e4e4;
}
.Accordeon .box {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e4e4e4;
}

/* 50: /app/components/Modules/Agenda/Agenda.scss */
.Agenda .boxes {
  border-top: 1px solid #e4e4e4;
}
.Agenda .box {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
}
.Agenda .box:after {
  content: "";
  height: 1px;
  background: #e4e4e4;
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 0;
}

/* 51: /app/components/Modules/ArticleTeaser/ArticleTeaser.scss */
@media screen and (min-width: 992px) {
  .ArticleTeaser .headline {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.ArticleTeaser .title {
  display: block;
  margin-bottom: 10px;
}
@media screen and (min-width: 992px) {
  .ArticleTeaser .title {
    margin-bottom: 0;
  }
}

/* 52: /app/components/Modules/Cards/Cards.scss */
/* 53: /app/components/Modules/ContactForm/ContactForm.scss */
.ContactForm .image {
  border-radius: 16px 0 0 16px;
  overflow: hidden;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.ContactForm .image img {
  height: auto;
  object-fit: cover;
  width: 100%;
}
.ContactForm .content {
  background: #ffffff;
  border-radius: 0 16px 16px 0;
  overflow: hidden;
}
.ContactForm iframe {
  width: 100%;
  min-height: 300px;
}

/* 54: /app/components/Modules/CourseLevelList/CourseLevelList.scss */
/* 55: /app/components/Modules/CourseTypeList/CourseTypeList.scss */
.CourseTypeList {
  position: relative;
  z-index: 2;
}
.CourseTypeList .list-checkboxes {
  display: flex;
  align-items: center;
  gap: 10px;
}
.CourseTypeList .filterPadding {
  padding-left: 20px;
}
.CourseTypeList .horizontal-gap {
  row-gap: 20px;
}

.PageHeadMain + .CourseTypeList {
  margin-top: -100px;
}

/* 56: /app/components/Modules/DownloadsList/DownloadsList.scss */
/* 57: /app/components/Modules/EventsList/EventsList.scss */
/* 58: /app/components/Modules/EventTeaser/EventTeaser.scss */
@media screen and (min-width: 992px) {
  .EventTeaser .headline {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.EventTeaser .title {
  display: block;
  margin-bottom: 10px;
}
@media screen and (min-width: 992px) {
  .EventTeaser .title {
    margin-bottom: 0;
  }
}

/* 59: /app/components/Modules/Facts/Facts.scss */
@media screen and (min-width: 992px) {
  .Facts .boxes {
    display: flex;
    gap: 20px;
  }
}
.Facts .box {
  flex: 1;
  text-align: center;
  margin-bottom: 20px;
}
@media screen and (min-width: 992px) {
  .Facts .box {
    margin-bottom: 0;
  }
}
.Facts .icon-holder {
  width: 40px;
  height: 40px;
  background: #ffd2c8;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  color: #ff4b23;
}

/* 60: /app/components/Modules/FactsNumbers/FactsNumbers.scss */
@media screen and (min-width: 992px) {
  .FactsNumbers .boxes {
    display: flex;
    gap: 20px;
  }
}
.FactsNumbers .boxes > div {
  flex: 1;
  text-align: center;
  margin-bottom: 20px;
}
@media screen and (min-width: 992px) {
  .FactsNumbers .boxes > div {
    margin-bottom: 0;
  }
}

/* 61: /app/components/Modules/Form/Form.scss */
.Form iframe {
  width: 100%;
}

/* 62: /app/components/Modules/Gallery/Gallery.scss */
.Gallery {
  position: relative;
  /* Style your items */
}
.Gallery .hideFromStart {
  display: none;
}
.Gallery .slick-prev,
.Gallery .slick-next {
  position: absolute;
  top: 100%;
  transform: translate3d(0, 50%, 0px);
  width: 40px;
  height: 40px;
}
.Gallery .slick-prev:before,
.Gallery .slick-next:before {
  font-family: inherit;
  font-size: 16px;
  color: #000000;
}
@media screen and (min-width: 992px) {
  .Gallery .slick-prev,
.Gallery .slick-next {
    display: none;
  }
}
.Gallery .slick-prev {
  left: 0;
}
.Gallery .slick-next {
  right: 0;
}
.Gallery .masonry {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.Gallery .hideFromStart {
  max-height: 350px;
  overflow: hidden;
}
.Gallery .masonry_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}
.Gallery .masonry_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}
.Gallery .image {
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  width: auto;
}
.Gallery .center {
  text-align: center;
}

/* 63: /app/components/Modules/Highlights/Highlights.scss */
.Highlights .cardHolder > div {
  height: 100%;
}

/* 64: /app/components/Modules/ImageText/Big/Big.scss */
.ImageText.Big {
  background: #ffffff;
}
@media screen and (min-width: 992px) {
  .ImageText.Big.image_right .image-wrap {
    transform: translateX(20px);
  }
}
.ImageText.Big.brand {
  background: #fff5f3;
}
.ImageText.Big .image-wrap {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 200px;
}
@media screen and (min-width: 992px) {
  .ImageText.Big .image-wrap {
    height: auto;
    margin-right: 0;
  }
}
.ImageText.Big .text-wrap {
  position: relative;
  min-height: 120px;
  display: flex;
  align-items: center;
}
.ImageText.Big .image {
  margin: 0 -20px;
}
@media screen and (min-width: 992px) {
  .ImageText.Big .image {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
}
.ImageText.Big .image > div {
  height: 100%;
  width: 100%;
}
.ImageText.Big .image img {
  height: auto;
  object-fit: cover;
  width: 100%;
}
/* 65: /app/components/Modules/ImageText/Fit/Fit.scss */
.ImageText.Fit {
  background: #ffffff;
}
.ImageText.Fit.brand {
  background: #fff5f3;
}
.ImageText.Fit .image-wrap {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 200px;
}
@media screen and (min-width: 992px) {
  .ImageText.Fit .image-wrap {
    height: auto;
    margin-right: 0;
  }
}
.ImageText.Fit .text-wrap {
  position: relative;
  min-height: 120px;
  display: flex;
  align-items: center;
}
.ImageText.Fit .image {
  margin: 0 -20px;
}
.ImageText.Fit .image img {
  height: auto;
  width: 100%;
}

/* 66: /app/components/Modules/ImageText/ImageText.scss */
/* 67: /app/components/Modules/ImageText/Small/Small.scss */
.ImageText.Small {
  background: #ffffff;
}
.ImageText.Small.brand {
  background: #fff5f3;
}
.ImageText.Small .image-wrap {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  border-radius: 16px;
  overflow: hidden;
  height: 200px;
}
@media screen and (min-width: 992px) {
  .ImageText.Small .image-wrap {
    height: auto;
    margin-right: 0;
  }
}
.ImageText.Small .text-wrap {
  position: relative;
  min-height: 120px;
  display: flex;
  align-items: center;
}
.ImageText.Small .image {
  margin: 0 -20px;
  overflow: hidden;
}
@media screen and (min-width: 992px) {
  .ImageText.Small .image {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
}
.ImageText.Small .image > div {
  height: 100%;
  width: 100%;
}
.ImageText.Small .image img {
  height: auto;
  object-fit: cover;
  width: 100%;
}
/* 68: /app/components/Modules/JobsList/JobsList.scss */
/* 69: /app/components/Modules/Map/Map.scss */
/* 70: /app/components/Modules/Newsletter/Newsletter.scss */
.Newsletter {
  position: relative;
}
.Newsletter .content {
  position: relative;
  z-index: 2;
  text-align: center;
}
@media screen and (min-width: 992px) {
  .Newsletter .content {
    text-align: left;
  }
}
.Newsletter .image {
  z-index: 1;
  overflow: hidden;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.Newsletter .image:after {
  content: " ";
  z-index: 2;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}
.Newsletter .image .Picture {
  display: flex;
  height: 100%;
  width: 100%;
}
.Newsletter .image img {
  height: auto;
  object-fit: cover;
  width: 100%;
}

/* 71: /app/components/Modules/NewsList/NewsList.scss */
.NewsList {
  position: relative;
  z-index: 2;
}

.PageHeadMain + .NewsList {
  margin-top: -100px;
}

/* 72: /app/components/Modules/PageHeadArticle/PageHeadArticle.scss */
.PageHeadArticle {
  margin-top: 72px;
}

/* 73: /app/components/Modules/PageHeadCourseType/PageHeadCourseType.scss */
.PageHeadCourseType {
  margin-top: 72px;
}

/* 74: /app/components/Modules/PageHeadEvent/PageHeadEvent.scss */
.PageHeadEvent {
  margin-top: 72px;
}
.PageHeadEvent .row {
  margin-bottom: 6px;
  display: flex;
}
.PageHeadEvent .label,
.PageHeadEvent .value {
  display: inline-block;
}
.PageHeadEvent .label {
  width: 120px;
}

/* 75: /app/components/Modules/PageHeadHome/PageHeadHome.scss */
.PageHeadHome {
  position: relative;
  height: calc(90vh - 72px);
}
.PageHeadHome .sizer {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding-bottom: 10px;
}
@media screen and (min-width: 992px) {
  .PageHeadHome .sizer {
    align-items: center;
    padding: 0;
  }
}
.PageHeadHome .content {
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 0 10px;
}
@media screen and (min-width: 992px) {
  .PageHeadHome .content {
    text-align: left;
    padding: 0;
  }
}
.PageHeadHome .media {
  z-index: 1;
  overflow: hidden;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.PageHeadHome .media:after {
  content: " ";
  z-index: 2;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.2) 100%);
}
.PageHeadHome .media .Picture {
  display: flex;
  height: 100%;
  width: 100%;
}
.PageHeadHome .media img {
  height: auto;
  object-fit: cover;
  width: 100%;
}

/* 76: /app/components/Modules/PageHeadMain/PageHeadMain.scss */
.PageHeadMain {
  position: relative;
  background: #fff5f3;
  margin-top: 72px;
}
.PageHeadMain .content {
  position: relative;
  z-index: 2;
  padding: 0 20px;
}
.PageHeadMain .media {
  z-index: 1;
  overflow: hidden;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.PageHeadMain .media:after {
  content: " ";
  z-index: 2;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}
.PageHeadMain .media .Picture {
  display: flex;
  height: 100%;
  width: 100%;
}
.PageHeadMain .media img {
  height: auto;
  object-fit: cover;
  width: 100%;
}

/* 77: /app/components/Modules/PageHeadSub/PageHeadSub.scss */
.PageHeadSub {
  margin-top: 72px;
}
.PageHeadSub .background {
  background: #fff5f3;
}
.PageHeadSub .content {
  padding: 0 20px;
}
.PageHeadSub .description {
  transform: translateY(-8px);
}
.PageHeadSub .badges > span {
  margin-right: 6px;
}
.PageHeadSub .align-bottom {
  align-items: flex-end;
}
.PageHeadSub .backlink {
  display: flex;
  align-items: center;
  opacity: 0.4;
  transition: opacity 300ms ease-in-out;
}
.PageHeadSub .backlink .Icon, .PageHeadSub .backlink .SliderVideos .slick-prev, .SliderVideos .PageHeadSub .backlink .slick-prev,
.PageHeadSub .backlink .SliderVideos .slick-next,
.SliderVideos .PageHeadSub .backlink .slick-next, .PageHeadSub .backlink .SliderQuotes .slick-prev, .SliderQuotes .PageHeadSub .backlink .slick-prev,
.PageHeadSub .backlink .SliderQuotes .slick-next,
.SliderQuotes .PageHeadSub .backlink .slick-next, .PageHeadSub .backlink .SliderNumbers .slick-prev, .SliderNumbers .PageHeadSub .backlink .slick-prev,
.PageHeadSub .backlink .SliderNumbers .slick-next,
.SliderNumbers .PageHeadSub .backlink .slick-next, .PageHeadSub .backlink .SliderImages .slick-prev, .SliderImages .PageHeadSub .backlink .slick-prev,
.PageHeadSub .backlink .SliderImages .slick-next,
.SliderImages .PageHeadSub .backlink .slick-next, .PageHeadSub .backlink .SliderGuests .slick-prev, .SliderGuests .PageHeadSub .backlink .slick-prev,
.PageHeadSub .backlink .SliderGuests .slick-next,
.SliderGuests .PageHeadSub .backlink .slick-next, .PageHeadSub .backlink .Gallery .slick-prev, .Gallery .PageHeadSub .backlink .slick-prev,
.PageHeadSub .backlink .Gallery .slick-next,
.Gallery .PageHeadSub .backlink .slick-next {
  margin-right: 6px;
}
.PageHeadSub .backlink:hover {
  opacity: 1;
}
.PageHeadSub .image-background {
  position: relative;
  padding: 0 20px;
}
.PageHeadSub .image-background-color {
  content: " ";
  z-index: 1;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  bottom: 50%;
  background: #fff5f3;
}
.PageHeadSub .image {
  position: relative;
  z-index: 2;
  border-radius: 16px;
  overflow: hidden;
}

/* 78: /app/components/Modules/PersonsList/PersonsList.scss */
/* 79: /app/components/Modules/Podcast/Podcast.scss */
/* 80: /app/components/Modules/ProductsList/ProductsList.scss */
/* 81: /app/components/Modules/Quote/Quote.scss */
/* 82: /app/components/Modules/SliderGuests/SliderGuests.scss */
.SliderGuests .wrap {
  position: relative;
  margin-bottom: 40px;
}
@media screen and (min-width: 992px) {
  .SliderGuests .wrap {
    margin: 0 -20px;
  }
}
@media screen and (min-width: 992px) {
  .SliderGuests .slick-slide > div {
    height: 100%;
  }
}
@media screen and (min-width: 992px) {
  .SliderGuests .slick-track {
    display: flex !important;
  }
  .SliderGuests .slick-slide {
    height: inherit !important;
  }
}
.SliderGuests .slick-prev,
.SliderGuests .slick-next {
  display: block;
  position: absolute;
  z-index: 10;
  top: 100%;
  width: 40px;
  height: 40px;
}
.SliderGuests .slick-prev:before,
.SliderGuests .slick-next:before {
  font-family: inherit;
  font-size: 16px;
  color: #000000;
}
@media screen and (min-width: 992px) {
  .SliderGuests .slick-prev,
.SliderGuests .slick-next {
    top: -8%;
    transform: translateY(-100%);
  }
}
.SliderGuests .slick-prev {
  left: 10px;
  right: unset;
}
@media screen and (min-width: 992px) {
  .SliderGuests .slick-prev {
    left: unset;
    right: 70px !important;
  }
}
.SliderGuests .slick-next {
  right: 10px;
}
@media screen and (min-width: 992px) {
  .SliderGuests .slick-next {
    left: unset;
    right: 40px !important;
  }
}
.SliderGuests .slide {
  height: 100%;
}
@media screen and (min-width: 992px) {
  .SliderGuests .slide {
    padding-right: 20px;
  }
}
.SliderGuests .slide > .Quote-Partial {
  height: 100%;
}
.SliderGuests .headline-holder {
  position: relative;
}
.SliderGuests .icon {
  font-size: 160px;
  position: absolute;
  right: 0;
  top: -10px;
  color: #ffd2c8;
  transform: translateX(150%);
}

/* 83: /app/components/Modules/SliderImages/SliderImages.scss */
.SliderImages {
  overflow: hidden;
  padding-bottom: 20px !important;
}
.SliderImages .slick-dots {
  display: none !important;
}
@media screen and (min-width: 992px) {
  .SliderImages .slick-dots {
    display: block !important;
  }
}
.SliderImages .image {
  border-radius: 16px;
  overflow: hidden;
}
.SliderImages .slick-prev,
.SliderImages .slick-next {
  width: 40px;
  height: 40px;
  top: unset;
  bottom: 0;
  transform: translateY(100%);
}
@media screen and (min-width: 992px) {
  .SliderImages .slick-prev,
.SliderImages .slick-next {
    top: 50%;
    bottom: unset;
    transform: translateY(-50%);
  }
}
.SliderImages .slick-prev:before,
.SliderImages .slick-next:before {
  font-family: inherit;
  font-size: 16px;
  color: #000000;
}
.SliderImages .slick-prev {
  left: 0;
}
@media screen and (min-width: 992px) {
  .SliderImages .slick-prev {
    left: -40px;
  }
}
.SliderImages .slick-next {
  right: 0;
}
@media screen and (min-width: 992px) {
  .SliderImages .slick-next {
    right: -40px;
  }
}

/* 84: /app/components/Modules/SliderNumbers/SliderNumbers.scss */
.SliderNumbers .wrap {
  position: relative;
  margin-bottom: 40px;
}
@media screen and (min-width: 992px) {
  .SliderNumbers .wrap {
    margin: 0 -20px;
  }
}
@media screen and (min-width: 992px) {
  .SliderNumbers .slick-slide > div {
    height: 100%;
  }
}
.SliderNumbers .slide {
  position: relative;
  padding-right: 0;
  padding-left: 20px;
  padding-bottom: 20px;
}
@media screen and (min-width: 992px) {
  .SliderNumbers .slide {
    padding-right: 40px;
  }
}
.SliderNumbers .slide:before {
  content: " ";
  z-index: 1;
  background: #fff5f3;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  top: 18px;
  right: 0;
  border-radius: 16px;
}
@media screen and (min-width: 992px) {
  .SliderNumbers .slide:before {
    right: 20px;
  }
}
.SliderNumbers .slide .content {
  position: relative;
  z-index: 2;
}
.SliderNumbers .slick-prev,
.SliderNumbers .slick-next {
  display: block;
  position: absolute;
  top: 100%;
  transform: translateY(50%);
  width: 40px;
  height: 40px;
}
.SliderNumbers .slick-prev:before,
.SliderNumbers .slick-next:before {
  font-family: inherit;
  font-size: 16px;
  color: #000000;
}
@media screen and (min-width: 992px) {
  .SliderNumbers .slick-prev,
.SliderNumbers .slick-next {
    top: 0;
    transform: translateY(-100%);
  }
}
.SliderNumbers .slick-prev {
  left: 10px;
  right: unset;
}
@media screen and (min-width: 992px) {
  .SliderNumbers .slick-prev {
    left: unset;
    right: 70px !important;
  }
}
.SliderNumbers .slick-next {
  right: 10px;
}
@media screen and (min-width: 992px) {
  .SliderNumbers .slick-next {
    left: unset;
    right: 40px !important;
  }
}

/* 85: /app/components/Modules/SliderQuotes/SliderQuotes.scss */
.SliderQuotes .wrap {
  position: relative;
  margin-bottom: 40px;
}
@media screen and (min-width: 992px) {
  .SliderQuotes .slick-slide > div {
    height: 100%;
  }
}
@media screen and (min-width: 992px) {
  .SliderQuotes .slick-track {
    display: flex !important;
  }
  .SliderQuotes .slick-slide {
    height: inherit !important;
  }
}
.SliderQuotes .slick-prev,
.SliderQuotes .slick-next {
  display: block;
  position: absolute;
  top: 100%;
  transform: translateY(50%);
  width: 40px;
  height: 40px;
}
.SliderQuotes .slick-prev:before,
.SliderQuotes .slick-next:before {
  font-family: inherit;
  font-size: 16px;
  color: #000000;
}
@media screen and (min-width: 992px) {
  .SliderQuotes .slick-prev,
.SliderQuotes .slick-next {
    top: 0;
    transform: translateY(-100%);
  }
}
.SliderQuotes .slick-prev {
  left: 10px;
  right: unset;
}
@media screen and (min-width: 992px) {
  .SliderQuotes .slick-prev {
    left: unset;
    right: 70px !important;
  }
}
.SliderQuotes .slick-next {
  right: 10px;
}
@media screen and (min-width: 992px) {
  .SliderQuotes .slick-next {
    left: unset;
    right: 40px !important;
  }
}
.SliderQuotes .slide {
  height: 100%;
}
@media screen and (min-width: 992px) {
  .SliderQuotes .slide {
    padding-right: 20px;
  }
}
.SliderQuotes .slide > .Quote-Partial {
  height: 100%;
}
.SliderQuotes .headline-holder {
  position: relative;
}
.SliderQuotes .icon {
  font-size: 160px;
  position: absolute;
  right: 0;
  top: -10px;
  color: #ffd2c8;
  transform: translateX(150%);
}

/* 86: /app/components/Modules/SliderVideos/SliderVideos.scss */
.SliderVideos {
  overflow: hidden;
  padding-bottom: 20px !important;
}
.SliderVideos .slick-dots {
  display: none !important;
}
@media screen and (min-width: 992px) {
  .SliderVideos .slick-dots {
    display: block !important;
  }
}
.SliderVideos .slick-prev,
.SliderVideos .slick-next {
  width: 40px;
  height: 40px;
  top: unset;
  bottom: 0;
  transform: translateY(100%);
}
@media screen and (min-width: 992px) {
  .SliderVideos .slick-prev,
.SliderVideos .slick-next {
    top: 50%;
    bottom: unset;
    transform: translateY(-50%);
  }
}
.SliderVideos .slick-prev:before,
.SliderVideos .slick-next:before {
  font-family: inherit;
  font-size: 16px;
  color: #000000;
}
.SliderVideos .slick-prev {
  left: 0;
}
@media screen and (min-width: 992px) {
  .SliderVideos .slick-prev {
    left: -40px;
  }
}
.SliderVideos .slick-next {
  right: 0;
}
@media screen and (min-width: 992px) {
  .SliderVideos .slick-next {
    right: -40px;
  }
}

/* 87: /app/components/Modules/Teaser/Teaser.scss */
.Teaser .inner {
  background: #ffd2c8;
  border-radius: 16px;
  padding: 10px;
}
@media screen and (min-width: 992px) {
  .Teaser .inner {
    padding: 20px 0;
  }
}
.Teaser .image {
  padding-top: 20px;
  padding-bottom: 20px;
}
.Teaser .content {
  display: flex;
  align-items: center;
}

/* 88: /app/components/Modules/TextTeaser/TextTeaser.scss */
.TextTeaser {
  position: relative;
  overflow: hidden;
}
.TextTeaser .inner {
  position: relative;
}
.TextTeaser .content {
  position: relative;
  text-align: center;
  z-index: 2;
}
@media screen and (min-width: 992px) {
  .TextTeaser .content.hasImage {
    min-height: 350px;
  }
}
.TextTeaser .image-grid {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
@media screen and (min-width: 992px) {
  .TextTeaser .image {
    position: absolute;
    left: 0;
    z-index: 1;
  }
}
.TextTeaser .headline img {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.TextTeaser .image-1 {
  display: none;
}
@media screen and (min-width: 992px) {
  .TextTeaser .image-1 {
    display: block;
    top: 60px;
    max-height: 50%;
  }
}
.TextTeaser .image-2 {
  display: none;
}
@media screen and (min-width: 992px) {
  .TextTeaser .image-2 {
    display: block;
    bottom: 60px;
    max-height: 50%;
    left: 0;
  }
}
.TextTeaser .image-3 {
  width: 50%;
  height: auto;
  margin: 10px auto;
}
@media screen and (min-width: 992px) {
  .TextTeaser .image-3 {
    width: auto;
    height: auto;
    top: 60px;
    max-height: 80%;
    margin: 0;
  }
}
.TextTeaser .image-3 svg {
  width: 100%;
}

/* 89: /app/components/Modules/Todo/Todo.scss */
/* 90: /app/components/Modules/Unknown/Unknown.scss */
.Unknown {
  background: red;
}

/* 91: /app/components/Modules/Wysiwyg/Wysiwyg.scss */
/* 92: /app/components/Pages/Error404/Error404.scss */
.Error404 {
  position: relative;
  min-height: 50vh;
  margin-top: 72px;
}

/* 93: /app/components/Pages/ErrorGeneral/ErrorGeneral.scss */
/* 94: /app/components/Partials/Card/Card.scss */
.Card {
  background: white;
  border-radius: 16px;
  overflow: hidden;
}
.Card.highlight {
  background: #ffd2c8;
}
.Card.spacerBottom {
  margin-bottom: 20px;
}
.Card.fillHeight {
  height: 100%;
}
.Card .content {
  padding: 12px;
}
.Card .badges span {
  margin-right: 6px;
}
.Card.plain .image {
  border-radius: 14px;
  overflow: hidden;
}
.Card.plain .content {
  padding: 12px 0;
}
.Card.border {
  border: 2px solid #ffd2c8;
}
.Card.border:hover {
  border-color: #ff4b23;
}
.Card.border .content {
  padding-left: 12px;
  padding-right: 12px;
}
.Card:hover .image:has(.teaser)::before {
  opacity: 1;
}
.Card:hover .teaser {
  transform: translateY(0);
}
.Card .image {
  position: relative;
  overflow: hidden;
}
.Card .image:has(.teaser)::before {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: block;
  transition: all 0.3s;
  opacity: 0;
}
.Card .teaser {
  transition: all 0.3s;
  position: absolute;
  inset: 0;
  padding: 10px;
  display: flex;
  transform: translateY(100%);
  align-items: flex-end;
}
.Card.withHover {
  cursor: pointer;
}

/* 95: /app/components/Partials/Course/Course.scss */
.Course {
  display: flex;
  gap: 20px;
  flex-direction: column;
}
@media screen and (min-width: 992px) {
  .Course {
    flex-direction: row;
  }
}
.Course > div {
  flex: 1;
}
.Course .dates .date {
  height: 40px;
  border: 1px solid #ff4b23;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  cursor: pointer;
}
.Course .dates .date.selected {
  background: #ffd2c8;
  color: #ff4b23;
}
.Course .showMore {
  text-align: center;
}

/* 96: /app/components/Partials/Guest/Guest.scss */
.Guest-Partial {
  position: relative;
  padding: 0 10px;
}
@media screen and (min-width: 992px) {
  .Guest-Partial {
    padding: 0;
  }
}
.Guest-Partial:before {
  content: " ";
  z-index: 1;
  background: #fff5f3;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  top: 75px;
  border-radius: 16px;
}
.Guest-Partial .content {
  position: relative;
  z-index: 2;
}
.Guest-Partial .ellipsis {
  text-decoration: underline;
  display: block;
}
.Guest-Partial .icon {
  font-size: 160px;
  position: absolute;
  right: 0;
  top: 0;
  color: #ffd2c8;
}
.Guest-Partial .image {
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 50%;
}
.Guest-Partial.small:before {
  top: 40px;
}
.Guest-Partial.small .image {
  width: 80px;
  height: 80px;
}

/* 97: /app/components/Partials/ListRow/ListRow.scss */
.ListRow {
  overflow: hidden;
  border: 1px solid #e4e4e4;
  border-radius: 16px;
  background: white;
  margin-bottom: 6px;
}
@media screen and (min-width: 992px) {
  .ListRow {
    display: flex;
    align-items: center;
  }
}
.ListRow .image {
  width: 100%;
}
@media screen and (min-width: 992px) {
  .ListRow .image {
    max-width: 260px;
    margin-right: 10px;
  }
}
.ListRow .content {
  flex-grow: 1;
  padding: 10px;
}
.ListRow .buttons {
  display: flex;
  align-items: center;
  padding: 10px;
  padding-top: 0;
}
@media screen and (min-width: 992px) {
  .ListRow .buttons {
    padding-right: 20px;
  }
}
.ListRow .buttons .Button {
  flex-grow: 1;
}

/* 98: /app/components/Partials/Quote/Quote.scss */
.Quote-Partial {
  position: relative;
  padding: 0 10px;
}
@media screen and (min-width: 992px) {
  .Quote-Partial {
    padding: 0;
  }
}
.Quote-Partial:before {
  content: " ";
  z-index: 1;
  background: #fff5f3;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  top: 75px;
  border-radius: 16px;
}
.Quote-Partial .content {
  position: relative;
  z-index: 2;
}
.Quote-Partial .ellipsis {
  text-decoration: underline;
  display: block;
}
.Quote-Partial .icon {
  font-size: 160px;
  position: absolute;
  right: 0;
  top: 0;
  color: #ffd2c8;
}
.Quote-Partial .image {
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 50%;
}
.Quote-Partial.small:before {
  top: 40px;
}
.Quote-Partial.small .image {
  width: 80px;
  height: 80px;
}

/* 99: /app/components/Partials/SideCard/SideCard.scss */
.SideCard {
  border: 1px solid #e4e4e4;
  border-radius: 16px;
  overflow: hidden;
}

/* 100: /app/components/Partials/VideoFrame/VideoFrame.scss */
.VideoFrame {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.VideoFrame.radius {
  border-radius: 16px;
}
.VideoFrame .frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* 101: /app/components/Partials/VideoFramePlyr/Plyr.scss */
@keyframes plyr-progress {
  to {
    background-position: 25px 0;
    background-position: 25px 0;
  }
}
@keyframes plyr-popup {
  0% {
    opacity: 0.5;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes plyr-fade-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.plyr {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  align-items: center;
  direction: ltr;
  display: flex;
  flex-direction: column;
  font-family: inherit;
  font-family: inherit;
  font-variant-numeric: tabular-nums;
  font-weight: 400;
  font-weight: 400;
  line-height: 1.7;
  line-height: 1.7;
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  transition: box-shadow 0.3s ease;
  z-index: 0;
}

.plyr audio,
.plyr iframe,
.plyr video {
  display: block;
  height: 100%;
  width: 100%;
}

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto;
}

.plyr:focus {
  outline: 0;
}

.plyr--full-ui {
  box-sizing: border-box;
}

.plyr--full-ui *,
.plyr--full-ui :after,
.plyr--full-ui :before {
  box-sizing: inherit;
}

.plyr--full-ui a,
.plyr--full-ui button,
.plyr--full-ui input,
.plyr--full-ui label {
  touch-action: manipulation;
}

.plyr__badge {
  background: #4a5464;
  background: #4a5464;
  border-radius: 2px;
  border-radius: 2px;
  color: #fff;
  color: #fff;
  font-size: 9px;
  font-size: 9px;
  line-height: 1;
  padding: 3px 4px;
}

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none;
}

.plyr__captions {
  animation: plyr-fade-in 0.3s ease;
  bottom: 0;
  display: none;
  font-size: 13px;
  font-size: 13px;
  left: 0;
  padding: 10px;
  padding: 10px;
  position: absolute;
  text-align: center;
  transition: transform 0.4s ease-in-out;
  width: 100%;
}

.plyr__captions span:empty {
  display: none;
}

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 15px;
    font-size: 15px;
    padding: 20px;
    padding: 20px;
  }
}
@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px;
    font-size: 18px;
  }
}
.plyr--captions-active .plyr__captions {
  display: block;
}

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  transform: translateY(-40px);
  transform: translateY(-40px);
}

.plyr__caption {
  background: rgba(0, 0, 0, 0.8);
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  color: #fff;
  color: #fff;
  line-height: 185%;
  padding: 0.2em 0.5em;
  white-space: pre-wrap;
}

.plyr__caption div {
  display: inline;
}

.plyr__control {
  background: transparent;
  border: 0;
  border-radius: 3px;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: 7px;
  padding: 7px;
  position: relative;
  transition: all 0.3s ease;
}

.plyr__control svg {
  fill: currentColor;
  display: block;
  height: 18px;
  height: 18px;
  pointer-events: none;
  width: 18px;
  width: 18px;
}

.plyr__control:focus {
  outline: 0;
}

.plyr__control.plyr__tab-focus {
  outline: 3px dotted #ff4b22;
  outline: #ff4b22 dotted 3px;
  outline-offset: 2px;
}

a.plyr__control {
  text-decoration: none;
}

.plyr__control.plyr__control--pressed .icon--not-pressed,
.plyr__control.plyr__control--pressed .label--not-pressed,
.plyr__control:not(.plyr__control--pressed) .icon--pressed,
.plyr__control:not(.plyr__control--pressed) .label--pressed,
a.plyr__control:after,
a.plyr__control:before {
  display: none;
}

.plyr--full-ui ::-webkit-media-controls {
  display: none;
}

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center;
}

.plyr__controls .plyr__progress__container {
  flex: 1;
  min-width: 0;
}

.plyr__controls .plyr__controls__item {
  margin-left: 2.5px;
  margin-left: 2.5px;
}

.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto;
}

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: 2.5px;
  padding-left: 2.5px;
}

.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 5px;
  padding: 0 5px;
}

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child,
.plyr__controls .plyr__controls__item.plyr__time + .plyr__time,
.plyr__controls .plyr__controls__item.plyr__time:first-child {
  padding-left: 0;
}

.plyr [data-plyr=airplay],
.plyr [data-plyr=captions],
.plyr [data-plyr=fullscreen],
.plyr [data-plyr=pip],
.plyr__controls:empty {
  display: none;
}

.plyr--airplay-supported [data-plyr=airplay],
.plyr--captions-enabled [data-plyr=captions],
.plyr--fullscreen-enabled [data-plyr=fullscreen],
.plyr--pip-supported [data-plyr=pip] {
  display: inline-block;
}

.plyr__menu {
  display: flex;
  position: relative;
}

.plyr__menu .plyr__control svg {
  transition: transform 0.3s ease;
}

.plyr__menu .plyr__control[aria-expanded=true] svg {
  transform: rotate(90deg);
}

.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
  display: none;
}

.plyr__menu__container {
  animation: plyr-popup 0.2s ease;
  background: hsla(0deg, 0%, 100%, 0.9);
  background: hsla(0deg, 0%, 100%, 0.9);
  border-radius: 4px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4a5464;
  color: #4a5464;
  font-size: 15px;
  font-size: 15px;
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3;
}

.plyr__menu__container > div {
  overflow: hidden;
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}

.plyr__menu__container:after {
  border: 4px solid transparent;
  border-top-color: hsla(0deg, 0%, 100%, 0.9);
  border: 4px solid transparent;
  border-top-color: hsla(0deg, 0%, 100%, 0.9);
  content: "";
  height: 0;
  position: absolute;
  right: 14px;
  right: 14px;
  top: 100%;
  width: 0;
}

.plyr__menu__container [role=menu] {
  padding: 7px;
  padding: 7px;
}

.plyr__menu__container [role=menuitem],
.plyr__menu__container [role=menuitemradio] {
  margin-top: 2px;
}

.plyr__menu__container [role=menuitem]:first-child,
.plyr__menu__container [role=menuitemradio]:first-child {
  margin-top: 0;
}

.plyr__menu__container .plyr__control {
  align-items: center;
  color: #4a5464;
  color: #4a5464;
  display: flex;
  font-size: 13px;
  font-size: 13px;
  padding: 4.66667px 10.5px;
  padding: 4.6666666667px 10.5px;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
}

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%;
}

.plyr__menu__container .plyr__control:after {
  border: 4px solid transparent;
  border: 4px solid transparent;
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px;
  padding-right: 28px;
}

.plyr__menu__container .plyr__control--forward:after {
  border-left-color: #728197;
  border-left-color: #728197;
  right: 6.5px;
  right: 6.5px;
}

.plyr__menu__container .plyr__control--forward.plyr__tab-focus:after,
.plyr__menu__container .plyr__control--forward:hover:after {
  border-left-color: currentColor;
}

.plyr__menu__container .plyr__control--back {
  font-weight: 400;
  font-weight: 400;
  margin: 7px;
  margin: 7px;
  margin-bottom: 3.5px;
  margin-bottom: 3.5px;
  padding-left: 28px;
  padding-left: 28px;
  position: relative;
  width: calc(100% - 14px);
  width: calc(100% - 14px);
}

.plyr__menu__container .plyr__control--back:after {
  border-right-color: #728197;
  border-right-color: #728197;
  left: 6.5px;
  left: 6.5px;
}

.plyr__menu__container .plyr__control--back:before {
  background: #dcdfe5;
  background: #dcdfe5;
  box-shadow: 0 1px 0 #fff;
  box-shadow: 0 1px 0 #fff;
  content: "";
  height: 1px;
  left: 0;
  margin-top: 3.5px;
  margin-top: 3.5px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%;
}

.plyr__menu__container .plyr__control--back.plyr__tab-focus:after,
.plyr__menu__container .plyr__control--back:hover:after {
  border-right-color: currentColor;
}

.plyr__menu__container .plyr__control[role=menuitemradio] {
  padding-left: 7px;
  padding-left: 7px;
}

.plyr__menu__container .plyr__control[role=menuitemradio]:after,
.plyr__menu__container .plyr__control[role=menuitemradio]:before {
  border-radius: 100%;
}

.plyr__menu__container .plyr__control[role=menuitemradio]:before {
  background: rgba(0, 0, 0, 0.1);
  content: "";
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  margin-right: 10px;
  transition: all 0.3s ease;
  width: 16px;
}

.plyr__menu__container .plyr__control[role=menuitemradio]:after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  transform: translateY(-50%) scale(0);
  transition: transform 0.3s ease, opacity 0.3s ease;
  width: 6px;
}

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]:before {
  background: #ff4b22;
  background: #ff4b22;
}

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]:after {
  opacity: 1;
  transform: translateY(-50%) scale(1);
}

.plyr__menu__container .plyr__control[role=menuitemradio].plyr__tab-focus:before,
.plyr__menu__container .plyr__control[role=menuitemradio]:hover:before {
  background: rgba(35, 40, 47, 0.1);
}

.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: -5px;
  overflow: hidden;
  padding-left: 24.5px;
  pointer-events: none;
}

.plyr--full-ui input[type=range] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: 26px;
  border-radius: 26px;
  color: #ff4b22;
  color: #ff4b22;
  display: block;
  height: 19px;
  height: 19px;
  margin: 0;
  min-width: 0;
  padding: 0;
  transition: box-shadow 0.3s ease;
  width: 100%;
}

.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
  background: transparent;
  background-image: linear-gradient(90deg, currentColor 0, transparent 0);
  background-image: linear-gradient(to right, currentColor 0, transparent 0);
  border: 0;
  border-radius: 2.5px;
  border-radius: 2.5px;
  height: 5px;
  height: 5px;
  -webkit-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  -webkit-user-select: none;
  user-select: none;
}

.plyr--full-ui input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: #fff;
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2);
  height: 13px;
  height: 13px;
  margin-top: -4px;
  margin-top: -4px;
  position: relative;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 13px;
  width: 13px;
}

.plyr--full-ui input[type=range]::-moz-range-track {
  background: transparent;
  border: 0;
  border-radius: 2.5px;
  border-radius: 2.5px;
  height: 5px;
  height: 5px;
  -moz-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  user-select: none;
}

.plyr--full-ui input[type=range]::-moz-range-thumb {
  background: #fff;
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2);
  height: 13px;
  height: 13px;
  position: relative;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 13px;
  width: 13px;
}

.plyr--full-ui input[type=range]::-moz-range-progress {
  background: currentColor;
  border-radius: 2.5px;
  border-radius: 2.5px;
  height: 5px;
  height: 5px;
}

.plyr--full-ui input[type=range]::-ms-track {
  color: transparent;
}

.plyr--full-ui input[type=range]::-ms-fill-upper,
.plyr--full-ui input[type=range]::-ms-track {
  background: transparent;
  border: 0;
  border-radius: 2.5px;
  border-radius: 2.5px;
  height: 5px;
  height: 5px;
  -ms-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  user-select: none;
}

.plyr--full-ui input[type=range]::-ms-fill-lower {
  background: transparent;
  background: currentColor;
  border: 0;
  border-radius: 2.5px;
  border-radius: 2.5px;
  height: 5px;
  height: 5px;
  -ms-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  user-select: none;
}

.plyr--full-ui input[type=range]::-ms-thumb {
  background: #fff;
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2);
  height: 13px;
  height: 13px;
  margin-top: 0;
  position: relative;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 13px;
  width: 13px;
}

.plyr--full-ui input[type=range]::-ms-tooltip {
  display: none;
}

.plyr--full-ui input[type=range]::-moz-focus-outer {
  border: 0;
}

.plyr--full-ui input[type=range]:focus {
  outline: 0;
}

.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
  outline: 3px dotted #ff4b22;
  outline: #ff4b22 dotted 3px;
  outline-offset: 2px;
}

.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
  outline: 3px dotted #ff4b22;
  outline: #ff4b22 dotted 3px;
  outline-offset: 2px;
}

.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
  outline: 3px dotted #ff4b22;
  outline: #ff4b22 dotted 3px;
  outline-offset: 2px;
}

.plyr__poster {
  background-color: #000;
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.2s ease;
  width: 100%;
  z-index: 1;
}

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1;
}

.plyr--youtube.plyr--paused.plyr__poster-enabled:not(.plyr--stopped) .plyr__poster {
  display: none;
}

.plyr__time {
  font-size: 13px;
  font-size: 13px;
}

.plyr__time + .plyr__time:before {
  content: "⁄";
  margin-right: 10px;
  margin-right: 10px;
}

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none;
  }
}
.plyr__tooltip {
  background: hsla(0deg, 0%, 100%, 0.9);
  background: hsla(0deg, 0%, 100%, 0.9);
  border-radius: 5px;
  border-radius: 5px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4a5464;
  color: #4a5464;
  font-size: 13px;
  font-size: 13px;
  font-weight: 400;
  font-weight: 400;
  left: 50%;
  line-height: 1.3;
  margin-bottom: 10px;
  margin-bottom: 10px;
  opacity: 0;
  padding: 5px 7.5px;
  padding: 5px 7.5px;
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, 10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform 0.2s ease 0.1s, opacity 0.2s ease 0.1s;
  white-space: nowrap;
  z-index: 2;
}

.plyr__tooltip:before {
  border-left: 4px solid transparent;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid hsla(0deg, 0%, 100%, 0.9);
  border-top: 4px solid hsla(0deg, 0%, 100%, 0.9);
  bottom: -4px;
  bottom: -4px;
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 0;
  z-index: 2;
}

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip,
.plyr .plyr__control:hover .plyr__tooltip,
.plyr__tooltip--visible {
  opacity: 1;
  transform: translate(-50%) scale(1);
}

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3;
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip,
.plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  transform: translateY(10px) scale(0.8);
  transform-origin: 0 100%;
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip:before,
.plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip:before {
  left: 16px;
  left: 16px;
}

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  transform: translateY(10px) scale(0.8);
  transform-origin: 100% 100%;
}

.plyr__controls > .plyr__control:last-child .plyr__tooltip:before {
  left: auto;
  right: 16px;
  right: 16px;
  transform: translateX(50%);
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible,
.plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible,
.plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip,
.plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip,
.plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip,
.plyr__controls > .plyr__control:first-child:hover .plyr__tooltip,
.plyr__controls > .plyr__control:last-child .plyr__tooltip--visible,
.plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip,
.plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  transform: translate(0) scale(1);
}

.plyr__progress {
  left: 6.5px;
  left: 6.5px;
  margin-right: 13px;
  margin-right: 13px;
  position: relative;
}

.plyr__progress input[type=range],
.plyr__progress__buffer {
  margin-left: -6.5px;
  margin-left: -6.5px;
  margin-right: -6.5px;
  margin-right: -6.5px;
  width: calc(100% + 13px);
  width: calc(100% + 13px);
}

.plyr__progress input[type=range] {
  position: relative;
  z-index: 2;
}

.plyr__progress .plyr__tooltip {
  left: 0;
  max-width: 120px;
  overflow-wrap: break-word;
  white-space: normal;
}

.plyr__progress__buffer {
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  border-radius: 100px;
  height: 5px;
  height: 5px;
  left: 0;
  margin-top: -2.5px;
  margin-top: -2.5px;
  padding: 0;
  position: absolute;
  top: 50%;
}

.plyr__progress__buffer::-webkit-progress-bar {
  background: transparent;
}

.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  min-width: 5px;
  -webkit-transition: width 0.2s ease;
  transition: width 0.2s ease;
}

.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  min-width: 5px;
  -moz-transition: width 0.2s ease;
  transition: width 0.2s ease;
}

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  -ms-transition: width 0.2s ease;
  transition: width 0.2s ease;
}

.plyr--loading .plyr__progress__buffer {
  animation: plyr-progress 1s linear infinite;
  background-image: linear-gradient(-45deg, rgba(35, 40, 47, 0.6) 25%, transparent 0, transparent 50%, rgba(35, 40, 47, 0.6) 0, rgba(35, 40, 47, 0.6) 75%, transparent 0, transparent);
  background-image: linear-gradient(-45deg, rgba(35, 40, 47, 0.6) 25%, transparent 25%, transparent 50%, rgba(35, 40, 47, 0.6) 50%, rgba(35, 40, 47, 0.6) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  background-size: 25px 25px;
  color: transparent;
}

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: hsla(0deg, 0%, 100%, 0.25);
  background-color: hsla(0deg, 0%, 100%, 0.25);
}

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(193, 200, 209, 0.6);
  background-color: rgba(193, 200, 209, 0.6);
}

.plyr__progress__marker {
  background-color: #fff;
  background-color: #fff;
  border-radius: 1px;
  height: 5px;
  height: 5px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 3px;
  width: 3px;
  z-index: 3;
}

.plyr__volume {
  align-items: center;
  display: flex;
  max-width: 110px;
  min-width: 80px;
  position: relative;
  width: 20%;
}

.plyr__volume input[type=range] {
  margin-left: 5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-right: 5px;
  position: relative;
  z-index: 2;
}

.plyr--is-ios .plyr__volume {
  min-width: 0;
  width: auto;
}

.plyr--audio {
  display: block;
}

.plyr--audio .plyr__controls {
  background: #fff;
  background: #fff;
  border-radius: inherit;
  color: #4a5464;
  color: #4a5464;
  padding: 10px;
  padding: 10px;
}

.plyr--audio .plyr__control.plyr__tab-focus,
.plyr--audio .plyr__control:hover,
.plyr--audio .plyr__control[aria-expanded=true] {
  background: #ff4b22;
  background: #ff4b22;
  color: #fff;
  color: #fff;
}

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(193, 200, 209, 0.6);
  background-color: rgba(193, 200, 209, 0.6);
}

.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
  background-color: rgba(193, 200, 209, 0.6);
  background-color: rgba(193, 200, 209, 0.6);
}

.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
  background-color: rgba(193, 200, 209, 0.6);
  background-color: rgba(193, 200, 209, 0.6);
}

.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2), 0 0 0 3px rgba(35, 40, 47, 0.1);
  box-shadow: 0 0 0 3px rgba(35, 40, 47, 0.1);
}

.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2), 0 0 0 3px rgba(35, 40, 47, 0.1);
  box-shadow: 0 0 0 3px rgba(35, 40, 47, 0.1);
}

.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2), 0 0 0 3px rgba(35, 40, 47, 0.1);
  box-shadow: 0 0 0 3px rgba(35, 40, 47, 0.1);
}

.plyr--audio .plyr__progress__buffer {
  color: rgba(193, 200, 209, 0.6);
  color: rgba(193, 200, 209, 0.6);
}

.plyr--video {
  background: #000;
  background: #000;
  overflow: hidden;
}

.plyr--video.plyr--menu-open {
  overflow: visible;
}

.plyr__video-wrapper {
  background: #000;
  background: #000;
  height: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.plyr__video-embed,
.plyr__video-wrapper--fixed-ratio {
  aspect-ratio: 16/9;
}

@supports not (aspect-ratio: 16/9) {
  .plyr__video-embed,
.plyr__video-wrapper--fixed-ratio {
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
  }
}
.plyr__video-embed iframe,
.plyr__video-wrapper--fixed-ratio video {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  transform: translateY(-38.28125%);
}

.plyr--video .plyr__controls {
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.75));
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.75));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  color: #fff;
  left: 0;
  padding: 5px;
  padding: 5px;
  padding-top: 20px;
  padding-top: 20px;
  position: absolute;
  right: 0;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
  z-index: 3;
}

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 10px;
    padding: 10px;
    padding-top: 35px;
    padding-top: 35px;
  }
}
.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  transform: translateY(100%);
}

.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded=true] {
  background: #ff4b22;
  background: #ff4b22;
  color: #fff;
  color: #fff;
}

.plyr__control--overlaid {
  background: #ff4b22;
  background: #ff4b22;
  border: 0;
  border-radius: 100%;
  color: #fff;
  color: #fff;
  display: none;
  left: 50%;
  opacity: 0.9;
  padding: 15px;
  padding: 15px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s;
  z-index: 2;
}

.plyr__control--overlaid svg {
  left: 2px;
  position: relative;
}

.plyr__control--overlaid:focus,
.plyr__control--overlaid:hover {
  opacity: 1;
}

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden;
}

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block;
}

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
  background-color: hsla(0deg, 0%, 100%, 0.25);
  background-color: hsla(0deg, 0%, 100%, 0.25);
}

.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
  background-color: hsla(0deg, 0%, 100%, 0.25);
  background-color: hsla(0deg, 0%, 100%, 0.25);
}

.plyr--full-ui.plyr--video input[type=range]::-ms-track {
  background-color: hsla(0deg, 0%, 100%, 0.25);
  background-color: hsla(0deg, 0%, 100%, 0.25);
}

.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2), 0 0 0 3px hsla(0deg, 0%, 100%, 0.5);
  box-shadow: 0 0 0 3px hsla(0deg, 0%, 100%, 0.5);
}

.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2), 0 0 0 3px hsla(0deg, 0%, 100%, 0.5);
  box-shadow: 0 0 0 3px hsla(0deg, 0%, 100%, 0.5);
}

.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2), 0 0 0 3px hsla(0deg, 0%, 100%, 0.5);
  box-shadow: 0 0 0 3px hsla(0deg, 0%, 100%, 0.5);
}

.plyr--video .plyr__progress__buffer {
  color: hsla(0deg, 0%, 100%, 0.25);
  color: hsla(0deg, 0%, 100%, 0.25);
}

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:fullscreen video {
  height: 100%;
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:fullscreen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:fullscreen .plyr__captions {
    font-size: 21px;
    font-size: 21px;
  }
}
.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  margin: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10000000;
}

.plyr--fullscreen-fallback video {
  height: 100%;
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px;
    font-size: 21px;
  }
}
.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.plyr__ads > div,
.plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%;
}

.plyr__ads:after {
  background: #23282f;
  border-radius: 2px;
  bottom: 10px;
  bottom: 10px;
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  right: 10px;
  z-index: 3;
}

.plyr__ads:empty:after {
  display: none;
}

.plyr__cues {
  background: currentColor;
  display: block;
  height: 5px;
  height: 5px;
  left: 0;
  opacity: 0.8;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  z-index: 3;
}

.plyr__preview-thumb {
  background-color: hsla(0deg, 0%, 100%, 0.9);
  background-color: hsla(0deg, 0%, 100%, 0.9);
  border-radius: 5px;
  border-radius: 5px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  margin-bottom: 10px;
  opacity: 0;
  padding: 3px;
  pointer-events: none;
  position: absolute;
  transform: translateY(10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform 0.2s ease 0.1s, opacity 0.2s ease 0.1s;
  z-index: 2;
}

.plyr__preview-thumb--is-shown {
  opacity: 1;
  transform: translate(0) scale(1);
}

.plyr__preview-thumb:before {
  border-left: 4px solid transparent;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid hsla(0deg, 0%, 100%, 0.9);
  border-top: 4px solid hsla(0deg, 0%, 100%, 0.9);
  bottom: -4px;
  bottom: -4px;
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 0;
  z-index: 2;
}

.plyr__preview-thumb__image-container {
  background: #c1c8d1;
  border-radius: 4px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.plyr__preview-thumb__image-container img,
.plyr__preview-thumb__image-container:after {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.plyr__preview-thumb__image-container:after {
  border-radius: inherit;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
  content: "";
  pointer-events: none;
}

.plyr__preview-thumb__image-container img {
  max-height: none;
  max-width: none;
}

.plyr__preview-thumb__time-container {
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.75));
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.75));
  border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  bottom: 0;
  left: 0;
  line-height: 1.1;
  padding: 20px 6px 6px;
  position: absolute;
  right: 0;
  z-index: 3;
}

.plyr__preview-thumb__time-container span {
  color: #fff;
  font-size: 13px;
  font-size: 13px;
}

.plyr__preview-scrubbing {
  bottom: 0;
  filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease;
  width: 100%;
  z-index: 1;
}

.plyr__preview-scrubbing--is-shown {
  opacity: 1;
}

.plyr__preview-scrubbing img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  -o-object-fit: contain;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%;
}

.plyr--no-transition {
  transition: none !important;
}

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  border: 0 !important;
  height: 1px !important;
  overflow: hidden;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.plyr [hidden] {
  display: none !important;
}

/* 102: /app/components/Partials/VideoFramePlyr/VideoFramePlyr.scss */
@keyframes plyr-progress {
  to {
    background-position: 25px 0;
    background-position: 25px 0;
  }
}
@keyframes plyr-popup {
  0% {
    opacity: 0.5;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes plyr-fade-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.plyr {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  align-items: center;
  direction: ltr;
  display: flex;
  flex-direction: column;
  font-family: inherit;
  font-family: inherit;
  font-variant-numeric: tabular-nums;
  font-weight: 400;
  font-weight: 400;
  line-height: 1.7;
  line-height: 1.7;
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  transition: box-shadow 0.3s ease;
  z-index: 0;
}

.plyr audio,
.plyr iframe,
.plyr video {
  display: block;
  height: 100%;
  width: 100%;
}

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto;
}

.plyr:focus {
  outline: 0;
}

.plyr--full-ui {
  box-sizing: border-box;
}

.plyr--full-ui *,
.plyr--full-ui :after,
.plyr--full-ui :before {
  box-sizing: inherit;
}

.plyr--full-ui a,
.plyr--full-ui button,
.plyr--full-ui input,
.plyr--full-ui label {
  touch-action: manipulation;
}

.plyr__badge {
  background: #4a5464;
  background: #4a5464;
  border-radius: 2px;
  border-radius: 2px;
  color: #fff;
  color: #fff;
  font-size: 9px;
  font-size: 9px;
  line-height: 1;
  padding: 3px 4px;
}

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none;
}

.plyr__captions {
  animation: plyr-fade-in 0.3s ease;
  bottom: 0;
  display: none;
  font-size: 13px;
  font-size: 13px;
  left: 0;
  padding: 10px;
  padding: 10px;
  position: absolute;
  text-align: center;
  transition: transform 0.4s ease-in-out;
  width: 100%;
}

.plyr__captions span:empty {
  display: none;
}

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 15px;
    font-size: 15px;
    padding: 20px;
    padding: 20px;
  }
}
@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px;
    font-size: 18px;
  }
}
.plyr--captions-active .plyr__captions {
  display: block;
}

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  transform: translateY(-40px);
  transform: translateY(-40px);
}

.plyr__caption {
  background: rgba(0, 0, 0, 0.8);
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  color: #fff;
  color: #fff;
  line-height: 185%;
  padding: 0.2em 0.5em;
  white-space: pre-wrap;
}

.plyr__caption div {
  display: inline;
}

.plyr__control {
  background: transparent;
  border: 0;
  border-radius: 3px;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: 7px;
  padding: 7px;
  position: relative;
  transition: all 0.3s ease;
}

.plyr__control svg {
  fill: currentColor;
  display: block;
  height: 18px;
  height: 18px;
  pointer-events: none;
  width: 18px;
  width: 18px;
}

.plyr__control:focus {
  outline: 0;
}

.plyr__control.plyr__tab-focus {
  outline: 3px dotted #ff4b22;
  outline: #ff4b22 dotted 3px;
  outline-offset: 2px;
}

a.plyr__control {
  text-decoration: none;
}

.plyr__control.plyr__control--pressed .icon--not-pressed,
.plyr__control.plyr__control--pressed .label--not-pressed,
.plyr__control:not(.plyr__control--pressed) .icon--pressed,
.plyr__control:not(.plyr__control--pressed) .label--pressed,
a.plyr__control:after,
a.plyr__control:before {
  display: none;
}

.plyr--full-ui ::-webkit-media-controls {
  display: none;
}

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center;
}

.plyr__controls .plyr__progress__container {
  flex: 1;
  min-width: 0;
}

.plyr__controls .plyr__controls__item {
  margin-left: 2.5px;
  margin-left: 2.5px;
}

.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto;
}

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: 2.5px;
  padding-left: 2.5px;
}

.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 5px;
  padding: 0 5px;
}

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child,
.plyr__controls .plyr__controls__item.plyr__time + .plyr__time,
.plyr__controls .plyr__controls__item.plyr__time:first-child {
  padding-left: 0;
}

.plyr [data-plyr=airplay],
.plyr [data-plyr=captions],
.plyr [data-plyr=fullscreen],
.plyr [data-plyr=pip],
.plyr__controls:empty {
  display: none;
}

.plyr--airplay-supported [data-plyr=airplay],
.plyr--captions-enabled [data-plyr=captions],
.plyr--fullscreen-enabled [data-plyr=fullscreen],
.plyr--pip-supported [data-plyr=pip] {
  display: inline-block;
}

.plyr__menu {
  display: flex;
  position: relative;
}

.plyr__menu .plyr__control svg {
  transition: transform 0.3s ease;
}

.plyr__menu .plyr__control[aria-expanded=true] svg {
  transform: rotate(90deg);
}

.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
  display: none;
}

.plyr__menu__container {
  animation: plyr-popup 0.2s ease;
  background: hsla(0deg, 0%, 100%, 0.9);
  background: hsla(0deg, 0%, 100%, 0.9);
  border-radius: 4px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4a5464;
  color: #4a5464;
  font-size: 15px;
  font-size: 15px;
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3;
}

.plyr__menu__container > div {
  overflow: hidden;
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}

.plyr__menu__container:after {
  border: 4px solid transparent;
  border-top-color: hsla(0deg, 0%, 100%, 0.9);
  border: 4px solid transparent;
  border-top-color: hsla(0deg, 0%, 100%, 0.9);
  content: "";
  height: 0;
  position: absolute;
  right: 14px;
  right: 14px;
  top: 100%;
  width: 0;
}

.plyr__menu__container [role=menu] {
  padding: 7px;
  padding: 7px;
}

.plyr__menu__container [role=menuitem],
.plyr__menu__container [role=menuitemradio] {
  margin-top: 2px;
}

.plyr__menu__container [role=menuitem]:first-child,
.plyr__menu__container [role=menuitemradio]:first-child {
  margin-top: 0;
}

.plyr__menu__container .plyr__control {
  align-items: center;
  color: #4a5464;
  color: #4a5464;
  display: flex;
  font-size: 13px;
  font-size: 13px;
  padding: 4.66667px 10.5px;
  padding: 4.6666666667px 10.5px;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
}

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%;
}

.plyr__menu__container .plyr__control:after {
  border: 4px solid transparent;
  border: 4px solid transparent;
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px;
  padding-right: 28px;
}

.plyr__menu__container .plyr__control--forward:after {
  border-left-color: #728197;
  border-left-color: #728197;
  right: 6.5px;
  right: 6.5px;
}

.plyr__menu__container .plyr__control--forward.plyr__tab-focus:after,
.plyr__menu__container .plyr__control--forward:hover:after {
  border-left-color: currentColor;
}

.plyr__menu__container .plyr__control--back {
  font-weight: 400;
  font-weight: 400;
  margin: 7px;
  margin: 7px;
  margin-bottom: 3.5px;
  margin-bottom: 3.5px;
  padding-left: 28px;
  padding-left: 28px;
  position: relative;
  width: calc(100% - 14px);
  width: calc(100% - 14px);
}

.plyr__menu__container .plyr__control--back:after {
  border-right-color: #728197;
  border-right-color: #728197;
  left: 6.5px;
  left: 6.5px;
}

.plyr__menu__container .plyr__control--back:before {
  background: #dcdfe5;
  background: #dcdfe5;
  box-shadow: 0 1px 0 #fff;
  box-shadow: 0 1px 0 #fff;
  content: "";
  height: 1px;
  left: 0;
  margin-top: 3.5px;
  margin-top: 3.5px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%;
}

.plyr__menu__container .plyr__control--back.plyr__tab-focus:after,
.plyr__menu__container .plyr__control--back:hover:after {
  border-right-color: currentColor;
}

.plyr__menu__container .plyr__control[role=menuitemradio] {
  padding-left: 7px;
  padding-left: 7px;
}

.plyr__menu__container .plyr__control[role=menuitemradio]:after,
.plyr__menu__container .plyr__control[role=menuitemradio]:before {
  border-radius: 100%;
}

.plyr__menu__container .plyr__control[role=menuitemradio]:before {
  background: rgba(0, 0, 0, 0.1);
  content: "";
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  margin-right: 10px;
  transition: all 0.3s ease;
  width: 16px;
}

.plyr__menu__container .plyr__control[role=menuitemradio]:after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  transform: translateY(-50%) scale(0);
  transition: transform 0.3s ease, opacity 0.3s ease;
  width: 6px;
}

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]:before {
  background: #ff4b22;
  background: #ff4b22;
}

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]:after {
  opacity: 1;
  transform: translateY(-50%) scale(1);
}

.plyr__menu__container .plyr__control[role=menuitemradio].plyr__tab-focus:before,
.plyr__menu__container .plyr__control[role=menuitemradio]:hover:before {
  background: rgba(35, 40, 47, 0.1);
}

.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: -5px;
  overflow: hidden;
  padding-left: 24.5px;
  pointer-events: none;
}

.plyr--full-ui input[type=range] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: 26px;
  border-radius: 26px;
  color: #ff4b22;
  color: #ff4b22;
  display: block;
  height: 19px;
  height: 19px;
  margin: 0;
  min-width: 0;
  padding: 0;
  transition: box-shadow 0.3s ease;
  width: 100%;
}

.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
  background: transparent;
  background-image: linear-gradient(90deg, currentColor 0, transparent 0);
  background-image: linear-gradient(to right, currentColor 0, transparent 0);
  border: 0;
  border-radius: 2.5px;
  border-radius: 2.5px;
  height: 5px;
  height: 5px;
  -webkit-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  -webkit-user-select: none;
  user-select: none;
}

.plyr--full-ui input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: #fff;
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2);
  height: 13px;
  height: 13px;
  margin-top: -4px;
  margin-top: -4px;
  position: relative;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 13px;
  width: 13px;
}

.plyr--full-ui input[type=range]::-moz-range-track {
  background: transparent;
  border: 0;
  border-radius: 2.5px;
  border-radius: 2.5px;
  height: 5px;
  height: 5px;
  -moz-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  user-select: none;
}

.plyr--full-ui input[type=range]::-moz-range-thumb {
  background: #fff;
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2);
  height: 13px;
  height: 13px;
  position: relative;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 13px;
  width: 13px;
}

.plyr--full-ui input[type=range]::-moz-range-progress {
  background: currentColor;
  border-radius: 2.5px;
  border-radius: 2.5px;
  height: 5px;
  height: 5px;
}

.plyr--full-ui input[type=range]::-ms-track {
  color: transparent;
}

.plyr--full-ui input[type=range]::-ms-fill-upper,
.plyr--full-ui input[type=range]::-ms-track {
  background: transparent;
  border: 0;
  border-radius: 2.5px;
  border-radius: 2.5px;
  height: 5px;
  height: 5px;
  -ms-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  user-select: none;
}

.plyr--full-ui input[type=range]::-ms-fill-lower {
  background: transparent;
  background: currentColor;
  border: 0;
  border-radius: 2.5px;
  border-radius: 2.5px;
  height: 5px;
  height: 5px;
  -ms-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  user-select: none;
}

.plyr--full-ui input[type=range]::-ms-thumb {
  background: #fff;
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2);
  height: 13px;
  height: 13px;
  margin-top: 0;
  position: relative;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 13px;
  width: 13px;
}

.plyr--full-ui input[type=range]::-ms-tooltip {
  display: none;
}

.plyr--full-ui input[type=range]::-moz-focus-outer {
  border: 0;
}

.plyr--full-ui input[type=range]:focus {
  outline: 0;
}

.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
  outline: 3px dotted #ff4b22;
  outline: #ff4b22 dotted 3px;
  outline-offset: 2px;
}

.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
  outline: 3px dotted #ff4b22;
  outline: #ff4b22 dotted 3px;
  outline-offset: 2px;
}

.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
  outline: 3px dotted #ff4b22;
  outline: #ff4b22 dotted 3px;
  outline-offset: 2px;
}

.plyr__poster {
  background-color: #000;
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.2s ease;
  width: 100%;
  z-index: 1;
}

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1;
}

.plyr--youtube.plyr--paused.plyr__poster-enabled:not(.plyr--stopped) .plyr__poster {
  display: none;
}

.plyr__time {
  font-size: 13px;
  font-size: 13px;
}

.plyr__time + .plyr__time:before {
  content: "⁄";
  margin-right: 10px;
  margin-right: 10px;
}

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none;
  }
}
.plyr__tooltip {
  background: hsla(0deg, 0%, 100%, 0.9);
  background: hsla(0deg, 0%, 100%, 0.9);
  border-radius: 5px;
  border-radius: 5px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4a5464;
  color: #4a5464;
  font-size: 13px;
  font-size: 13px;
  font-weight: 400;
  font-weight: 400;
  left: 50%;
  line-height: 1.3;
  margin-bottom: 10px;
  margin-bottom: 10px;
  opacity: 0;
  padding: 5px 7.5px;
  padding: 5px 7.5px;
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, 10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform 0.2s ease 0.1s, opacity 0.2s ease 0.1s;
  white-space: nowrap;
  z-index: 2;
}

.plyr__tooltip:before {
  border-left: 4px solid transparent;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid hsla(0deg, 0%, 100%, 0.9);
  border-top: 4px solid hsla(0deg, 0%, 100%, 0.9);
  bottom: -4px;
  bottom: -4px;
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 0;
  z-index: 2;
}

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip,
.plyr .plyr__control:hover .plyr__tooltip,
.plyr__tooltip--visible {
  opacity: 1;
  transform: translate(-50%) scale(1);
}

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3;
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip,
.plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  transform: translateY(10px) scale(0.8);
  transform-origin: 0 100%;
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip:before,
.plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip:before {
  left: 16px;
  left: 16px;
}

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  transform: translateY(10px) scale(0.8);
  transform-origin: 100% 100%;
}

.plyr__controls > .plyr__control:last-child .plyr__tooltip:before {
  left: auto;
  right: 16px;
  right: 16px;
  transform: translateX(50%);
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible,
.plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible,
.plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip,
.plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip,
.plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip,
.plyr__controls > .plyr__control:first-child:hover .plyr__tooltip,
.plyr__controls > .plyr__control:last-child .plyr__tooltip--visible,
.plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip,
.plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  transform: translate(0) scale(1);
}

.plyr__progress {
  left: 6.5px;
  left: 6.5px;
  margin-right: 13px;
  margin-right: 13px;
  position: relative;
}

.plyr__progress input[type=range],
.plyr__progress__buffer {
  margin-left: -6.5px;
  margin-left: -6.5px;
  margin-right: -6.5px;
  margin-right: -6.5px;
  width: calc(100% + 13px);
  width: calc(100% + 13px);
}

.plyr__progress input[type=range] {
  position: relative;
  z-index: 2;
}

.plyr__progress .plyr__tooltip {
  left: 0;
  max-width: 120px;
  overflow-wrap: break-word;
  white-space: normal;
}

.plyr__progress__buffer {
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  border-radius: 100px;
  height: 5px;
  height: 5px;
  left: 0;
  margin-top: -2.5px;
  margin-top: -2.5px;
  padding: 0;
  position: absolute;
  top: 50%;
}

.plyr__progress__buffer::-webkit-progress-bar {
  background: transparent;
}

.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  min-width: 5px;
  -webkit-transition: width 0.2s ease;
  transition: width 0.2s ease;
}

.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  min-width: 5px;
  -moz-transition: width 0.2s ease;
  transition: width 0.2s ease;
}

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  -ms-transition: width 0.2s ease;
  transition: width 0.2s ease;
}

.plyr--loading .plyr__progress__buffer {
  animation: plyr-progress 1s linear infinite;
  background-image: linear-gradient(-45deg, rgba(35, 40, 47, 0.6) 25%, transparent 0, transparent 50%, rgba(35, 40, 47, 0.6) 0, rgba(35, 40, 47, 0.6) 75%, transparent 0, transparent);
  background-image: linear-gradient(-45deg, rgba(35, 40, 47, 0.6) 25%, transparent 25%, transparent 50%, rgba(35, 40, 47, 0.6) 50%, rgba(35, 40, 47, 0.6) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  background-size: 25px 25px;
  color: transparent;
}

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: hsla(0deg, 0%, 100%, 0.25);
  background-color: hsla(0deg, 0%, 100%, 0.25);
}

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(193, 200, 209, 0.6);
  background-color: rgba(193, 200, 209, 0.6);
}

.plyr__progress__marker {
  background-color: #fff;
  background-color: #fff;
  border-radius: 1px;
  height: 5px;
  height: 5px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 3px;
  width: 3px;
  z-index: 3;
}

.plyr__volume {
  align-items: center;
  display: flex;
  max-width: 110px;
  min-width: 80px;
  position: relative;
  width: 20%;
}

.plyr__volume input[type=range] {
  margin-left: 5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-right: 5px;
  position: relative;
  z-index: 2;
}

.plyr--is-ios .plyr__volume {
  min-width: 0;
  width: auto;
}

.plyr--audio {
  display: block;
}

.plyr--audio .plyr__controls {
  background: #fff;
  background: #fff;
  border-radius: inherit;
  color: #4a5464;
  color: #4a5464;
  padding: 10px;
  padding: 10px;
}

.plyr--audio .plyr__control.plyr__tab-focus,
.plyr--audio .plyr__control:hover,
.plyr--audio .plyr__control[aria-expanded=true] {
  background: #ff4b22;
  background: #ff4b22;
  color: #fff;
  color: #fff;
}

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(193, 200, 209, 0.6);
  background-color: rgba(193, 200, 209, 0.6);
}

.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
  background-color: rgba(193, 200, 209, 0.6);
  background-color: rgba(193, 200, 209, 0.6);
}

.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
  background-color: rgba(193, 200, 209, 0.6);
  background-color: rgba(193, 200, 209, 0.6);
}

.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2), 0 0 0 3px rgba(35, 40, 47, 0.1);
  box-shadow: 0 0 0 3px rgba(35, 40, 47, 0.1);
}

.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2), 0 0 0 3px rgba(35, 40, 47, 0.1);
  box-shadow: 0 0 0 3px rgba(35, 40, 47, 0.1);
}

.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2), 0 0 0 3px rgba(35, 40, 47, 0.1);
  box-shadow: 0 0 0 3px rgba(35, 40, 47, 0.1);
}

.plyr--audio .plyr__progress__buffer {
  color: rgba(193, 200, 209, 0.6);
  color: rgba(193, 200, 209, 0.6);
}

.plyr--video {
  background: #000;
  background: #000;
  overflow: hidden;
}

.plyr--video.plyr--menu-open {
  overflow: visible;
}

.plyr__video-wrapper {
  background: #000;
  background: #000;
  height: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.plyr__video-embed,
.plyr__video-wrapper--fixed-ratio {
  aspect-ratio: 16/9;
}

@supports not (aspect-ratio: 16/9) {
  .plyr__video-embed,
.plyr__video-wrapper--fixed-ratio {
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
  }
}
.plyr__video-embed iframe,
.plyr__video-wrapper--fixed-ratio video {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  transform: translateY(-38.28125%);
}

.plyr--video .plyr__controls {
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.75));
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.75));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  color: #fff;
  left: 0;
  padding: 5px;
  padding: 5px;
  padding-top: 20px;
  padding-top: 20px;
  position: absolute;
  right: 0;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
  z-index: 3;
}

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 10px;
    padding: 10px;
    padding-top: 35px;
    padding-top: 35px;
  }
}
.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  transform: translateY(100%);
}

.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded=true] {
  background: #ff4b22;
  background: #ff4b22;
  color: #fff;
  color: #fff;
}

.plyr__control--overlaid {
  background: #ff4b22;
  background: #ff4b22;
  border: 0;
  border-radius: 100%;
  color: #fff;
  color: #fff;
  display: none;
  left: 50%;
  opacity: 0.9;
  padding: 15px;
  padding: 15px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s;
  z-index: 2;
}

.plyr__control--overlaid svg {
  left: 2px;
  position: relative;
}

.plyr__control--overlaid:focus,
.plyr__control--overlaid:hover {
  opacity: 1;
}

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden;
}

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block;
}

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
  background-color: hsla(0deg, 0%, 100%, 0.25);
  background-color: hsla(0deg, 0%, 100%, 0.25);
}

.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
  background-color: hsla(0deg, 0%, 100%, 0.25);
  background-color: hsla(0deg, 0%, 100%, 0.25);
}

.plyr--full-ui.plyr--video input[type=range]::-ms-track {
  background-color: hsla(0deg, 0%, 100%, 0.25);
  background-color: hsla(0deg, 0%, 100%, 0.25);
}

.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2), 0 0 0 3px hsla(0deg, 0%, 100%, 0.5);
  box-shadow: 0 0 0 3px hsla(0deg, 0%, 100%, 0.5);
}

.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2), 0 0 0 3px hsla(0deg, 0%, 100%, 0.5);
  box-shadow: 0 0 0 3px hsla(0deg, 0%, 100%, 0.5);
}

.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2), 0 0 0 3px hsla(0deg, 0%, 100%, 0.5);
  box-shadow: 0 0 0 3px hsla(0deg, 0%, 100%, 0.5);
}

.plyr--video .plyr__progress__buffer {
  color: hsla(0deg, 0%, 100%, 0.25);
  color: hsla(0deg, 0%, 100%, 0.25);
}

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:fullscreen video {
  height: 100%;
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:fullscreen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:fullscreen .plyr__captions {
    font-size: 21px;
    font-size: 21px;
  }
}
.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  margin: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10000000;
}

.plyr--fullscreen-fallback video {
  height: 100%;
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px;
    font-size: 21px;
  }
}
.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.plyr__ads > div,
.plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%;
}

.plyr__ads:after {
  background: #23282f;
  border-radius: 2px;
  bottom: 10px;
  bottom: 10px;
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  right: 10px;
  z-index: 3;
}

.plyr__ads:empty:after {
  display: none;
}

.plyr__cues {
  background: currentColor;
  display: block;
  height: 5px;
  height: 5px;
  left: 0;
  opacity: 0.8;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  z-index: 3;
}

.plyr__preview-thumb {
  background-color: hsla(0deg, 0%, 100%, 0.9);
  background-color: hsla(0deg, 0%, 100%, 0.9);
  border-radius: 5px;
  border-radius: 5px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  margin-bottom: 10px;
  opacity: 0;
  padding: 3px;
  pointer-events: none;
  position: absolute;
  transform: translateY(10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform 0.2s ease 0.1s, opacity 0.2s ease 0.1s;
  z-index: 2;
}

.plyr__preview-thumb--is-shown {
  opacity: 1;
  transform: translate(0) scale(1);
}

.plyr__preview-thumb:before {
  border-left: 4px solid transparent;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid hsla(0deg, 0%, 100%, 0.9);
  border-top: 4px solid hsla(0deg, 0%, 100%, 0.9);
  bottom: -4px;
  bottom: -4px;
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 0;
  z-index: 2;
}

.plyr__preview-thumb__image-container {
  background: #c1c8d1;
  border-radius: 4px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.plyr__preview-thumb__image-container img,
.plyr__preview-thumb__image-container:after {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.plyr__preview-thumb__image-container:after {
  border-radius: inherit;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
  content: "";
  pointer-events: none;
}

.plyr__preview-thumb__image-container img {
  max-height: none;
  max-width: none;
}

.plyr__preview-thumb__time-container {
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.75));
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.75));
  border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  bottom: 0;
  left: 0;
  line-height: 1.1;
  padding: 20px 6px 6px;
  position: absolute;
  right: 0;
  z-index: 3;
}

.plyr__preview-thumb__time-container span {
  color: #fff;
  font-size: 13px;
  font-size: 13px;
}

.plyr__preview-scrubbing {
  bottom: 0;
  filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease;
  width: 100%;
  z-index: 1;
}

.plyr__preview-scrubbing--is-shown {
  opacity: 1;
}

.plyr__preview-scrubbing img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  -o-object-fit: contain;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%;
}

.plyr--no-transition {
  transition: none !important;
}

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  border: 0 !important;
  height: 1px !important;
  overflow: hidden;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.plyr [hidden] {
  display: none !important;
}

.VideoFramePlyr {
  overflow: hidden;
  position: relative;
  width: 200%;
  transform: translateX(-25%);
}
@media screen and (min-width: 992px) {
  .VideoFramePlyr {
    width: 100%;
    transform: translateX(0);
  }
}
.VideoFramePlyr.radius {
  border-radius: 16px;
}

/* 103: /app/components/Ui/Badge/Badge.scss */
.Badge {
  display: inline-block;
  padding: 2px 7px;
  border-radius: 4px;
}
.Badge.bg-brand-medium {
  background: #ffd2c8;
}
.Badge.bg-brand-light {
  background: #fff5f3;
}
.Badge.bg-light {
  background: #ffffff;
}
.Badge.white {
  background: white;
}

/* 104: /app/components/Ui/Button/Button.scss */
.Button {
  position: relative;
  padding: 0 20px;
  align-items: center;
  justify-content: center;
  width: auto;
  border-radius: 8px;
  height: 50px;
  border: 0;
  appearance: none;
  display: flex;
  border: 1px solid transparent;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none !important;
  width: 100%;
  transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);
}
@media screen and (min-width: 992px) {
  .Button {
    width: auto;
    display: inline-flex;
    height: 40px;
  }
}
.Button .button--content {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
}
.Button .button--content .Icon, .Button .button--content .Gallery .slick-prev, .Gallery .Button .button--content .slick-prev,
.Button .button--content .Gallery .slick-next,
.Gallery .Button .button--content .slick-next, .Button .button--content .SliderGuests .slick-prev, .SliderGuests .Button .button--content .slick-prev,
.Button .button--content .SliderGuests .slick-next,
.SliderGuests .Button .button--content .slick-next, .Button .button--content .SliderImages .slick-prev, .SliderImages .Button .button--content .slick-prev,
.Button .button--content .SliderImages .slick-next,
.SliderImages .Button .button--content .slick-next, .Button .button--content .SliderNumbers .slick-prev, .SliderNumbers .Button .button--content .slick-prev,
.Button .button--content .SliderNumbers .slick-next,
.SliderNumbers .Button .button--content .slick-next, .Button .button--content .SliderQuotes .slick-prev, .SliderQuotes .Button .button--content .slick-prev,
.Button .button--content .SliderQuotes .slick-next,
.SliderQuotes .Button .button--content .slick-next, .Button .button--content .SliderVideos .slick-prev, .SliderVideos .Button .button--content .slick-prev,
.Button .button--content .SliderVideos .slick-next,
.SliderVideos .Button .button--content .slick-next {
  margin-right: 6px;
}
.Button.small {
  height: 40px;
}
@media screen and (min-width: 992px) {
  .Button.small {
    height: 32px;
  }
}
.Button.block {
  width: 100%;
}
.Button.block .button--content {
  justify-content: center;
}
.Button.disabled {
  background: #b2b2b2;
  cursor: not-allowed;
  opacity: 0.7;
}
.Button.type-primary, .Button.type-brand {
  background: #ff4b23;
  border: 0;
  color: #ffffff;
}
.Button.type-primary:hover, .Button.type-brand:hover {
  background: #ffd2c8;
  border: 0;
  color: #ff4b23;
}
.Button.type-primary-outlines, .Button.type-brand-outlines {
  background: transparent;
  border: 1px solid #ff4b23;
  color: #000000;
}
.Button.type-primary-outlines:hover, .Button.type-brand-outlines:hover {
  background: #fff5f3;
  color: #ff4b23;
}
.Button.type-dark {
  background: #000000;
  border: 0;
  color: #ffffff;
}
.Button.type-dark:hover {
  background: #ffd2c8;
  border: 0;
  color: #ff4b23;
}
.Button.type-light {
  background: #ffffff;
  border: 0;
  color: #000000;
}
.Button.type-light:hover {
  background: #fff5f3;
  color: #ff4b23;
}
.Button.type-light-outlines {
  background: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
}
.Button.type-light-outlines:hover {
  background: #fff5f3;
  color: #ff4b23;
}
.Button.disabled {
  background: #b2b2b2;
  cursor: not-allowed;
  opacity: 0.7;
}

.Button-Icon {
  padding: 0;
  width: 60px;
  height: 60px;
}
@media screen and (min-width: 992px) {
  .Button-Icon {
    width: 80px;
    height: 80px;
  }
}

/* 105: /app/components/Ui/Center/Center.scss */
.Center {
  text-align: center;
}

/* 106: /app/components/Ui/FrameForm/FrameForm.scss */
/* 107: /app/components/Ui/Grid/Column/Column.scss */
.Column {
  position: relative;
}

/* 108: /app/components/Ui/Grid/Debug/Debug.scss */
.Grid-Debug {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  pointer-events: none;
}
.Grid-Debug * {
  height: 100%;
}
.Grid-Debug .Column {
  position: relative;
  height: 100%;
}
.Grid-Debug .Column .Column-Inner {
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 109: /app/components/Ui/Grid/Row/Row.scss */
.Row {
  position: relative;
}

/* 110: /app/components/Ui/Grid/Sizer/Sizer.scss */
.Sizer {
  position: relative;
}

/* 111: /app/components/Ui/Icon/Icon.scss */
.Icon, .Gallery .slick-prev,
.Gallery .slick-next, .SliderGuests .slick-prev,
.SliderGuests .slick-next, .SliderImages .slick-prev,
.SliderImages .slick-next, .SliderNumbers .slick-prev,
.SliderNumbers .slick-next, .SliderQuotes .slick-prev,
.SliderQuotes .slick-next, .SliderVideos .slick-prev,
.SliderVideos .slick-next {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "coda-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.Icon.color-default, .Gallery .color-default.slick-prev,
.Gallery .color-default.slick-next, .SliderGuests .color-default.slick-prev,
.SliderGuests .color-default.slick-next, .SliderImages .color-default.slick-prev,
.SliderImages .color-default.slick-next, .SliderNumbers .color-default.slick-prev,
.SliderNumbers .color-default.slick-next, .SliderQuotes .color-default.slick-prev,
.SliderQuotes .color-default.slick-next, .SliderVideos .color-default.slick-prev,
.SliderVideos .color-default.slick-next {
  color: blue;
}
.Icon.color-inherit, .Gallery .color-inherit.slick-prev,
.Gallery .color-inherit.slick-next, .SliderGuests .color-inherit.slick-prev,
.SliderGuests .color-inherit.slick-next, .SliderImages .color-inherit.slick-prev,
.SliderImages .color-inherit.slick-next, .SliderNumbers .color-inherit.slick-prev,
.SliderNumbers .color-inherit.slick-next, .SliderQuotes .color-inherit.slick-prev,
.SliderQuotes .color-inherit.slick-next, .SliderVideos .color-inherit.slick-prev,
.SliderVideos .color-inherit.slick-next {
  color: inherit;
}
.Icon.color-brand, .Gallery .color-brand.slick-prev,
.Gallery .color-brand.slick-next, .SliderGuests .color-brand.slick-prev,
.SliderGuests .color-brand.slick-next, .SliderImages .color-brand.slick-prev,
.SliderImages .color-brand.slick-next, .SliderNumbers .color-brand.slick-prev,
.SliderNumbers .color-brand.slick-next, .SliderQuotes .color-brand.slick-prev,
.SliderQuotes .color-brand.slick-next, .SliderVideos .color-brand.slick-prev,
.SliderVideos .color-brand.slick-next {
  color: #ff4b23;
}
.Icon.color-brandLight, .Gallery .color-brandLight.slick-prev,
.Gallery .color-brandLight.slick-next, .SliderGuests .color-brandLight.slick-prev,
.SliderGuests .color-brandLight.slick-next, .SliderImages .color-brandLight.slick-prev,
.SliderImages .color-brandLight.slick-next, .SliderNumbers .color-brandLight.slick-prev,
.SliderNumbers .color-brandLight.slick-next, .SliderQuotes .color-brandLight.slick-prev,
.SliderQuotes .color-brandLight.slick-next, .SliderVideos .color-brandLight.slick-prev,
.SliderVideos .color-brandLight.slick-next {
  color: #fff5f3;
}
.Icon.color-light, .Gallery .color-light.slick-prev,
.Gallery .color-light.slick-next, .SliderGuests .color-light.slick-prev,
.SliderGuests .color-light.slick-next, .SliderImages .color-light.slick-prev,
.SliderImages .color-light.slick-next, .SliderNumbers .color-light.slick-prev,
.SliderNumbers .color-light.slick-next, .SliderQuotes .color-light.slick-prev,
.SliderQuotes .color-light.slick-next, .SliderVideos .color-light.slick-prev,
.SliderVideos .color-light.slick-next {
  color: #ffffff;
}
.Icon.color-light-50, .Gallery .color-light-50.slick-prev,
.Gallery .color-light-50.slick-next, .SliderGuests .color-light-50.slick-prev,
.SliderGuests .color-light-50.slick-next, .SliderImages .color-light-50.slick-prev,
.SliderImages .color-light-50.slick-next, .SliderNumbers .color-light-50.slick-prev,
.SliderNumbers .color-light-50.slick-next, .SliderQuotes .color-light-50.slick-prev,
.SliderQuotes .color-light-50.slick-next, .SliderVideos .color-light-50.slick-prev,
.SliderVideos .color-light-50.slick-next {
  color: rgba(255, 255, 255, 0.5);
}
.Icon.color-dark, .Gallery .color-dark.slick-prev,
.Gallery .color-dark.slick-next, .SliderGuests .color-dark.slick-prev,
.SliderGuests .color-dark.slick-next, .SliderImages .color-dark.slick-prev,
.SliderImages .color-dark.slick-next, .SliderNumbers .color-dark.slick-prev,
.SliderNumbers .color-dark.slick-next, .SliderQuotes .color-dark.slick-prev,
.SliderQuotes .color-dark.slick-next, .SliderVideos .color-dark.slick-prev,
.SliderVideos .color-dark.slick-next {
  color: #000000;
}
.Icon.color-dark-50, .Gallery .color-dark-50.slick-prev,
.Gallery .color-dark-50.slick-next, .SliderGuests .color-dark-50.slick-prev,
.SliderGuests .color-dark-50.slick-next, .SliderImages .color-dark-50.slick-prev,
.SliderImages .color-dark-50.slick-next, .SliderNumbers .color-dark-50.slick-prev,
.SliderNumbers .color-dark-50.slick-next, .SliderQuotes .color-dark-50.slick-prev,
.SliderQuotes .color-dark-50.slick-next, .SliderVideos .color-dark-50.slick-prev,
.SliderVideos .color-dark-50.slick-next {
  color: rgba(0, 0, 0, 0.5);
}
.Icon.icon-burger:before, .Gallery .icon-burger.slick-prev:before,
.Gallery .icon-burger.slick-next:before, .SliderGuests .icon-burger.slick-prev:before,
.SliderGuests .icon-burger.slick-next:before, .SliderImages .icon-burger.slick-prev:before,
.SliderImages .icon-burger.slick-next:before, .SliderNumbers .icon-burger.slick-prev:before,
.SliderNumbers .icon-burger.slick-next:before, .SliderQuotes .icon-burger.slick-prev:before,
.SliderQuotes .icon-burger.slick-next:before, .SliderVideos .icon-burger.slick-prev:before,
.SliderVideos .icon-burger.slick-next:before {
  content: "\e900";
}
.Icon.icon-download:before, .Gallery .icon-download.slick-prev:before,
.Gallery .icon-download.slick-next:before, .SliderGuests .icon-download.slick-prev:before,
.SliderGuests .icon-download.slick-next:before, .SliderImages .icon-download.slick-prev:before,
.SliderImages .icon-download.slick-next:before, .SliderNumbers .icon-download.slick-prev:before,
.SliderNumbers .icon-download.slick-next:before, .SliderQuotes .icon-download.slick-prev:before,
.SliderQuotes .icon-download.slick-next:before, .SliderVideos .icon-download.slick-prev:before,
.SliderVideos .icon-download.slick-next:before {
  content: "\e917";
}
.Icon.icon-arrow-up:before, .Gallery .icon-arrow-up.slick-prev:before,
.Gallery .icon-arrow-up.slick-next:before, .SliderGuests .icon-arrow-up.slick-prev:before,
.SliderGuests .icon-arrow-up.slick-next:before, .SliderImages .icon-arrow-up.slick-prev:before,
.SliderImages .icon-arrow-up.slick-next:before, .SliderNumbers .icon-arrow-up.slick-prev:before,
.SliderNumbers .icon-arrow-up.slick-next:before, .SliderQuotes .icon-arrow-up.slick-prev:before,
.SliderQuotes .icon-arrow-up.slick-next:before, .SliderVideos .icon-arrow-up.slick-prev:before,
.SliderVideos .icon-arrow-up.slick-next:before {
  content: "\e901";
}
.Icon.icon-arrow-down:before, .Gallery .icon-arrow-down.slick-prev:before,
.Gallery .icon-arrow-down.slick-next:before, .SliderGuests .icon-arrow-down.slick-prev:before,
.SliderGuests .icon-arrow-down.slick-next:before, .SliderImages .icon-arrow-down.slick-prev:before,
.SliderImages .icon-arrow-down.slick-next:before, .SliderNumbers .icon-arrow-down.slick-prev:before,
.SliderNumbers .icon-arrow-down.slick-next:before, .SliderQuotes .icon-arrow-down.slick-prev:before,
.SliderQuotes .icon-arrow-down.slick-next:before, .SliderVideos .icon-arrow-down.slick-prev:before,
.SliderVideos .icon-arrow-down.slick-next:before {
  content: "\e902";
}
.Icon.icon-arrow-left:before, .Gallery .icon-arrow-left.slick-prev:before,
.Gallery .icon-arrow-left.slick-next:before, .SliderGuests .icon-arrow-left.slick-prev:before,
.SliderGuests .icon-arrow-left.slick-next:before, .SliderImages .icon-arrow-left.slick-prev:before,
.SliderImages .icon-arrow-left.slick-next:before, .SliderNumbers .icon-arrow-left.slick-prev:before,
.SliderNumbers .icon-arrow-left.slick-next:before, .SliderQuotes .icon-arrow-left.slick-prev:before,
.SliderQuotes .icon-arrow-left.slick-next:before, .SliderVideos .icon-arrow-left.slick-prev:before,
.SliderVideos .icon-arrow-left.slick-next:before, .Gallery .Icon.slick-prev:before, .Gallery .slick-prev:before,
.Gallery .slick-next.slick-prev:before, .SliderGuests .Gallery .slick-prev:before, .Gallery .SliderGuests .slick-prev:before,
.SliderGuests .Gallery .slick-next.slick-prev:before,
.Gallery .SliderGuests .slick-next.slick-prev:before, .SliderImages .Gallery .slick-prev:before, .Gallery .SliderImages .slick-prev:before,
.SliderImages .Gallery .slick-next.slick-prev:before,
.Gallery .SliderImages .slick-next.slick-prev:before, .SliderNumbers .Gallery .slick-prev:before, .Gallery .SliderNumbers .slick-prev:before,
.SliderNumbers .Gallery .slick-next.slick-prev:before,
.Gallery .SliderNumbers .slick-next.slick-prev:before, .SliderQuotes .Gallery .slick-prev:before, .Gallery .SliderQuotes .slick-prev:before,
.SliderQuotes .Gallery .slick-next.slick-prev:before,
.Gallery .SliderQuotes .slick-next.slick-prev:before, .SliderVideos .Gallery .slick-prev:before, .Gallery .SliderVideos .slick-prev:before,
.SliderVideos .Gallery .slick-next.slick-prev:before,
.Gallery .SliderVideos .slick-next.slick-prev:before, .SliderGuests .Icon.slick-prev:before, .Gallery .SliderGuests .slick-prev:before, .SliderGuests .Gallery .slick-prev:before,
.Gallery .SliderGuests .slick-next.slick-prev:before,
.SliderGuests .Gallery .slick-next.slick-prev:before, .SliderGuests .slick-prev:before,
.SliderGuests .slick-next.slick-prev:before, .SliderImages .SliderGuests .slick-prev:before, .SliderGuests .SliderImages .slick-prev:before,
.SliderImages .SliderGuests .slick-next.slick-prev:before,
.SliderGuests .SliderImages .slick-next.slick-prev:before, .SliderNumbers .SliderGuests .slick-prev:before, .SliderGuests .SliderNumbers .slick-prev:before,
.SliderNumbers .SliderGuests .slick-next.slick-prev:before,
.SliderGuests .SliderNumbers .slick-next.slick-prev:before, .SliderQuotes .SliderGuests .slick-prev:before, .SliderGuests .SliderQuotes .slick-prev:before,
.SliderQuotes .SliderGuests .slick-next.slick-prev:before,
.SliderGuests .SliderQuotes .slick-next.slick-prev:before, .SliderVideos .SliderGuests .slick-prev:before, .SliderGuests .SliderVideos .slick-prev:before,
.SliderVideos .SliderGuests .slick-next.slick-prev:before,
.SliderGuests .SliderVideos .slick-next.slick-prev:before, .SliderImages .Icon.slick-prev:before, .Gallery .SliderImages .slick-prev:before, .SliderImages .Gallery .slick-prev:before,
.Gallery .SliderImages .slick-next.slick-prev:before,
.SliderImages .Gallery .slick-next.slick-prev:before, .SliderGuests .SliderImages .slick-prev:before, .SliderImages .SliderGuests .slick-prev:before,
.SliderGuests .SliderImages .slick-next.slick-prev:before,
.SliderImages .SliderGuests .slick-next.slick-prev:before, .SliderImages .slick-prev:before,
.SliderImages .slick-next.slick-prev:before, .SliderNumbers .SliderImages .slick-prev:before, .SliderImages .SliderNumbers .slick-prev:before,
.SliderNumbers .SliderImages .slick-next.slick-prev:before,
.SliderImages .SliderNumbers .slick-next.slick-prev:before, .SliderQuotes .SliderImages .slick-prev:before, .SliderImages .SliderQuotes .slick-prev:before,
.SliderQuotes .SliderImages .slick-next.slick-prev:before,
.SliderImages .SliderQuotes .slick-next.slick-prev:before, .SliderVideos .SliderImages .slick-prev:before, .SliderImages .SliderVideos .slick-prev:before,
.SliderVideos .SliderImages .slick-next.slick-prev:before,
.SliderImages .SliderVideos .slick-next.slick-prev:before, .SliderNumbers .Icon.slick-prev:before, .Gallery .SliderNumbers .slick-prev:before, .SliderNumbers .Gallery .slick-prev:before,
.Gallery .SliderNumbers .slick-next.slick-prev:before,
.SliderNumbers .Gallery .slick-next.slick-prev:before, .SliderGuests .SliderNumbers .slick-prev:before, .SliderNumbers .SliderGuests .slick-prev:before,
.SliderGuests .SliderNumbers .slick-next.slick-prev:before,
.SliderNumbers .SliderGuests .slick-next.slick-prev:before, .SliderImages .SliderNumbers .slick-prev:before, .SliderNumbers .SliderImages .slick-prev:before,
.SliderImages .SliderNumbers .slick-next.slick-prev:before,
.SliderNumbers .SliderImages .slick-next.slick-prev:before, .SliderNumbers .slick-prev:before,
.SliderNumbers .slick-next.slick-prev:before, .SliderQuotes .SliderNumbers .slick-prev:before, .SliderNumbers .SliderQuotes .slick-prev:before,
.SliderQuotes .SliderNumbers .slick-next.slick-prev:before,
.SliderNumbers .SliderQuotes .slick-next.slick-prev:before, .SliderVideos .SliderNumbers .slick-prev:before, .SliderNumbers .SliderVideos .slick-prev:before,
.SliderVideos .SliderNumbers .slick-next.slick-prev:before,
.SliderNumbers .SliderVideos .slick-next.slick-prev:before, .SliderQuotes .Icon.slick-prev:before, .Gallery .SliderQuotes .slick-prev:before, .SliderQuotes .Gallery .slick-prev:before,
.Gallery .SliderQuotes .slick-next.slick-prev:before,
.SliderQuotes .Gallery .slick-next.slick-prev:before, .SliderGuests .SliderQuotes .slick-prev:before, .SliderQuotes .SliderGuests .slick-prev:before,
.SliderGuests .SliderQuotes .slick-next.slick-prev:before,
.SliderQuotes .SliderGuests .slick-next.slick-prev:before, .SliderImages .SliderQuotes .slick-prev:before, .SliderQuotes .SliderImages .slick-prev:before,
.SliderImages .SliderQuotes .slick-next.slick-prev:before,
.SliderQuotes .SliderImages .slick-next.slick-prev:before, .SliderNumbers .SliderQuotes .slick-prev:before, .SliderQuotes .SliderNumbers .slick-prev:before,
.SliderNumbers .SliderQuotes .slick-next.slick-prev:before,
.SliderQuotes .SliderNumbers .slick-next.slick-prev:before, .SliderQuotes .slick-prev:before,
.SliderQuotes .slick-next.slick-prev:before, .SliderVideos .SliderQuotes .slick-prev:before, .SliderQuotes .SliderVideos .slick-prev:before,
.SliderVideos .SliderQuotes .slick-next.slick-prev:before,
.SliderQuotes .SliderVideos .slick-next.slick-prev:before, .SliderVideos .Icon.slick-prev:before, .Gallery .SliderVideos .slick-prev:before, .SliderVideos .Gallery .slick-prev:before,
.Gallery .SliderVideos .slick-next.slick-prev:before,
.SliderVideos .Gallery .slick-next.slick-prev:before, .SliderGuests .SliderVideos .slick-prev:before, .SliderVideos .SliderGuests .slick-prev:before,
.SliderGuests .SliderVideos .slick-next.slick-prev:before,
.SliderVideos .SliderGuests .slick-next.slick-prev:before, .SliderImages .SliderVideos .slick-prev:before, .SliderVideos .SliderImages .slick-prev:before,
.SliderImages .SliderVideos .slick-next.slick-prev:before,
.SliderVideos .SliderImages .slick-next.slick-prev:before, .SliderNumbers .SliderVideos .slick-prev:before, .SliderVideos .SliderNumbers .slick-prev:before,
.SliderNumbers .SliderVideos .slick-next.slick-prev:before,
.SliderVideos .SliderNumbers .slick-next.slick-prev:before, .SliderQuotes .SliderVideos .slick-prev:before, .SliderVideos .SliderQuotes .slick-prev:before,
.SliderQuotes .SliderVideos .slick-next.slick-prev:before,
.SliderVideos .SliderQuotes .slick-next.slick-prev:before, .SliderVideos .slick-prev:before,
.SliderVideos .slick-next.slick-prev:before {
  content: "\e903";
}
.Icon.icon-arrow-right:before, .Gallery .icon-arrow-right.slick-prev:before,
.Gallery .icon-arrow-right.slick-next:before, .SliderGuests .icon-arrow-right.slick-prev:before,
.SliderGuests .icon-arrow-right.slick-next:before, .SliderImages .icon-arrow-right.slick-prev:before,
.SliderImages .icon-arrow-right.slick-next:before, .SliderNumbers .icon-arrow-right.slick-prev:before,
.SliderNumbers .icon-arrow-right.slick-next:before, .SliderQuotes .icon-arrow-right.slick-prev:before,
.SliderQuotes .icon-arrow-right.slick-next:before, .SliderVideos .icon-arrow-right.slick-prev:before,
.SliderVideos .icon-arrow-right.slick-next:before, .Gallery .Icon.slick-next:before, .Gallery .slick-prev.slick-next:before,
.Gallery .slick-next:before, .SliderGuests .Gallery .slick-prev.slick-next:before, .Gallery .SliderGuests .slick-prev.slick-next:before,
.SliderGuests .Gallery .slick-next:before,
.Gallery .SliderGuests .slick-next:before, .SliderImages .Gallery .slick-prev.slick-next:before, .Gallery .SliderImages .slick-prev.slick-next:before,
.SliderImages .Gallery .slick-next:before,
.Gallery .SliderImages .slick-next:before, .SliderNumbers .Gallery .slick-prev.slick-next:before, .Gallery .SliderNumbers .slick-prev.slick-next:before,
.SliderNumbers .Gallery .slick-next:before,
.Gallery .SliderNumbers .slick-next:before, .SliderQuotes .Gallery .slick-prev.slick-next:before, .Gallery .SliderQuotes .slick-prev.slick-next:before,
.SliderQuotes .Gallery .slick-next:before,
.Gallery .SliderQuotes .slick-next:before, .SliderVideos .Gallery .slick-prev.slick-next:before, .Gallery .SliderVideos .slick-prev.slick-next:before,
.SliderVideos .Gallery .slick-next:before,
.Gallery .SliderVideos .slick-next:before, .SliderGuests .Icon.slick-next:before, .Gallery .SliderGuests .slick-prev.slick-next:before, .SliderGuests .Gallery .slick-prev.slick-next:before,
.Gallery .SliderGuests .slick-next:before,
.SliderGuests .Gallery .slick-next:before, .SliderGuests .slick-prev.slick-next:before,
.SliderGuests .slick-next:before, .SliderImages .SliderGuests .slick-prev.slick-next:before, .SliderGuests .SliderImages .slick-prev.slick-next:before,
.SliderImages .SliderGuests .slick-next:before,
.SliderGuests .SliderImages .slick-next:before, .SliderNumbers .SliderGuests .slick-prev.slick-next:before, .SliderGuests .SliderNumbers .slick-prev.slick-next:before,
.SliderNumbers .SliderGuests .slick-next:before,
.SliderGuests .SliderNumbers .slick-next:before, .SliderQuotes .SliderGuests .slick-prev.slick-next:before, .SliderGuests .SliderQuotes .slick-prev.slick-next:before,
.SliderQuotes .SliderGuests .slick-next:before,
.SliderGuests .SliderQuotes .slick-next:before, .SliderVideos .SliderGuests .slick-prev.slick-next:before, .SliderGuests .SliderVideos .slick-prev.slick-next:before,
.SliderVideos .SliderGuests .slick-next:before,
.SliderGuests .SliderVideos .slick-next:before, .SliderImages .Icon.slick-next:before, .Gallery .SliderImages .slick-prev.slick-next:before, .SliderImages .Gallery .slick-prev.slick-next:before,
.Gallery .SliderImages .slick-next:before,
.SliderImages .Gallery .slick-next:before, .SliderGuests .SliderImages .slick-prev.slick-next:before, .SliderImages .SliderGuests .slick-prev.slick-next:before,
.SliderGuests .SliderImages .slick-next:before,
.SliderImages .SliderGuests .slick-next:before, .SliderImages .slick-prev.slick-next:before,
.SliderImages .slick-next:before, .SliderNumbers .SliderImages .slick-prev.slick-next:before, .SliderImages .SliderNumbers .slick-prev.slick-next:before,
.SliderNumbers .SliderImages .slick-next:before,
.SliderImages .SliderNumbers .slick-next:before, .SliderQuotes .SliderImages .slick-prev.slick-next:before, .SliderImages .SliderQuotes .slick-prev.slick-next:before,
.SliderQuotes .SliderImages .slick-next:before,
.SliderImages .SliderQuotes .slick-next:before, .SliderVideos .SliderImages .slick-prev.slick-next:before, .SliderImages .SliderVideos .slick-prev.slick-next:before,
.SliderVideos .SliderImages .slick-next:before,
.SliderImages .SliderVideos .slick-next:before, .SliderNumbers .Icon.slick-next:before, .Gallery .SliderNumbers .slick-prev.slick-next:before, .SliderNumbers .Gallery .slick-prev.slick-next:before,
.Gallery .SliderNumbers .slick-next:before,
.SliderNumbers .Gallery .slick-next:before, .SliderGuests .SliderNumbers .slick-prev.slick-next:before, .SliderNumbers .SliderGuests .slick-prev.slick-next:before,
.SliderGuests .SliderNumbers .slick-next:before,
.SliderNumbers .SliderGuests .slick-next:before, .SliderImages .SliderNumbers .slick-prev.slick-next:before, .SliderNumbers .SliderImages .slick-prev.slick-next:before,
.SliderImages .SliderNumbers .slick-next:before,
.SliderNumbers .SliderImages .slick-next:before, .SliderNumbers .slick-prev.slick-next:before,
.SliderNumbers .slick-next:before, .SliderQuotes .SliderNumbers .slick-prev.slick-next:before, .SliderNumbers .SliderQuotes .slick-prev.slick-next:before,
.SliderQuotes .SliderNumbers .slick-next:before,
.SliderNumbers .SliderQuotes .slick-next:before, .SliderVideos .SliderNumbers .slick-prev.slick-next:before, .SliderNumbers .SliderVideos .slick-prev.slick-next:before,
.SliderVideos .SliderNumbers .slick-next:before,
.SliderNumbers .SliderVideos .slick-next:before, .SliderQuotes .Icon.slick-next:before, .Gallery .SliderQuotes .slick-prev.slick-next:before, .SliderQuotes .Gallery .slick-prev.slick-next:before,
.Gallery .SliderQuotes .slick-next:before,
.SliderQuotes .Gallery .slick-next:before, .SliderGuests .SliderQuotes .slick-prev.slick-next:before, .SliderQuotes .SliderGuests .slick-prev.slick-next:before,
.SliderGuests .SliderQuotes .slick-next:before,
.SliderQuotes .SliderGuests .slick-next:before, .SliderImages .SliderQuotes .slick-prev.slick-next:before, .SliderQuotes .SliderImages .slick-prev.slick-next:before,
.SliderImages .SliderQuotes .slick-next:before,
.SliderQuotes .SliderImages .slick-next:before, .SliderNumbers .SliderQuotes .slick-prev.slick-next:before, .SliderQuotes .SliderNumbers .slick-prev.slick-next:before,
.SliderNumbers .SliderQuotes .slick-next:before,
.SliderQuotes .SliderNumbers .slick-next:before, .SliderQuotes .slick-prev.slick-next:before,
.SliderQuotes .slick-next:before, .SliderVideos .SliderQuotes .slick-prev.slick-next:before, .SliderQuotes .SliderVideos .slick-prev.slick-next:before,
.SliderVideos .SliderQuotes .slick-next:before,
.SliderQuotes .SliderVideos .slick-next:before, .SliderVideos .Icon.slick-next:before, .Gallery .SliderVideos .slick-prev.slick-next:before, .SliderVideos .Gallery .slick-prev.slick-next:before,
.Gallery .SliderVideos .slick-next:before,
.SliderVideos .Gallery .slick-next:before, .SliderGuests .SliderVideos .slick-prev.slick-next:before, .SliderVideos .SliderGuests .slick-prev.slick-next:before,
.SliderGuests .SliderVideos .slick-next:before,
.SliderVideos .SliderGuests .slick-next:before, .SliderImages .SliderVideos .slick-prev.slick-next:before, .SliderVideos .SliderImages .slick-prev.slick-next:before,
.SliderImages .SliderVideos .slick-next:before,
.SliderVideos .SliderImages .slick-next:before, .SliderNumbers .SliderVideos .slick-prev.slick-next:before, .SliderVideos .SliderNumbers .slick-prev.slick-next:before,
.SliderNumbers .SliderVideos .slick-next:before,
.SliderVideos .SliderNumbers .slick-next:before, .SliderQuotes .SliderVideos .slick-prev.slick-next:before, .SliderVideos .SliderQuotes .slick-prev.slick-next:before,
.SliderQuotes .SliderVideos .slick-next:before,
.SliderVideos .SliderQuotes .slick-next:before, .SliderVideos .slick-prev.slick-next:before,
.SliderVideos .slick-next:before {
  content: "\e904";
}
.Icon.icon-quote:before, .Gallery .icon-quote.slick-prev:before,
.Gallery .icon-quote.slick-next:before, .SliderGuests .icon-quote.slick-prev:before,
.SliderGuests .icon-quote.slick-next:before, .SliderImages .icon-quote.slick-prev:before,
.SliderImages .icon-quote.slick-next:before, .SliderNumbers .icon-quote.slick-prev:before,
.SliderNumbers .icon-quote.slick-next:before, .SliderQuotes .icon-quote.slick-prev:before,
.SliderQuotes .icon-quote.slick-next:before, .SliderVideos .icon-quote.slick-prev:before,
.SliderVideos .icon-quote.slick-next:before {
  content: "\e905";
}
.Icon.icon-close:before, .Gallery .icon-close.slick-prev:before,
.Gallery .icon-close.slick-next:before, .SliderGuests .icon-close.slick-prev:before,
.SliderGuests .icon-close.slick-next:before, .SliderImages .icon-close.slick-prev:before,
.SliderImages .icon-close.slick-next:before, .SliderNumbers .icon-close.slick-prev:before,
.SliderNumbers .icon-close.slick-next:before, .SliderQuotes .icon-close.slick-prev:before,
.SliderQuotes .icon-close.slick-next:before, .SliderVideos .icon-close.slick-prev:before,
.SliderVideos .icon-close.slick-next:before {
  content: "\e906";
}
.Icon.icon-youtube:before, .Gallery .icon-youtube.slick-prev:before,
.Gallery .icon-youtube.slick-next:before, .SliderGuests .icon-youtube.slick-prev:before,
.SliderGuests .icon-youtube.slick-next:before, .SliderImages .icon-youtube.slick-prev:before,
.SliderImages .icon-youtube.slick-next:before, .SliderNumbers .icon-youtube.slick-prev:before,
.SliderNumbers .icon-youtube.slick-next:before, .SliderQuotes .icon-youtube.slick-prev:before,
.SliderQuotes .icon-youtube.slick-next:before, .SliderVideos .icon-youtube.slick-prev:before,
.SliderVideos .icon-youtube.slick-next:before {
  content: "\e907";
}
.Icon.icon-facebook:before, .Gallery .icon-facebook.slick-prev:before,
.Gallery .icon-facebook.slick-next:before, .SliderGuests .icon-facebook.slick-prev:before,
.SliderGuests .icon-facebook.slick-next:before, .SliderImages .icon-facebook.slick-prev:before,
.SliderImages .icon-facebook.slick-next:before, .SliderNumbers .icon-facebook.slick-prev:before,
.SliderNumbers .icon-facebook.slick-next:before, .SliderQuotes .icon-facebook.slick-prev:before,
.SliderQuotes .icon-facebook.slick-next:before, .SliderVideos .icon-facebook.slick-prev:before,
.SliderVideos .icon-facebook.slick-next:before {
  content: "\e908";
}
.Icon.icon-Instagram:before, .Gallery .icon-Instagram.slick-prev:before,
.Gallery .icon-Instagram.slick-next:before, .SliderGuests .icon-Instagram.slick-prev:before,
.SliderGuests .icon-Instagram.slick-next:before, .SliderImages .icon-Instagram.slick-prev:before,
.SliderImages .icon-Instagram.slick-next:before, .SliderNumbers .icon-Instagram.slick-prev:before,
.SliderNumbers .icon-Instagram.slick-next:before, .SliderQuotes .icon-Instagram.slick-prev:before,
.SliderQuotes .icon-Instagram.slick-next:before, .SliderVideos .icon-Instagram.slick-prev:before,
.SliderVideos .icon-Instagram.slick-next:before {
  content: "\e909";
}
.Icon.icon-plus:before, .Gallery .icon-plus.slick-prev:before,
.Gallery .icon-plus.slick-next:before, .SliderGuests .icon-plus.slick-prev:before,
.SliderGuests .icon-plus.slick-next:before, .SliderImages .icon-plus.slick-prev:before,
.SliderImages .icon-plus.slick-next:before, .SliderNumbers .icon-plus.slick-prev:before,
.SliderNumbers .icon-plus.slick-next:before, .SliderQuotes .icon-plus.slick-prev:before,
.SliderQuotes .icon-plus.slick-next:before, .SliderVideos .icon-plus.slick-prev:before,
.SliderVideos .icon-plus.slick-next:before {
  content: "\e90a";
}
.Icon.icon-search:before, .Gallery .icon-search.slick-prev:before,
.Gallery .icon-search.slick-next:before, .SliderGuests .icon-search.slick-prev:before,
.SliderGuests .icon-search.slick-next:before, .SliderImages .icon-search.slick-prev:before,
.SliderImages .icon-search.slick-next:before, .SliderNumbers .icon-search.slick-prev:before,
.SliderNumbers .icon-search.slick-next:before, .SliderQuotes .icon-search.slick-prev:before,
.SliderQuotes .icon-search.slick-next:before, .SliderVideos .icon-search.slick-prev:before,
.SliderVideos .icon-search.slick-next:before {
  content: "\e90b";
}
.Icon.icon-pin:before, .Gallery .icon-pin.slick-prev:before,
.Gallery .icon-pin.slick-next:before, .SliderGuests .icon-pin.slick-prev:before,
.SliderGuests .icon-pin.slick-next:before, .SliderImages .icon-pin.slick-prev:before,
.SliderImages .icon-pin.slick-next:before, .SliderNumbers .icon-pin.slick-prev:before,
.SliderNumbers .icon-pin.slick-next:before, .SliderQuotes .icon-pin.slick-prev:before,
.SliderQuotes .icon-pin.slick-next:before, .SliderVideos .icon-pin.slick-prev:before,
.SliderVideos .icon-pin.slick-next:before {
  content: "\e90c";
}
.Icon.icon-play:before, .Gallery .icon-play.slick-prev:before,
.Gallery .icon-play.slick-next:before, .SliderGuests .icon-play.slick-prev:before,
.SliderGuests .icon-play.slick-next:before, .SliderImages .icon-play.slick-prev:before,
.SliderImages .icon-play.slick-next:before, .SliderNumbers .icon-play.slick-prev:before,
.SliderNumbers .icon-play.slick-next:before, .SliderQuotes .icon-play.slick-prev:before,
.SliderQuotes .icon-play.slick-next:before, .SliderVideos .icon-play.slick-prev:before,
.SliderVideos .icon-play.slick-next:before {
  content: "\e90d";
}
.Icon.icon-phone:before, .Gallery .icon-phone.slick-prev:before,
.Gallery .icon-phone.slick-next:before, .SliderGuests .icon-phone.slick-prev:before,
.SliderGuests .icon-phone.slick-next:before, .SliderImages .icon-phone.slick-prev:before,
.SliderImages .icon-phone.slick-next:before, .SliderNumbers .icon-phone.slick-prev:before,
.SliderNumbers .icon-phone.slick-next:before, .SliderQuotes .icon-phone.slick-prev:before,
.SliderQuotes .icon-phone.slick-next:before, .SliderVideos .icon-phone.slick-prev:before,
.SliderVideos .icon-phone.slick-next:before {
  content: "\e90e";
}
.Icon.icon-mail:before, .Gallery .icon-mail.slick-prev:before,
.Gallery .icon-mail.slick-next:before, .SliderGuests .icon-mail.slick-prev:before,
.SliderGuests .icon-mail.slick-next:before, .SliderImages .icon-mail.slick-prev:before,
.SliderImages .icon-mail.slick-next:before, .SliderNumbers .icon-mail.slick-prev:before,
.SliderNumbers .icon-mail.slick-next:before, .SliderQuotes .icon-mail.slick-prev:before,
.SliderQuotes .icon-mail.slick-next:before, .SliderVideos .icon-mail.slick-prev:before,
.SliderVideos .icon-mail.slick-next:before {
  content: "\e90f";
}
.Icon.icon-user:before, .Gallery .icon-user.slick-prev:before,
.Gallery .icon-user.slick-next:before, .SliderGuests .icon-user.slick-prev:before,
.SliderGuests .icon-user.slick-next:before, .SliderImages .icon-user.slick-prev:before,
.SliderImages .icon-user.slick-next:before, .SliderNumbers .icon-user.slick-prev:before,
.SliderNumbers .icon-user.slick-next:before, .SliderQuotes .icon-user.slick-prev:before,
.SliderQuotes .icon-user.slick-next:before, .SliderVideos .icon-user.slick-prev:before,
.SliderVideos .icon-user.slick-next:before {
  content: "\e910";
}
.Icon.icon-minus:before, .Gallery .icon-minus.slick-prev:before,
.Gallery .icon-minus.slick-next:before, .SliderGuests .icon-minus.slick-prev:before,
.SliderGuests .icon-minus.slick-next:before, .SliderImages .icon-minus.slick-prev:before,
.SliderImages .icon-minus.slick-next:before, .SliderNumbers .icon-minus.slick-prev:before,
.SliderNumbers .icon-minus.slick-next:before, .SliderQuotes .icon-minus.slick-prev:before,
.SliderQuotes .icon-minus.slick-next:before, .SliderVideos .icon-minus.slick-prev:before,
.SliderVideos .icon-minus.slick-next:before {
  content: "\e911";
}
.Icon.icon-check-big:before, .Gallery .icon-check-big.slick-prev:before,
.Gallery .icon-check-big.slick-next:before, .SliderGuests .icon-check-big.slick-prev:before,
.SliderGuests .icon-check-big.slick-next:before, .SliderImages .icon-check-big.slick-prev:before,
.SliderImages .icon-check-big.slick-next:before, .SliderNumbers .icon-check-big.slick-prev:before,
.SliderNumbers .icon-check-big.slick-next:before, .SliderQuotes .icon-check-big.slick-prev:before,
.SliderQuotes .icon-check-big.slick-next:before, .SliderVideos .icon-check-big.slick-prev:before,
.SliderVideos .icon-check-big.slick-next:before {
  content: "\e912";
}
.Icon.icon-check-small:before, .Gallery .icon-check-small.slick-prev:before,
.Gallery .icon-check-small.slick-next:before, .SliderGuests .icon-check-small.slick-prev:before,
.SliderGuests .icon-check-small.slick-next:before, .SliderImages .icon-check-small.slick-prev:before,
.SliderImages .icon-check-small.slick-next:before, .SliderNumbers .icon-check-small.slick-prev:before,
.SliderNumbers .icon-check-small.slick-next:before, .SliderQuotes .icon-check-small.slick-prev:before,
.SliderQuotes .icon-check-small.slick-next:before, .SliderVideos .icon-check-small.slick-prev:before,
.SliderVideos .icon-check-small.slick-next:before {
  content: "\e913";
}
.Icon.icon-spotify:before, .Gallery .icon-spotify.slick-prev:before,
.Gallery .icon-spotify.slick-next:before, .SliderGuests .icon-spotify.slick-prev:before,
.SliderGuests .icon-spotify.slick-next:before, .SliderImages .icon-spotify.slick-prev:before,
.SliderImages .icon-spotify.slick-next:before, .SliderNumbers .icon-spotify.slick-prev:before,
.SliderNumbers .icon-spotify.slick-next:before, .SliderQuotes .icon-spotify.slick-prev:before,
.SliderQuotes .icon-spotify.slick-next:before, .SliderVideos .icon-spotify.slick-prev:before,
.SliderVideos .icon-spotify.slick-next:before {
  content: "\e914";
}
.Icon.icon-kalender:before, .Gallery .icon-kalender.slick-prev:before,
.Gallery .icon-kalender.slick-next:before, .SliderGuests .icon-kalender.slick-prev:before,
.SliderGuests .icon-kalender.slick-next:before, .SliderImages .icon-kalender.slick-prev:before,
.SliderImages .icon-kalender.slick-next:before, .SliderNumbers .icon-kalender.slick-prev:before,
.SliderNumbers .icon-kalender.slick-next:before, .SliderQuotes .icon-kalender.slick-prev:before,
.SliderQuotes .icon-kalender.slick-next:before, .SliderVideos .icon-kalender.slick-prev:before,
.SliderVideos .icon-kalender.slick-next:before {
  content: "\e915";
}
.Icon.icon-list:before, .Gallery .icon-list.slick-prev:before,
.Gallery .icon-list.slick-next:before, .SliderGuests .icon-list.slick-prev:before,
.SliderGuests .icon-list.slick-next:before, .SliderImages .icon-list.slick-prev:before,
.SliderImages .icon-list.slick-next:before, .SliderNumbers .icon-list.slick-prev:before,
.SliderNumbers .icon-list.slick-next:before, .SliderQuotes .icon-list.slick-prev:before,
.SliderQuotes .icon-list.slick-next:before, .SliderVideos .icon-list.slick-prev:before,
.SliderVideos .icon-list.slick-next:before {
  content: "\e916";
}

/* 112: /app/components/Ui/Inner/Inner.scss */
.Inner {
  padding: 0 10px;
  transition: background 300ms ease-in-out;
}
@media screen and (min-width: 992px) {
  .Inner {
    padding: 0 20px;
  }
}
.Inner.noPad {
  padding: 0;
}
.Inner.bg-light {
  background: #ffffff;
}
.Inner.bg-brandLight {
  background: #fff5f3;
}
.Inner.bg-dark {
  background: #000000;
}
.Inner.bg-transparent {
  background: rgba(0, 0, 0, 0.15);
}

/* 113: /app/components/Ui/LightBox/LightBox.scss */
.LightBox {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  position: fixed;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
}
.LightBox .lb-close-trigger {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  z-index: 1001;
}
.LightBox .lb-button {
  position: absolute;
  top: 50%;
  font-size: 22px;
  cursor: pointer;
  z-index: 1002;
  transform: translate3d(0, -50%, 0);
}
.LightBox .lb-button.next {
  right: 10px;
}
@media screen and (min-width: 992px) {
  .LightBox .lb-button.next {
    right: 20px;
  }
}
.LightBox .lb-button.prev {
  left: 10px;
}
@media screen and (min-width: 992px) {
  .LightBox .lb-button.prev {
    left: 20px;
  }
}
.LightBox .lb-button.close {
  top: 20px;
  right: 20px;
}
.LightBox .lb-image {
  position: relative;
  z-index: 1003;
  width: 75vw;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.LightBox .lb-image .Picture {
  justify-content: center;
  height: auto !important;
}
.LightBox .lb-image img {
  max-width: 100% !important;
  max-height: 100% !important;
  height: auto !important;
  width: auto !important;
}
.LightBox .lb-download {
  position: absolute;
  bottom: 20px;
  z-index: 1003;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

/* 114: /app/components/Ui/Modal/Modal.scss */
.Modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.Modal .md-close {
  position: absolute;
  top: 14px;
  right: 14px;
  z-index: 1003;
}
.Modal .md-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.Modal .md-content > div {
  position: relative;
  z-index: 1002;
}
.Modal .md-inner {
  background: #ffffff;
  border-radius: 16px;
  overflow: scroll;
  max-height: 90vh;
}
@media screen and (min-width: 992px) {
  .Modal .md-inner {
    max-height: 75vh;
  }
}
.Modal .md-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
}

/* 115: /app/components/Ui/Picture/Picture.scss */
.Picture {
  width: 100%;
  height: 100%;
  display: flex;
}
.Picture .Image {
  display: block;
  width: 100%;
}
.Picture .Image.cover {
  object-fit: cover;
  height: auto;
  width: 100%;
}

/* 116: /app/components/Ui/Spacer/Spacer.scss */
.sv-1 {
  height: 2px;
  width: 100%;
}
.sv-1.debug {
  background: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .sv-1\@xs {
    height: 2px;
    width: 100%;
  }
  .sv-1\@xs.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 568px) {
  .sv-1\@s {
    height: 2px;
    width: 100%;
  }
  .sv-1\@s.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 769px) {
  .sv-1\@sm {
    height: 2px;
    width: 100%;
  }
  .sv-1\@sm.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 992px) {
  .sv-1\@md {
    height: 2px;
    width: 100%;
  }
  .sv-1\@md.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .sv-1\@lg {
    height: 2px;
    width: 100%;
  }
  .sv-1\@lg.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1681px) {
  .sv-1\@xl {
    height: 2px;
    width: 100%;
  }
  .sv-1\@xl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 2000px) {
  .sv-1\@xxl {
    height: 2px;
    width: 100%;
  }
  .sv-1\@xxl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sh-1 {
  width: 2px;
  height: 100%;
}
.sh-1.debug {
  background: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .sh-1\@xs {
    width: 2px;
    height: 100%;
  }
  .sh-1\@xs.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 568px) {
  .sh-1\@s {
    width: 2px;
    height: 100%;
  }
  .sh-1\@s.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 769px) {
  .sh-1\@sm {
    width: 2px;
    height: 100%;
  }
  .sh-1\@sm.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 992px) {
  .sh-1\@md {
    width: 2px;
    height: 100%;
  }
  .sh-1\@md.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .sh-1\@lg {
    width: 2px;
    height: 100%;
  }
  .sh-1\@lg.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1681px) {
  .sh-1\@xl {
    width: 2px;
    height: 100%;
  }
  .sh-1\@xl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 2000px) {
  .sh-1\@xxl {
    width: 2px;
    height: 100%;
  }
  .sh-1\@xxl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sv-2 {
  height: 4px;
  width: 100%;
}
.sv-2.debug {
  background: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .sv-2\@xs {
    height: 4px;
    width: 100%;
  }
  .sv-2\@xs.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 568px) {
  .sv-2\@s {
    height: 4px;
    width: 100%;
  }
  .sv-2\@s.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 769px) {
  .sv-2\@sm {
    height: 4px;
    width: 100%;
  }
  .sv-2\@sm.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 992px) {
  .sv-2\@md {
    height: 4px;
    width: 100%;
  }
  .sv-2\@md.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .sv-2\@lg {
    height: 4px;
    width: 100%;
  }
  .sv-2\@lg.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1681px) {
  .sv-2\@xl {
    height: 4px;
    width: 100%;
  }
  .sv-2\@xl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 2000px) {
  .sv-2\@xxl {
    height: 4px;
    width: 100%;
  }
  .sv-2\@xxl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sh-2 {
  width: 4px;
  height: 100%;
}
.sh-2.debug {
  background: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .sh-2\@xs {
    width: 4px;
    height: 100%;
  }
  .sh-2\@xs.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 568px) {
  .sh-2\@s {
    width: 4px;
    height: 100%;
  }
  .sh-2\@s.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 769px) {
  .sh-2\@sm {
    width: 4px;
    height: 100%;
  }
  .sh-2\@sm.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 992px) {
  .sh-2\@md {
    width: 4px;
    height: 100%;
  }
  .sh-2\@md.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .sh-2\@lg {
    width: 4px;
    height: 100%;
  }
  .sh-2\@lg.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1681px) {
  .sh-2\@xl {
    width: 4px;
    height: 100%;
  }
  .sh-2\@xl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 2000px) {
  .sh-2\@xxl {
    width: 4px;
    height: 100%;
  }
  .sh-2\@xxl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sv-3 {
  height: 6px;
  width: 100%;
}
.sv-3.debug {
  background: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .sv-3\@xs {
    height: 6px;
    width: 100%;
  }
  .sv-3\@xs.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 568px) {
  .sv-3\@s {
    height: 6px;
    width: 100%;
  }
  .sv-3\@s.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 769px) {
  .sv-3\@sm {
    height: 6px;
    width: 100%;
  }
  .sv-3\@sm.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 992px) {
  .sv-3\@md {
    height: 6px;
    width: 100%;
  }
  .sv-3\@md.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .sv-3\@lg {
    height: 6px;
    width: 100%;
  }
  .sv-3\@lg.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1681px) {
  .sv-3\@xl {
    height: 6px;
    width: 100%;
  }
  .sv-3\@xl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 2000px) {
  .sv-3\@xxl {
    height: 6px;
    width: 100%;
  }
  .sv-3\@xxl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sh-3 {
  width: 6px;
  height: 100%;
}
.sh-3.debug {
  background: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .sh-3\@xs {
    width: 6px;
    height: 100%;
  }
  .sh-3\@xs.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 568px) {
  .sh-3\@s {
    width: 6px;
    height: 100%;
  }
  .sh-3\@s.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 769px) {
  .sh-3\@sm {
    width: 6px;
    height: 100%;
  }
  .sh-3\@sm.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 992px) {
  .sh-3\@md {
    width: 6px;
    height: 100%;
  }
  .sh-3\@md.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .sh-3\@lg {
    width: 6px;
    height: 100%;
  }
  .sh-3\@lg.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1681px) {
  .sh-3\@xl {
    width: 6px;
    height: 100%;
  }
  .sh-3\@xl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 2000px) {
  .sh-3\@xxl {
    width: 6px;
    height: 100%;
  }
  .sh-3\@xxl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sv-4 {
  height: 8px;
  width: 100%;
}
.sv-4.debug {
  background: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .sv-4\@xs {
    height: 8px;
    width: 100%;
  }
  .sv-4\@xs.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 568px) {
  .sv-4\@s {
    height: 8px;
    width: 100%;
  }
  .sv-4\@s.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 769px) {
  .sv-4\@sm {
    height: 8px;
    width: 100%;
  }
  .sv-4\@sm.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 992px) {
  .sv-4\@md {
    height: 8px;
    width: 100%;
  }
  .sv-4\@md.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .sv-4\@lg {
    height: 8px;
    width: 100%;
  }
  .sv-4\@lg.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1681px) {
  .sv-4\@xl {
    height: 8px;
    width: 100%;
  }
  .sv-4\@xl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 2000px) {
  .sv-4\@xxl {
    height: 8px;
    width: 100%;
  }
  .sv-4\@xxl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sh-4 {
  width: 8px;
  height: 100%;
}
.sh-4.debug {
  background: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .sh-4\@xs {
    width: 8px;
    height: 100%;
  }
  .sh-4\@xs.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 568px) {
  .sh-4\@s {
    width: 8px;
    height: 100%;
  }
  .sh-4\@s.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 769px) {
  .sh-4\@sm {
    width: 8px;
    height: 100%;
  }
  .sh-4\@sm.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 992px) {
  .sh-4\@md {
    width: 8px;
    height: 100%;
  }
  .sh-4\@md.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .sh-4\@lg {
    width: 8px;
    height: 100%;
  }
  .sh-4\@lg.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1681px) {
  .sh-4\@xl {
    width: 8px;
    height: 100%;
  }
  .sh-4\@xl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 2000px) {
  .sh-4\@xxl {
    width: 8px;
    height: 100%;
  }
  .sh-4\@xxl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sv-5 {
  height: 10px;
  width: 100%;
}
.sv-5.debug {
  background: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .sv-5\@xs {
    height: 10px;
    width: 100%;
  }
  .sv-5\@xs.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 568px) {
  .sv-5\@s {
    height: 10px;
    width: 100%;
  }
  .sv-5\@s.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 769px) {
  .sv-5\@sm {
    height: 10px;
    width: 100%;
  }
  .sv-5\@sm.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 992px) {
  .sv-5\@md {
    height: 10px;
    width: 100%;
  }
  .sv-5\@md.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .sv-5\@lg {
    height: 10px;
    width: 100%;
  }
  .sv-5\@lg.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1681px) {
  .sv-5\@xl {
    height: 10px;
    width: 100%;
  }
  .sv-5\@xl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 2000px) {
  .sv-5\@xxl {
    height: 10px;
    width: 100%;
  }
  .sv-5\@xxl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sh-5 {
  width: 10px;
  height: 100%;
}
.sh-5.debug {
  background: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .sh-5\@xs {
    width: 10px;
    height: 100%;
  }
  .sh-5\@xs.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 568px) {
  .sh-5\@s {
    width: 10px;
    height: 100%;
  }
  .sh-5\@s.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 769px) {
  .sh-5\@sm {
    width: 10px;
    height: 100%;
  }
  .sh-5\@sm.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 992px) {
  .sh-5\@md {
    width: 10px;
    height: 100%;
  }
  .sh-5\@md.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .sh-5\@lg {
    width: 10px;
    height: 100%;
  }
  .sh-5\@lg.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1681px) {
  .sh-5\@xl {
    width: 10px;
    height: 100%;
  }
  .sh-5\@xl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 2000px) {
  .sh-5\@xxl {
    width: 10px;
    height: 100%;
  }
  .sh-5\@xxl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sv-6 {
  height: 12px;
  width: 100%;
}
.sv-6.debug {
  background: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .sv-6\@xs {
    height: 12px;
    width: 100%;
  }
  .sv-6\@xs.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 568px) {
  .sv-6\@s {
    height: 12px;
    width: 100%;
  }
  .sv-6\@s.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 769px) {
  .sv-6\@sm {
    height: 12px;
    width: 100%;
  }
  .sv-6\@sm.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 992px) {
  .sv-6\@md {
    height: 12px;
    width: 100%;
  }
  .sv-6\@md.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .sv-6\@lg {
    height: 12px;
    width: 100%;
  }
  .sv-6\@lg.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1681px) {
  .sv-6\@xl {
    height: 12px;
    width: 100%;
  }
  .sv-6\@xl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 2000px) {
  .sv-6\@xxl {
    height: 12px;
    width: 100%;
  }
  .sv-6\@xxl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sh-6 {
  width: 12px;
  height: 100%;
}
.sh-6.debug {
  background: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .sh-6\@xs {
    width: 12px;
    height: 100%;
  }
  .sh-6\@xs.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 568px) {
  .sh-6\@s {
    width: 12px;
    height: 100%;
  }
  .sh-6\@s.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 769px) {
  .sh-6\@sm {
    width: 12px;
    height: 100%;
  }
  .sh-6\@sm.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 992px) {
  .sh-6\@md {
    width: 12px;
    height: 100%;
  }
  .sh-6\@md.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .sh-6\@lg {
    width: 12px;
    height: 100%;
  }
  .sh-6\@lg.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1681px) {
  .sh-6\@xl {
    width: 12px;
    height: 100%;
  }
  .sh-6\@xl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 2000px) {
  .sh-6\@xxl {
    width: 12px;
    height: 100%;
  }
  .sh-6\@xxl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sv-7 {
  height: 14px;
  width: 100%;
}
.sv-7.debug {
  background: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .sv-7\@xs {
    height: 14px;
    width: 100%;
  }
  .sv-7\@xs.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 568px) {
  .sv-7\@s {
    height: 14px;
    width: 100%;
  }
  .sv-7\@s.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 769px) {
  .sv-7\@sm {
    height: 14px;
    width: 100%;
  }
  .sv-7\@sm.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 992px) {
  .sv-7\@md {
    height: 14px;
    width: 100%;
  }
  .sv-7\@md.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .sv-7\@lg {
    height: 14px;
    width: 100%;
  }
  .sv-7\@lg.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1681px) {
  .sv-7\@xl {
    height: 14px;
    width: 100%;
  }
  .sv-7\@xl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 2000px) {
  .sv-7\@xxl {
    height: 14px;
    width: 100%;
  }
  .sv-7\@xxl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sh-7 {
  width: 14px;
  height: 100%;
}
.sh-7.debug {
  background: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .sh-7\@xs {
    width: 14px;
    height: 100%;
  }
  .sh-7\@xs.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 568px) {
  .sh-7\@s {
    width: 14px;
    height: 100%;
  }
  .sh-7\@s.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 769px) {
  .sh-7\@sm {
    width: 14px;
    height: 100%;
  }
  .sh-7\@sm.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 992px) {
  .sh-7\@md {
    width: 14px;
    height: 100%;
  }
  .sh-7\@md.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .sh-7\@lg {
    width: 14px;
    height: 100%;
  }
  .sh-7\@lg.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1681px) {
  .sh-7\@xl {
    width: 14px;
    height: 100%;
  }
  .sh-7\@xl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 2000px) {
  .sh-7\@xxl {
    width: 14px;
    height: 100%;
  }
  .sh-7\@xxl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sv-10 {
  height: 20px;
  width: 100%;
}
.sv-10.debug {
  background: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .sv-10\@xs {
    height: 20px;
    width: 100%;
  }
  .sv-10\@xs.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 568px) {
  .sv-10\@s {
    height: 20px;
    width: 100%;
  }
  .sv-10\@s.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 769px) {
  .sv-10\@sm {
    height: 20px;
    width: 100%;
  }
  .sv-10\@sm.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 992px) {
  .sv-10\@md {
    height: 20px;
    width: 100%;
  }
  .sv-10\@md.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .sv-10\@lg {
    height: 20px;
    width: 100%;
  }
  .sv-10\@lg.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1681px) {
  .sv-10\@xl {
    height: 20px;
    width: 100%;
  }
  .sv-10\@xl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 2000px) {
  .sv-10\@xxl {
    height: 20px;
    width: 100%;
  }
  .sv-10\@xxl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sh-10 {
  width: 20px;
  height: 100%;
}
.sh-10.debug {
  background: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .sh-10\@xs {
    width: 20px;
    height: 100%;
  }
  .sh-10\@xs.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 568px) {
  .sh-10\@s {
    width: 20px;
    height: 100%;
  }
  .sh-10\@s.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 769px) {
  .sh-10\@sm {
    width: 20px;
    height: 100%;
  }
  .sh-10\@sm.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 992px) {
  .sh-10\@md {
    width: 20px;
    height: 100%;
  }
  .sh-10\@md.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .sh-10\@lg {
    width: 20px;
    height: 100%;
  }
  .sh-10\@lg.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1681px) {
  .sh-10\@xl {
    width: 20px;
    height: 100%;
  }
  .sh-10\@xl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 2000px) {
  .sh-10\@xxl {
    width: 20px;
    height: 100%;
  }
  .sh-10\@xxl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sv-15 {
  height: 30px;
  width: 100%;
}
.sv-15.debug {
  background: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .sv-15\@xs {
    height: 30px;
    width: 100%;
  }
  .sv-15\@xs.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 568px) {
  .sv-15\@s {
    height: 30px;
    width: 100%;
  }
  .sv-15\@s.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 769px) {
  .sv-15\@sm {
    height: 30px;
    width: 100%;
  }
  .sv-15\@sm.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 992px) {
  .sv-15\@md {
    height: 30px;
    width: 100%;
  }
  .sv-15\@md.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .sv-15\@lg {
    height: 30px;
    width: 100%;
  }
  .sv-15\@lg.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1681px) {
  .sv-15\@xl {
    height: 30px;
    width: 100%;
  }
  .sv-15\@xl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 2000px) {
  .sv-15\@xxl {
    height: 30px;
    width: 100%;
  }
  .sv-15\@xxl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sh-15 {
  width: 30px;
  height: 100%;
}
.sh-15.debug {
  background: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .sh-15\@xs {
    width: 30px;
    height: 100%;
  }
  .sh-15\@xs.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 568px) {
  .sh-15\@s {
    width: 30px;
    height: 100%;
  }
  .sh-15\@s.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 769px) {
  .sh-15\@sm {
    width: 30px;
    height: 100%;
  }
  .sh-15\@sm.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 992px) {
  .sh-15\@md {
    width: 30px;
    height: 100%;
  }
  .sh-15\@md.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .sh-15\@lg {
    width: 30px;
    height: 100%;
  }
  .sh-15\@lg.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1681px) {
  .sh-15\@xl {
    width: 30px;
    height: 100%;
  }
  .sh-15\@xl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 2000px) {
  .sh-15\@xxl {
    width: 30px;
    height: 100%;
  }
  .sh-15\@xxl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sv-20 {
  height: 40px;
  width: 100%;
}
.sv-20.debug {
  background: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .sv-20\@xs {
    height: 40px;
    width: 100%;
  }
  .sv-20\@xs.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 568px) {
  .sv-20\@s {
    height: 40px;
    width: 100%;
  }
  .sv-20\@s.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 769px) {
  .sv-20\@sm {
    height: 40px;
    width: 100%;
  }
  .sv-20\@sm.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 992px) {
  .sv-20\@md {
    height: 40px;
    width: 100%;
  }
  .sv-20\@md.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .sv-20\@lg {
    height: 40px;
    width: 100%;
  }
  .sv-20\@lg.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1681px) {
  .sv-20\@xl {
    height: 40px;
    width: 100%;
  }
  .sv-20\@xl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 2000px) {
  .sv-20\@xxl {
    height: 40px;
    width: 100%;
  }
  .sv-20\@xxl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sh-20 {
  width: 40px;
  height: 100%;
}
.sh-20.debug {
  background: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .sh-20\@xs {
    width: 40px;
    height: 100%;
  }
  .sh-20\@xs.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 568px) {
  .sh-20\@s {
    width: 40px;
    height: 100%;
  }
  .sh-20\@s.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 769px) {
  .sh-20\@sm {
    width: 40px;
    height: 100%;
  }
  .sh-20\@sm.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 992px) {
  .sh-20\@md {
    width: 40px;
    height: 100%;
  }
  .sh-20\@md.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .sh-20\@lg {
    width: 40px;
    height: 100%;
  }
  .sh-20\@lg.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1681px) {
  .sh-20\@xl {
    width: 40px;
    height: 100%;
  }
  .sh-20\@xl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 2000px) {
  .sh-20\@xxl {
    width: 40px;
    height: 100%;
  }
  .sh-20\@xxl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sv-30 {
  height: 60px;
  width: 100%;
}
.sv-30.debug {
  background: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .sv-30\@xs {
    height: 60px;
    width: 100%;
  }
  .sv-30\@xs.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 568px) {
  .sv-30\@s {
    height: 60px;
    width: 100%;
  }
  .sv-30\@s.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 769px) {
  .sv-30\@sm {
    height: 60px;
    width: 100%;
  }
  .sv-30\@sm.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 992px) {
  .sv-30\@md {
    height: 60px;
    width: 100%;
  }
  .sv-30\@md.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .sv-30\@lg {
    height: 60px;
    width: 100%;
  }
  .sv-30\@lg.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1681px) {
  .sv-30\@xl {
    height: 60px;
    width: 100%;
  }
  .sv-30\@xl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 2000px) {
  .sv-30\@xxl {
    height: 60px;
    width: 100%;
  }
  .sv-30\@xxl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sh-30 {
  width: 60px;
  height: 100%;
}
.sh-30.debug {
  background: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .sh-30\@xs {
    width: 60px;
    height: 100%;
  }
  .sh-30\@xs.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 568px) {
  .sh-30\@s {
    width: 60px;
    height: 100%;
  }
  .sh-30\@s.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 769px) {
  .sh-30\@sm {
    width: 60px;
    height: 100%;
  }
  .sh-30\@sm.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 992px) {
  .sh-30\@md {
    width: 60px;
    height: 100%;
  }
  .sh-30\@md.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .sh-30\@lg {
    width: 60px;
    height: 100%;
  }
  .sh-30\@lg.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1681px) {
  .sh-30\@xl {
    width: 60px;
    height: 100%;
  }
  .sh-30\@xl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 2000px) {
  .sh-30\@xxl {
    width: 60px;
    height: 100%;
  }
  .sh-30\@xxl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sv-40 {
  height: 80px;
  width: 100%;
}
.sv-40.debug {
  background: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .sv-40\@xs {
    height: 80px;
    width: 100%;
  }
  .sv-40\@xs.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 568px) {
  .sv-40\@s {
    height: 80px;
    width: 100%;
  }
  .sv-40\@s.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 769px) {
  .sv-40\@sm {
    height: 80px;
    width: 100%;
  }
  .sv-40\@sm.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 992px) {
  .sv-40\@md {
    height: 80px;
    width: 100%;
  }
  .sv-40\@md.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .sv-40\@lg {
    height: 80px;
    width: 100%;
  }
  .sv-40\@lg.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1681px) {
  .sv-40\@xl {
    height: 80px;
    width: 100%;
  }
  .sv-40\@xl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 2000px) {
  .sv-40\@xxl {
    height: 80px;
    width: 100%;
  }
  .sv-40\@xxl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sh-40 {
  width: 80px;
  height: 100%;
}
.sh-40.debug {
  background: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .sh-40\@xs {
    width: 80px;
    height: 100%;
  }
  .sh-40\@xs.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 568px) {
  .sh-40\@s {
    width: 80px;
    height: 100%;
  }
  .sh-40\@s.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 769px) {
  .sh-40\@sm {
    width: 80px;
    height: 100%;
  }
  .sh-40\@sm.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 992px) {
  .sh-40\@md {
    width: 80px;
    height: 100%;
  }
  .sh-40\@md.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .sh-40\@lg {
    width: 80px;
    height: 100%;
  }
  .sh-40\@lg.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1681px) {
  .sh-40\@xl {
    width: 80px;
    height: 100%;
  }
  .sh-40\@xl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 2000px) {
  .sh-40\@xxl {
    width: 80px;
    height: 100%;
  }
  .sh-40\@xxl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sv-60 {
  height: 120px;
  width: 100%;
}
.sv-60.debug {
  background: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .sv-60\@xs {
    height: 120px;
    width: 100%;
  }
  .sv-60\@xs.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 568px) {
  .sv-60\@s {
    height: 120px;
    width: 100%;
  }
  .sv-60\@s.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 769px) {
  .sv-60\@sm {
    height: 120px;
    width: 100%;
  }
  .sv-60\@sm.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 992px) {
  .sv-60\@md {
    height: 120px;
    width: 100%;
  }
  .sv-60\@md.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .sv-60\@lg {
    height: 120px;
    width: 100%;
  }
  .sv-60\@lg.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1681px) {
  .sv-60\@xl {
    height: 120px;
    width: 100%;
  }
  .sv-60\@xl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 2000px) {
  .sv-60\@xxl {
    height: 120px;
    width: 100%;
  }
  .sv-60\@xxl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sh-60 {
  width: 120px;
  height: 100%;
}
.sh-60.debug {
  background: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .sh-60\@xs {
    width: 120px;
    height: 100%;
  }
  .sh-60\@xs.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 568px) {
  .sh-60\@s {
    width: 120px;
    height: 100%;
  }
  .sh-60\@s.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 769px) {
  .sh-60\@sm {
    width: 120px;
    height: 100%;
  }
  .sh-60\@sm.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 992px) {
  .sh-60\@md {
    width: 120px;
    height: 100%;
  }
  .sh-60\@md.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .sh-60\@lg {
    width: 120px;
    height: 100%;
  }
  .sh-60\@lg.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1681px) {
  .sh-60\@xl {
    width: 120px;
    height: 100%;
  }
  .sh-60\@xl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 2000px) {
  .sh-60\@xxl {
    width: 120px;
    height: 100%;
  }
  .sh-60\@xxl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sv-80 {
  height: 160px;
  width: 100%;
}
.sv-80.debug {
  background: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .sv-80\@xs {
    height: 160px;
    width: 100%;
  }
  .sv-80\@xs.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 568px) {
  .sv-80\@s {
    height: 160px;
    width: 100%;
  }
  .sv-80\@s.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 769px) {
  .sv-80\@sm {
    height: 160px;
    width: 100%;
  }
  .sv-80\@sm.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 992px) {
  .sv-80\@md {
    height: 160px;
    width: 100%;
  }
  .sv-80\@md.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .sv-80\@lg {
    height: 160px;
    width: 100%;
  }
  .sv-80\@lg.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1681px) {
  .sv-80\@xl {
    height: 160px;
    width: 100%;
  }
  .sv-80\@xl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 2000px) {
  .sv-80\@xxl {
    height: 160px;
    width: 100%;
  }
  .sv-80\@xxl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sh-80 {
  width: 160px;
  height: 100%;
}
.sh-80.debug {
  background: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .sh-80\@xs {
    width: 160px;
    height: 100%;
  }
  .sh-80\@xs.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 568px) {
  .sh-80\@s {
    width: 160px;
    height: 100%;
  }
  .sh-80\@s.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 769px) {
  .sh-80\@sm {
    width: 160px;
    height: 100%;
  }
  .sh-80\@sm.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 992px) {
  .sh-80\@md {
    width: 160px;
    height: 100%;
  }
  .sh-80\@md.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .sh-80\@lg {
    width: 160px;
    height: 100%;
  }
  .sh-80\@lg.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1681px) {
  .sh-80\@xl {
    width: 160px;
    height: 100%;
  }
  .sh-80\@xl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 2000px) {
  .sh-80\@xxl {
    width: 160px;
    height: 100%;
  }
  .sh-80\@xxl.debug {
    background: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 767px) {
  .sh-0\@xs {
    height: 100%;
    width: 0;
  }
  .sv-0\@xs {
    height: 0;
    width: 100%;
  }
}
@media screen and (min-width: 568px) {
  .sh-0\@s {
    height: 100%;
    width: 0;
  }
  .sv-0\@s {
    height: 0;
    width: 100%;
  }
}
@media screen and (min-width: 769px) {
  .sh-0\@sm {
    height: 100%;
    width: 0;
  }
  .sv-0\@sm {
    height: 0;
    width: 100%;
  }
}
@media screen and (min-width: 992px) {
  .sh-0\@md {
    height: 100%;
    width: 0;
  }
  .sv-0\@md {
    height: 0;
    width: 100%;
  }
}
@media screen and (min-width: 1200px) {
  .sh-0\@lg {
    height: 100%;
    width: 0;
  }
  .sv-0\@lg {
    height: 0;
    width: 100%;
  }
}
@media screen and (min-width: 1681px) {
  .sh-0\@xl {
    height: 100%;
    width: 0;
  }
  .sv-0\@xl {
    height: 0;
    width: 100%;
  }
}
@media screen and (min-width: 2000px) {
  .sh-0\@xxl {
    height: 100%;
    width: 0;
  }
  .sv-0\@xxl {
    height: 0;
    width: 100%;
  }
}
/* 117: /app/components/Ui/Text/Debug/Debug.scss */
.Debug-Text {
  padding: 20px;
}
.Debug-Text .block {
  padding: 10px 0;
  border-bottom: 1px solid black;
}

/* 118: /app/components/Ui/Text/Text.mixins.scss */
.Text.type-headline-hero {
  font-family: RelativePro-Medium;
  letter-spacing: 0;
  font-size: 67px;
  line-height: 73px;
}
@media screen and (min-width: 992px) {
  .Text.type-headline-hero {
    font-size: 132px;
    line-height: 132px;
  }
}

.Typo h1, .Text.type-headline-1 {
  font-family: RelativePro-Medium;
  letter-spacing: 0;
  font-size: 50px;
  line-height: 60px;
}
@media screen and (min-width: 992px) {
  .Typo h1, .Text.type-headline-1 {
    font-size: 67px;
    line-height: 73px;
  }
}

.Typo h2, .Text.type-headline-2 {
  font-family: RelativePro-Medium;
  letter-spacing: 0;
  font-size: 50px;
  line-height: 60px;
}
@media screen and (min-width: 992px) {
  .Typo h2, .Text.type-headline-2 {
    font-size: 50px;
    line-height: 60px;
  }
}

.Typo h3, .Text.type-headline-3 {
  font-family: RelativePro-Medium;
  letter-spacing: 0;
  font-size: 28px;
  line-height: 42px;
}
@media screen and (min-width: 992px) {
  .Typo h3, .Text.type-headline-3 {
    font-size: 38px;
    line-height: 49px;
  }
}

.Typo h4, .Text.type-headline-4 {
  font-family: RelativePro-Medium;
  letter-spacing: 0;
  font-size: 22px;
  line-height: 33px;
}
@media screen and (min-width: 992px) {
  .Typo h4, .Text.type-headline-4 {
    font-size: 28px;
    line-height: 42px;
  }
}

.Typo h5, .Text.type-headline-5 {
  font-family: RelativePro-Medium;
  letter-spacing: 0;
  font-size: 18px;
  line-height: 26px;
}
@media screen and (min-width: 992px) {
  .Typo h5, .Text.type-headline-5 {
    font-size: 22px;
    line-height: 33px;
  }
}

.Typo h6, .Text.type-headline-6 {
  font-family: RelativePro-Medium;
  letter-spacing: 0;
  font-size: 16px;
  line-height: 24px;
}
@media screen and (min-width: 992px) {
  .Typo h6, .Text.type-headline-6 {
    font-size: 16px;
    line-height: 24px;
  }
}

.Text.type-headline-7 {
  font-family: RelativePro-Medium;
  letter-spacing: 0;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
@media screen and (min-width: 992px) {
  .Text.type-headline-7 {
    font-size: 12px;
    line-height: 20px;
  }
}

.Text.type-text-xxl, .Select .Dropdown-root {
  font-family: RelativePro-Book;
  font-size: 22px;
  line-height: 33px;
}
@media screen and (min-width: 992px) {
  .Text.type-text-xxl, .Select .Dropdown-root {
    font-size: 28px;
    line-height: 42px;
  }
}

.Typo strong, .Text.type-text-l-bold {
  font-family: RelativePro-Bold;
  font-size: 16px;
  line-height: 26px;
}
@media screen and (min-width: 992px) {
  .Typo strong, .Text.type-text-l-bold {
    font-size: 22px;
    line-height: 33px;
  }
}

.Typo p, .Text.type-text-l {
  font-family: RelativePro-Book;
  font-size: 16px;
  line-height: 26px;
}
@media screen and (min-width: 992px) {
  .Typo p, .Text.type-text-l {
    font-size: 22px;
    line-height: 33px;
  }
}

.Typo li, .Text.type-text-m, .Search .box input {
  font-family: RelativePro-Book;
  font-size: 16px;
  line-height: 26px;
}
.Typo li strong, .Text.type-text-m-bold {
  font-family: RelativePro-Bold;
  font-size: 16px;
  line-height: 26px;
}
@media screen and (min-width: 992px) {
  .Typo li strong, .Text.type-text-m-bold {
    font-size: 16px;
    line-height: 26px;
  }
}

.Text.type-text-s-bold {
  font-family: RelativePro-Bold;
  font-size: 14px;
  line-height: 24px;
}
@media screen and (min-width: 992px) {
  .Text.type-text-s-bold {
    font-size: 14px;
    line-height: 24px;
  }
}

.Text.type-text-s {
  font-family: RelativePro-Book;
  font-size: 14px;
  line-height: 24px;
}
@media screen and (min-width: 992px) {
  .Text.type-text-s {
    font-size: 14px;
    line-height: 24px;
  }
}

.Text.type-text-xs {
  font-family: RelativePro-Book;
  font-size: 12px;
  line-height: 20px;
}
@media screen and (min-width: 992px) {
  .Text.type-text-xs {
    font-size: 12px;
    line-height: 20px;
  }
}

.Text.type-text-xs-bold {
  font-family: RelativePro-Bold;
  font-size: 12px;
  line-height: 20px;
}
@media screen and (min-width: 992px) {
  .Text.type-text-xs-bold {
    font-size: 12px;
    line-height: 20px;
  }
}

.Text.type-text-xxs {
  font-family: RelativePro-Book;
  font-size: 10px;
  line-height: 15px;
}
@media screen and (min-width: 992px) {
  .Text.type-text-xxs {
    font-size: 10px;
    line-height: 15px;
  }
}

.Text.type-button {
  font-family: RelativePro-Book;
  font-size: 16px;
  line-height: 26px;
  text-decoration: none !important;
  text-decoration-color: #fff !important;
}
@media screen and (min-width: 992px) {
  .Text.type-button {
    font-size: 16px;
    line-height: 26px;
  }
}

/* 119: /app/components/Ui/Text/Text.scss */
.Text.type-headline-hero {
  font-family: RelativePro-Medium;
  letter-spacing: 0;
  font-size: 67px;
  line-height: 73px;
}
@media screen and (min-width: 992px) {
  .Text.type-headline-hero {
    font-size: 132px;
    line-height: 132px;
  }
}

.Typo h1, .Text.type-headline-1 {
  font-family: RelativePro-Medium;
  letter-spacing: 0;
  font-size: 50px;
  line-height: 60px;
}
@media screen and (min-width: 992px) {
  .Typo h1, .Text.type-headline-1 {
    font-size: 67px;
    line-height: 73px;
  }
}

.Typo h2, .Text.type-headline-2 {
  font-family: RelativePro-Medium;
  letter-spacing: 0;
  font-size: 50px;
  line-height: 60px;
}
@media screen and (min-width: 992px) {
  .Typo h2, .Text.type-headline-2 {
    font-size: 50px;
    line-height: 60px;
  }
}

.Typo h3, .Text.type-headline-3 {
  font-family: RelativePro-Medium;
  letter-spacing: 0;
  font-size: 28px;
  line-height: 42px;
}
@media screen and (min-width: 992px) {
  .Typo h3, .Text.type-headline-3 {
    font-size: 38px;
    line-height: 49px;
  }
}

.Typo h4, .Text.type-headline-4 {
  font-family: RelativePro-Medium;
  letter-spacing: 0;
  font-size: 22px;
  line-height: 33px;
}
@media screen and (min-width: 992px) {
  .Typo h4, .Text.type-headline-4 {
    font-size: 28px;
    line-height: 42px;
  }
}

.Typo h5, .Text.type-headline-5 {
  font-family: RelativePro-Medium;
  letter-spacing: 0;
  font-size: 18px;
  line-height: 26px;
}
@media screen and (min-width: 992px) {
  .Typo h5, .Text.type-headline-5 {
    font-size: 22px;
    line-height: 33px;
  }
}

.Typo h6, .Text.type-headline-6 {
  font-family: RelativePro-Medium;
  letter-spacing: 0;
  font-size: 16px;
  line-height: 24px;
}
@media screen and (min-width: 992px) {
  .Typo h6, .Text.type-headline-6 {
    font-size: 16px;
    line-height: 24px;
  }
}

.Text.type-headline-7 {
  font-family: RelativePro-Medium;
  letter-spacing: 0;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
@media screen and (min-width: 992px) {
  .Text.type-headline-7 {
    font-size: 12px;
    line-height: 20px;
  }
}

.Text.type-text-xxl, .Select .Dropdown-root {
  font-family: RelativePro-Book;
  font-size: 22px;
  line-height: 33px;
}
@media screen and (min-width: 992px) {
  .Text.type-text-xxl, .Select .Dropdown-root {
    font-size: 28px;
    line-height: 42px;
  }
}

.Typo strong, .Text.type-text-l-bold {
  font-family: RelativePro-Bold;
  font-size: 16px;
  line-height: 26px;
}
@media screen and (min-width: 992px) {
  .Typo strong, .Text.type-text-l-bold {
    font-size: 22px;
    line-height: 33px;
  }
}

.Typo p, .Text.type-text-l {
  font-family: RelativePro-Book;
  font-size: 16px;
  line-height: 26px;
}
@media screen and (min-width: 992px) {
  .Typo p, .Text.type-text-l {
    font-size: 22px;
    line-height: 33px;
  }
}

.Typo li, .Text.type-text-m, .Search .box input {
  font-family: RelativePro-Book;
  font-size: 16px;
  line-height: 26px;
}
.Typo li strong, .Text.type-text-m-bold {
  font-family: RelativePro-Bold;
  font-size: 16px;
  line-height: 26px;
}
@media screen and (min-width: 992px) {
  .Typo li strong, .Text.type-text-m-bold {
    font-size: 16px;
    line-height: 26px;
  }
}

.Text.type-text-s-bold {
  font-family: RelativePro-Bold;
  font-size: 14px;
  line-height: 24px;
}
@media screen and (min-width: 992px) {
  .Text.type-text-s-bold {
    font-size: 14px;
    line-height: 24px;
  }
}

.Text.type-text-s {
  font-family: RelativePro-Book;
  font-size: 14px;
  line-height: 24px;
}
@media screen and (min-width: 992px) {
  .Text.type-text-s {
    font-size: 14px;
    line-height: 24px;
  }
}

.Text.type-text-xs {
  font-family: RelativePro-Book;
  font-size: 12px;
  line-height: 20px;
}
@media screen and (min-width: 992px) {
  .Text.type-text-xs {
    font-size: 12px;
    line-height: 20px;
  }
}

.Text.type-text-xs-bold {
  font-family: RelativePro-Bold;
  font-size: 12px;
  line-height: 20px;
}
@media screen and (min-width: 992px) {
  .Text.type-text-xs-bold {
    font-size: 12px;
    line-height: 20px;
  }
}

.Text.type-text-xxs {
  font-family: RelativePro-Book;
  font-size: 10px;
  line-height: 15px;
}
@media screen and (min-width: 992px) {
  .Text.type-text-xxs {
    font-size: 10px;
    line-height: 15px;
  }
}

.Text.type-button {
  font-family: RelativePro-Book;
  font-size: 16px;
  line-height: 26px;
  text-decoration: none !important;
  text-decoration-color: #fff !important;
}
@media screen and (min-width: 992px) {
  .Text.type-button {
    font-size: 16px;
    line-height: 26px;
  }
}

.Text .handwriting {
  font-family: "Handwriting" !important;
}
.Text.nowrap {
  white-space: nowrap;
}
.Text.transition {
  transition: color 300ms ease-in-out;
}
.Text.underline {
  text-decoration: underline;
}
.Text.center {
  text-align: center;
}
.Text.type-default {
  background: red;
}
.Text.color-default {
  color: blue;
}
.Text.color-inherit {
  color: inherit;
}
.Text.color-brand {
  color: #ff4b23;
}
.Text.color-brandLight {
  color: #fff5f3;
}
.Text.color-light {
  color: #ffffff;
}
.Text.color-light-50 {
  color: rgba(255, 255, 255, 0.5);
}
.Text.color-dark {
  color: #000000;
}
.Text.color-dark-50 {
  color: rgba(0, 0, 0, 0.5);
}

/* 120: /app/components/Ui/Typo/Typo.scss */
.Typo a:link {
  text-decoration: underline;
}
.Typo .handwriting {
  font-family: "Handwriting" !important;
}
.Typo a > .Button {
  text-decoration: none;
}
.Typo h1,
.Typo h2,
.Typo h3,
.Typo h4,
.Typo h5,
.Typo h6 {
  font-weight: normal;
}
.Typo strong {
  font-weight: normal;
}
.Typo li strong {
  font-weight: normal;
}
.Typo h1,
.Typo h2,
.Typo h3 {
  margin-top: 10px;
  margin-bottom: 6px;
}
.Typo h1:first-child,
.Typo h2:first-child,
.Typo h3:first-child {
  margin-top: 0;
}
.Typo h1:last-of-type-child,
.Typo h2:last-of-type-child,
.Typo h3:last-of-type-child {
  margin-bottom: 0;
}
.Typo p {
  margin: 10px 0;
}
.Typo p:first-child {
  margin-top: 0;
}
.Typo p:last-child {
  margin-bottom: 0;
}
.Typo ul,
.Typo ol {
  padding-left: 0px;
}
.Typo ul li,
.Typo ol li {
  list-style: none;
  padding-left: 10px;
  margin: 4px 0;
}
.Typo ul li::before,
.Typo ol li::before {
  font-size: 20px;
  content: "•";
  color: #ff4b23;
  font-weight: bold;
  display: inline-block;
  width: 10px;
  margin-left: -10px;
}
.Typo ol li {
  list-style-type: none;
}

/* 121: /app/components/Wrapper/Body/Body.scss */
.Body {
  padding: 0;
  margin: 0;
}

/* 122: /app/components/Wrapper/Body/Footer/Footer.scss */
.Footer {
  color: #ffffff;
}
.Footer .payment-providers {
  background: white;
  border-radius: 5px;
  margin-top: 20px;
}
.Footer .payment-providers > div {
  padding: 0 10px;
}
.Footer .logo {
  display: block;
  max-width: 40%;
  margin: 0 auto;
  margin-bottom: 20px;
}
@media screen and (min-width: 992px) {
  .Footer .logo {
    max-width: 250px;
    margin: 0;
  }
}
.Footer .contact {
  text-align: center;
}
@media screen and (min-width: 992px) {
  .Footer .contact {
    text-align: left;
    display: flex;
    justify-content: space-between;
  }
}
.Footer .socials-wrap {
  margin-top: 20px;
}
@media screen and (min-width: 992px) {
  .Footer .socials-wrap {
    margin: 0;
  }
}
.Footer .socials {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
@media screen and (min-width: 992px) {
  .Footer .socials {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
}
.Footer .socials li a {
  display: block;
  height: 30px;
  width: 30px;
  font-size: 24px;
  margin: 0 auto;
  margin-bottom: 2px;
}

/* 123: /app/components/Wrapper/Body/Header/Header.scss */
.Header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  border-bottom: 2px solid transparent;
  transition: all 300ms ease-out;
}
.Header.light {
  border-bottom: 2px solid #fff5f3;
}
.Header .logo {
  height: 100%;
  padding: 16px 0;
}
.Header .left {
  height: 100%;
  display: flex;
  align-items: center;
}
.Header .right {
  height: 100%;
  display: flex;
  align-items: center;
}

/* 124: /app/components/Wrapper/Body/Main/Main.scss */
/* 125: /app/components/Wrapper/Document/Document.scss */
html {
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}

/* 126: /app/components/Wrapper/Modules/Debug/Debug.scss */
.Debug .info {
  background: #efefef;
  padding: 10px;
}
.Debug .module {
  border: 1px solid #efefef;
}

/* 127: /app/components/Wrapper/Types/Page/Page.scss */